import { Injectable } from '@angular/core';


const scriptArray = [
  { name: 'WebApis1.0', src: '$MANAGER_WIDGET/Common/webapi/1.0/webapis.js' },
  { name: 'Widget', src: '$MANAGER_WIDGET/Common/API/Widget.js' },
  { name: 'DeviceApis1.0', src: '$MANAGER_WIDGET/Common/webapi/1.0/deviceapis.js' },
  { name: 'CAPH', src: '$CAPH/1.0.0/caph-level1-unified.min.js' },

  { name: 'TizenWebApis', src: '$WEBAPIS/webapis/webapis.js' },
  { name: 'TizenB2BApis', src: '$B2BAPIS/b2bapis/b2bapis.js' },
  { name: 'HCAP', src: '../../assets/scripts/hcap.js' }
];

@Injectable({ providedIn: 'root' })
export class ScriptService {
  private scripts: any = {};

  constructor() {
    scriptArray.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  // load a single or multiple scripts
  load(...scripts: string[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  // load the script
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        // cross browser handling of onLoaded event
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        // finally append the script tag in the DOM
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}

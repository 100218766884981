import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-channels-grid',
  templateUrl: './channels-grid.component.html',
  styleUrls: ['./channels-grid.component.scss']
})
export class ChannelsGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

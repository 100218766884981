import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { delay, filter, retryWhen, take, tap } from 'rxjs/operators';
import { CoreService } from '@app/services/core.service';
import { StorageService } from '@app/services/storage.service';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';

@Injectable({ providedIn: 'root' })
export class ResponseInterceptor implements HttpInterceptor {
  private storeArray: Array<any> = [
    { api: 'checkiftvexists', key: ['unique_id'] },
    { api: 'get-tvbox-info', key: ['tvbox_info'] },
    { api: 'user/admin/get-user-info', key: ['user_info'] },
    { api: 'channel/get-room-channel-tv-settings', key: ['rights', 'terms', 'tv_hotspot', 'tv_via'] },
    { api: 'tvsettings/tv-settings/gettvsettings', key: ['tv_settings'] },
    { api: 'channel-content/getlastlogo', key: ['last_logo'] },
    { api: 'channel/get-channel-background', key: ['channel_background'] },
    { api: 'streaming/get-free-streaming-tv', key: ['tv_channels'] },
    { api: 'streaming/get-hotel-channels', key: ['hotel_channels'] },
    { api: 'channel-content/getpluscontent&kind=5', key: ['directory_of_services'] },
    { api: 'channel-content/getpluscontent&kind=4', key: ['daily_theme'] },
    { api: 'channel-content/getpluscontent&kind=6', key: ['activities'] },
    { api: 'dynamic-menu/dynamic-menu/list', key: ['items']},
    { api: 'assets/bucket/channels', key: ['tv_channels']}
  ];

  private responseCounter = 0;

  constructor(
    private core: CoreService,
    private storage: StorageService,
    private store: Store<fromApp.AppState>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(errors => {
        errors.subscribe(error => {
          if (this.responseCounter >= 5) {
            this.store.select('appStore')
            .pipe(
              filter(appState => _.isEqual(appState.platform, 'Philips')),
              take(1)
            )
            .subscribe(() => {
              window.location.reload();
            })
          }
          this.responseCounter++;
        })
        return errors.pipe(delay(1000));
      }),
      tap((res) => {
        if (res instanceof HttpResponse) {
            //* Local image storage is needed instead of cloud
            this.core.changeImagePath(res);

          _.forEach(this.storeArray, (value) => {
            if (_.includes(request.url, value.api)) {
              //* Check if res.body is object
              if (_.isPlainObject(res.body)) {
                _.forEach(_.keys(res.body), (i) => {
                  _.forEach(value.key, (j) => {
                    if (_.isEqual(i, j)) {
                      this.storage.save(j, res.body[j]);
                    }
                  });
                });
              //* Check if res.body is Array
              } else if (_.isArray(res.body)) {
                if (res.body.length == 1) {
                  this.storage.save(value.key, res.body[0]);
                } else {
                  this.storage.save(value.key, res.body);
                }
              }
            }
          });
        }
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreService } from '@app/services/core.service';
import 'lodash'

declare const _:any;

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'age_range' })
export class AgeRangeFilterPipe implements PipeTransform {
  constructor(private core: CoreService, protected sanitizer: DomSanitizer) {}

  transform(id: number): string {
    let age_range: string = '';
    if (_.isEqual(id, 0)) age_range = '0-16';
    if (_.isEqual(id, 1)) age_range = '17-24';
    if (_.isEqual(id, 2)) age_range = '25-34';
    if (_.isEqual(id, 3)) age_range = '35-44';
    if (_.isEqual(id, 4)) age_range = '45-54';
    if (_.isEqual(id, 5)) age_range = '55-64';
    if (_.isEqual(id, 6)) age_range = '65-74';
    if (_.isEqual(id, 7)) age_range = '74+';
    return age_range;
  }
}

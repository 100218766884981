import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromApp from '@app/store/app.reducer';
import * as HomeActions from './home.actions';

@Injectable()
export class HomeEffects {
   /**
   * * Get Channel Background
   *
   * @param channel_id
   * @returns images option  poi
   */
  @Effect()
  getchannelbackground = this.actions$.pipe(
    ofType(HomeActions.GET_CHANNEL_BACKGROUND),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([ actionState, storeState ]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}channel/get-channel-background`,
        body
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get channel background');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got channel background----------');

        return new HomeActions.SetChannelBackground(res[0]);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService,
    // private storage: StorageService
  ) {}
}

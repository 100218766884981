import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromApp from '@app/store/app.reducer';
import * as TvActions from './tv.actions';
import * as _ from 'lodash';
import { isNull } from 'lodash';
import { environment } from '@environments/environment';

@Injectable()
export class TvEffects {
  /**
   * * Get IP Channels
   *
   * @param channel_id
   * @returns ip channels array
   */
  @Effect()
  getIpChannelsdata = this.actions$.pipe(
    ofType(TvActions.GET_IP_CHANNELS_DATA),
    withLatestFrom(this.store$.select('appStore'), this.store$.select('tvStore')),
    switchMap(([actionState, storeState, tvStore]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}&user_id=${storeState.profile.user_id}`;
      if (storeState.deviceOnline) {
        return this.http.post<any>(
          `${this.core.hostUrl}${(actionState as any).payload}`,
          body
        );
      } else if (_.isEmpty(tvStore.channels)) {
        return this.http.get(environment.localServerURL + '/assets/bucket/channels/channels.json')
      }

      return tvStore.channels
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get ip tv channels');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got ip channels data----------');
        return new TvActions.SetChannelsData(this.trim_channels_url(res));
      }
    })
  );

  trim_channels_url (channels:Array<any>) {
    return _.map(channels, channel => {
      return {
        ...channel,
        link: _.trim(channel.link)
      }
    })
  }

  /**
   * * Get Hotel Channels
   *
   * @param channel_id
   * @returns ip channels array
   */
   @Effect()
   gethotelChannels = this.actions$.pipe(
     ofType(TvActions.GET_HOTEL_CHANNELS),
     withLatestFrom(this.store$.select('appStore')),
     switchMap(([actionState, storeState]) => {
       let body = `&channel_id=${storeState.tvbox_info.channel_id}&user_id=${storeState.profile.user_id}`;
       return this.http.post<any>(
         `${this.core.hostUrl}${(actionState as any).payload}`,
         body
       );
     }),
     map((res) => {
       if (res.error) {
         console.info('Server returned Error. get ip tv channels');
         throw 'Server returned Error. ' + res.alert ? res.alert : '';
       } else {
         console.info('----------got hotel channels----------');
         return new TvActions.SetHotelChannels(this.trim_channels_url(res));
       }
     })
   );

  /**
   * * Get Radio Channels
   *
   * @param channel_id
   * @returns radio channel array
   */
  @Effect()
  getRadiodata = this.actions$.pipe(
    ofType(TvActions.GET_RADIO_DATA),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}${(actionState as any).payload}`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get radio data');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got radio data----------');
        return new TvActions.SetRadioData(res);
      }
    })
  );

  /**
   * * Get Music Albums
   *
   * @param channel_id
   * @returns music data array
   */
  @Effect()
  getMusicdata = this.actions$.pipe(
    ofType(TvActions.GET_MUSIC_DATA),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      return this.http.post<any>(
        `${this.core.hostUrl}music/streaming-music/get-streaming-music&id=${storeState.tvbox_info.channel_id}`,
        ''
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get music data');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got music data----------');
        return new TvActions.SetMusicData(res);
      }
    })
  );

   /**
   * * Get Movies List
   *
   * @param channel_id
   * @returns music data array
   */
    @Effect()
    getMoviesdata = this.actions$.pipe(
      ofType(TvActions.GET_MOVIES_DATA),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        return this.http.post<any>(
          `${this.core.hostUrl}movies/streaming-movies/get-streaming-movies&id=${storeState.tvbox_info.channel_id}`,
          ''
        );
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. get music data');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('----------got movies data----------');
          return new TvActions.SetMoviesData(res);
        }
      })
    );

  /**
   * * Pay Tv endpoint
   *
   * @param corporate_id
   * @param user_id
   * @param room_id
   * @returns payment confirmation
   */
  @Effect()
  paytvpage = this.actions$.pipe(
    ofType(TvActions.PAY_TV),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&StreamingInvoices[kind]=1&StreamingInvoices[corporate_id]=${storeState.tvbox_info.corporate}&StreamingInvoices[customer_id]=${storeState.room.user_id}&StreamingInvoices[room_id]=${storeState.tvbox_info.room_id}&check=${(actionState as any).payload.check}`;
      return this.http.post<any>(
        `${this.core.hostUrl}streaminginvoices/streaming-invoices/room-billing`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. tv payment failed');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------Tv Payment----------');
        return new TvActions.SetPaymentTv(res[0]);
      }
    })
  );

    /**
   * * Get PayTv Channels
   *
   * @param channel_id
   * @returns radio channel array
   */
     @Effect()
     getpaytvchannels = this.actions$.pipe(
       ofType(TvActions.GET_PAYTV_CHANNELS),
       withLatestFrom(this.store$.select('appStore')),
       switchMap(([actionState, storeState]) => {
         let body = `&channel_id=${storeState.tvbox_info.channel_id}&user_id=${storeState.room.user_id}`;
         return this.http.post<any>(
           `${this.core.hostUrl}streaming/get-paid-streaming-tv`,
           body
         );
       }),
       map((res) => {
         if (res.error) {
           console.info('Server returned Error. get paid channels');
           throw 'Server returned Error. ' + res.alert ? res.alert : '';
         } else {
           console.info('----------got paid channels----------');
           return new TvActions.SetPayTvChannels(res);
         }
       })
     );

       /**
   * * Get Chromecast Content
   *
   * @param room_id
   * @returns radio channel array
   */
    @Effect()
    getchromecast = this.actions$.pipe(
      ofType(TvActions.GET_CHROMECAST_CONTENT),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        let body = `&roomId=${storeState.tvbox_info.room_id}&wifiSsid=${storeState.SSID}&wifiPassword=${storeState.wifiPass}`;
        //? Check if language is not null
        if (!isNull(storeState.room.tv_settings_language)) {
          body += `&language=${storeState.room.tv_settings_language}`
        }

        return this.http.post<any>(
          `${this.core.hostUrl}chromecast/message/retrieve`,
          body
        );
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. get paid channels');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('----------got paid channels----------');          
          return new TvActions.SetChromecastContent(res.message);
        }
      })
    );


  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService // private storage: StorageService
  ) {}
}

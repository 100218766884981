import { Action } from '@ngrx/store';

import { Corporate } from '@app/models/corporate'

export const SET_CORPORATES = '[Splash Page] Set Corporates';
export const GET_CORPORATES = '[Splash Page] Get Corporates';
export const CHECK_ROOM = '[Splash Page] Check Room';
export const SET_ROOM_STATUS = '[Splash Page] Set Room Status';
export const CREATE_TV_BOX = '[Splash Page] Create TV Box';

export class SetCorporates implements Action {
  readonly type = SET_CORPORATES;

  constructor(public payload: Corporate[]) {}
}

export class GetCorporates implements Action {
  readonly type = GET_CORPORATES;
}

export class CheckRoom implements Action {
  readonly type = CHECK_ROOM;

  constructor(public payload: {roomnumber: number, corporate: string}){}
}

export class SetRoomStatus implements Action {
  readonly type = SET_ROOM_STATUS;

  constructor(public payload: string) {}
}

export class CreateTvBox implements Action {
  readonly type = CREATE_TV_BOX;

  constructor(public payload: {roomnumber: number, token: string, corporate:string, device_type: number | undefined}) {}
}

export type SplashActions = SetCorporates | GetCorporates | CheckRoom | SetRoomStatus | CreateTvBox;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as AppInitialActions from '@app/store/app.actions';
import * as SettingsActions from './settings.actions';
import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from '@environments/environment';

@Injectable()
export class SettingsEffects {
  /**
   * * Get Languages from API
   *
   * @param uri
   * @returns []
   */
  @Effect()
  getlanguages = this.actions$.pipe(
    ofType(SettingsActions.GET_LANGUAGES),
    switchMap((payload: any) => {
      return this.http.post<any>(`${this.core.hostUrl}${payload.payload}`, '');
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got Languages----------');
        let defaultLanguage = _.find(res, {default: 1});
        if (!_.isUndefined(defaultLanguage)) {
          this.core.setTranslation(defaultLanguage.codename);
        } else {
          this.core.setTranslation('en');
        }
        return new SettingsActions.SetLanguages(res);
      }
    })
  );

    /**
   * * Get About info from API
   *
   * @param uri
   * @returns []
   */
    @Effect()
    getAboutInfo = this.actions$.pipe(
      ofType(SettingsActions.GET_ABOUT_INFO),      
      switchMap((action)=>{
        let body = '';
        return this.http.post(        
          `${this.core.hostUrl}api/plus/about`,body
        );
      }),
      map((res)=>{
        console.log(res);
        return new SettingsActions.SetAboutInfo(res)
      })
    )

  /**
   * * Update Language
   *
   * @param language
   * @param user_id
   * @returns []
   */
  @Effect({dispatch: false})
  updateLanguage = this.actions$.pipe(
    ofType(SettingsActions.UPDATE_LANGUAGE),
    map((action: SettingsActions.UpdateLanguage) => action.payload),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([payload, storeState]) => {
      let body = `&language=${payload.idx}`;
      return this.http.post(
        `${this.core.hostUrl}tvbox/update-tv-language&id=${storeState.profile.user_id}`,
        body
      );
    }),
    map((res) => {
      if (res == 'ok' ) {
        //this.store$.dispatch(new AppInitialActions.GetUserInfo());
        this.store$.dispatch(new AppInitialActions.DoReboot());
      }
    })
  );

 /**
   * * Get About from API
   *
   * @param uri
   * @returns []
   */
  @Effect()
  getabout = this.actions$.pipe(
    ofType(SettingsActions.GET_ABOUT),
    switchMap((payload: any) => {
      return this.http.post<any>(`${this.core.hostUrl}api/plus/about`, '');
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get about');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        return new SettingsActions.SetAbout({
          image: res.image,
          text: res.text
        });
      }
    })
  );


  /**
   * * Update Parental Code
   *
   * @param language
   * @param user_id
   * @returns []
   */
   @Effect({dispatch: false})
   updateParentalCode = this.actions$.pipe(
     ofType(SettingsActions.UPDATE_PARENTAL_CODE),
     map((action: SettingsActions.UpdateParentalCode) => action.payload),
     withLatestFrom(this.store$.select('appStore')),
     switchMap(([payload, storeState]) => {
       let body = `&parental_code=${payload}`;
       return this.http.post<any>(
         `${this.core.hostUrl}tvbox/update-parental-code-plus&id=${storeState.profile.user_id}`,
         body
       );
     }),
     map((res) => {
        if (res.error === false) {
          this.store$.dispatch(new AppInitialActions.GetUserInfo());
        }
     })
   );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private core: CoreService,
    private store$: Store<fromApp.AppState>,
    private router: Router
  ) {}
}

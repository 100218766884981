<!-- ? Samsung Legacy Objects -->
<ng-template [ngIf]="core.platform == 'SamsungLegacy'">>
  <object id="pluginsef" classid="clsid:SAMSUNG-INFOLINK-SEF"
    style="height:0px; width:0px; position: absolute;"></object>
  <object id="sef" classid="clsid:SAMSUNG-INFOLINK-SEF" style="height:0px; width:0px; position: absolute;"></object>
</ng-template>


<main id="app" class="app-container container h-100">
  <div class="row app-container__row h-100">
    <app-header *ngIf="headerFooter"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="headerFooter"></app-footer>
  </div>
</main>

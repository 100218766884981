import { isEqual } from 'lodash';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '@app/store/app.reducer';
import { environment } from '@environments/environment';

declare const FileSystem: new () => any;

@Injectable({ providedIn: 'root' })
export class StorageService {
  private platform: string | undefined;
  private fileSystemObj: any;

  constructor(private store: Store<fromApp.AppState>) {
    this.store.select('appStore').subscribe((res) => {
      this.platform = res.platform;
    });
  }

  /**
   * Save Data To Device Storage
   *
   * @param key
   * @param value
   */
  save = (key: string, value: any) => {
    if (
      isEqual(this.platform, 'SamsungLegacy') &&
      isEqual(environment.storage, 'device')
    ) {
      this.fileSystemObj = new FileSystem();
      const jsFileObj = this.fileSystemObj.openCommonFile(
        '000' + '/data.dat',
        'w'
      );
      var obj: any = {};
      obj[key] = value;
      jsFileObj.writeLine(JSON.stringify(obj));
      this.fileSystemObj.closeCommonFile(jsFileObj);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }

    return false;
  };

  /**
   * Read Data Form Device Storage
   *
   * @param line
   */
  read = (line: string) => {
    if (
      isEqual(this.platform, 'SamsungLegacy') &&
      isEqual(environment.storage, 'device')
    ) {
      this.fileSystemObj = new FileSystem();
      const jsFileObj = this.fileSystemObj.openCommonFile(
        '000' + '/data.dat',
        'r'
      );
      let arrResult: any = [];
      while ((line = jsFileObj.readLine())) {
        arrResult.push(line);
      }
      this.fileSystemObj.closeCommonFile(jsFileObj);
      return JSON.parse(arrResult);
    } else {
      var obj: any = {};
      obj[line] = localStorage.getItem(line);
      return obj;
    }
  };

  /**
   * Delete Data From Device Storage
   *
   * @param line
   */
  delete = (line: string) => {
    if (
      isEqual(this.platform, 'SamsungLegacy') &&
      isEqual(environment.storage, 'device')
    ) {
      this.fileSystemObj = new FileSystem();
      const jsFileObj = this.fileSystemObj.openCommonFile(
        '000' + '/data.dat',
        'r'
      );

      while ((line = jsFileObj.readLine())) {
        this.save(line, '');
      }

      this.fileSystemObj.closeCommonFile(jsFileObj);
      return true;
    } else {
      window.localStorage.removeItem(line);
      return true;
    }

    return false;
  };

  /**
   * Update Data From Device Storage
   *
   * @param key
   * @param value
   */
  update = (key: string, value: string) => {
    if (
      isEqual(this.platform, 'SamsungLegacy') &&
      isEqual(environment.storage, 'device')
    ) {
      this.fileSystemObj = new FileSystem();
      const jsFileObj = this.fileSystemObj.openCommonFile(
        '000' + '/data.dat',
        'r'
      );

      while ((key = jsFileObj.readLine())) {
        this.save(key, value);
      }
      this.fileSystemObj.closeCommonFile(jsFileObj);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };
}

import { Action } from '@ngrx/store';

export const GET_ROOM_AREAS = '[Smart Room] Get Room Areas';
export const SET_ROOM_AREAS = '[Smart Room] Set Room Areas';

export class GetRoomAreas implements Action {
  readonly type = GET_ROOM_AREAS;

  //constructor(public payload: { date_from: string, date_to: string }) {}
}

export class SetRoomAreas implements Action {
  readonly type = SET_ROOM_AREAS;

  constructor(public payload: any ) {}
}

export type SmartRoomActions =
  | SetRoomAreas
  | SetRoomAreas;

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreService } from '@app/services/core.service';
import { cloneDeep } from 'lodash';

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'safe' })
export class SafeFilterPipe implements PipeTransform {
  constructor(private core: CoreService, protected sanitizer: DomSanitizer) {}

  transform(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CoreService } from '@app/services/core.service';

import Modal from 'bootstrap.native/dist/components/modal-native.esm.js';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  private modal: any;
  public data$!: Observable<any>;
  @Input() item?: any;
  @Input() id: any;
  @Input() modalSize: string = 'modal-xl';

  constructor(private core: CoreService) {
    console.info('modal ---- loaded ---');
  }

  ngOnInit(): void {

  }

  show() {
    this.modal = new Modal('#'+this.id);
    this.modal.show();
  }
  hide() {
    this.modal.hide();
    this.core.SN.remove('form-control');
    this.core.SN.remove('confirm-btn');
  }

  ngOnDestroy() {
    console.info('Destroy Modal');
    this.core.SN.remove('form-control');
    this.core.SN.remove('confirm-btn');
  }
}

import { Action } from '@ngrx/store';

export const GET_POI_CATEGORIES_LIST = '[Points Of Interest] Get Points Of Interest Categories';
export const SET_POI_CATEGORIES_LIST = '[Points Of Interest] Set Points Of Interest Categories';
export const GET_QRCODES_ANDROID = '[Points Of Interest] Get Point Of Interest QR codes (Android)';
export const SET_QRCODES_ANDROID = '[Points Of Interest] Set Point Of Interest QR codes (Android)';
export const GET_QRCODES_IOS = '[Points Of Interest] Get Point Of Interest QR codes (IOS)';
export const SET_QRCODES_IOS = '[Points Of Interest] Set Point Of Interest QR codes (IOS)';
export const GET_BUS_INFOS = '[Bus Page] Get Bus Informations';
export const SET_BUS_INFOS = '[Bus Page] Set Bus Informations';
export const GET_WEATHER_DATA = '[Weather Page] Get Weather Data';
export const SET_WEATHER_DATA = '[Weather Page] Set Weather Data';
export class GetWeatherData implements Action {
  readonly type = GET_WEATHER_DATA;
}
export class SetWeatherData implements Action {
  readonly type = SET_WEATHER_DATA;

  constructor(public payload: any ) {}
}
export class GetPoiCategoriesList implements Action {
  readonly type = GET_POI_CATEGORIES_LIST;

  constructor(public payload: string) {}
}

export class SetPoiCategoriesList implements Action {
  readonly type = SET_POI_CATEGORIES_LIST;

  constructor(public payload: any ) {}
}

export class GetPointQRCodesAndroid implements Action {
  readonly type = GET_QRCODES_ANDROID;

  constructor(public payload: number) {}
}

export class SetPointQRCodesAndroid implements Action {
  readonly type = SET_QRCODES_ANDROID;

  constructor(public payload: any ) {}
}

export class GetPointQRCodesIos implements Action {
  readonly type = GET_QRCODES_IOS;

  constructor(public payload: number) {}
}

export class SetPointQRCodesIos implements Action {
  readonly type = SET_QRCODES_IOS;

  constructor(public payload: any ) {}
}

export class GetBusInfos implements Action {
  readonly type = GET_BUS_INFOS;

  constructor(public payload: { date_from: string, date_to: string }) {}
}

export class SetBusInfos implements Action {
  readonly type = SET_BUS_INFOS;

  constructor(public payload: any ) {}
}

export type InformationActions =
  | GetPoiCategoriesList
  | SetPoiCategoriesList
  | GetPointQRCodesAndroid
  | SetPointQRCodesAndroid
  | GetPointQRCodesIos
  | SetPointQRCodesIos
  | GetBusInfos
  | SetBusInfos
  | GetWeatherData
  | SetWeatherData;

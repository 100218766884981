import * as HomeActions from './home.actions';
import { ChannelBackground } from '@app/models/channelbackground';

export interface State {
  channelBackground: ChannelBackground;
  lastLogo: string;
}

const initialState: State = {
  channelBackground: new ChannelBackground,
  lastLogo: ''
}

export function homeReducer(
  state: State = initialState,
  action: HomeActions.HomePageActions
): State {
  switch (action.type) {
    case HomeActions.SET_CHANNEL_BACKGROUND:
      return {
        ...state,
        channelBackground: action.payload
      }
    default:
      return state;
  }
}

import { Action } from '@ngrx/store';
import { ChannelBackground } from '@app/models/channelbackground';

export const GET_CHANNEL_BACKGROUND = '[Home Page] Get Channel Background';
export const SET_CHANNEL_BACKGROUND = '[Home Page] Set Channel Background';
export class GetChannelBackground implements Action {
  readonly type = GET_CHANNEL_BACKGROUND;
}
export class SetChannelBackground implements Action {
  readonly type = SET_CHANNEL_BACKGROUND;

  constructor(public payload: ChannelBackground ) {}
}

export type HomePageActions =
  | GetChannelBackground
  | SetChannelBackground;


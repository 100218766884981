import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements AfterViewInit {
  public audioElement!:HTMLAudioElement;
  public imageSrc!:string;
  public audioSrcLocal!: string;
  public audioSrcCloud!: string;
  public text!: string;

  @ViewChild('audio') audioWrap!: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    this.audioElement = document.createElement('audio');
    this.audioElement.setAttribute('type', 'audio/mpeg');

    this.audioElement.controls = false;
    this.audioWrap.nativeElement.appendChild(this.audioElement);
    console.info('Sound from: '+this.audioSrcLocal);
    setTimeout(() => {
      this.audioElement.src = this.audioSrcLocal;
      this.audioElement.load();
      this.audioElement.play();
    }, 1000);

    this.audioElement.addEventListener('stalled', () => {
      console.info('error sound');
      this.audioElement.src = this.audioSrcCloud;
      this.audioElement.load();
      this.audioElement.play();
    });

    this.audioElement.addEventListener('ended', () => {
      console.info('ended sound');
      this.audioElement.currentTime = 0.01;
      this.audioElement.load();
      this.audioElement.play();
    })
  }
}

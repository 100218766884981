import { Action } from '@ngrx/store';

export const GET_SURVEY_CATEGORIES = '[Questionnaire Page] Get survey categories';
export const SET_SURVEY_CATEGORIES = '[Questionnaire Page] Set survey categories';
export const GET_PREFERENCES_CATEGORIES = '[Preferences Page] Get Preferences categories';
export const SUBMIT_SURVEY = '[Questionnaire Page] Submit Survey';
export const SUBMIT_WAKE_UP = '[Wake Up Page] Submit Wake Up Time';
export const GET_WAKE_UP_LIST = '[Wake Up Page] Get Wake Up List';
export const SET_WAKE_UP_LIST = '[Wake Up Page] Set Wake Up List';
export const EDIT_WAKE_UP_ALARM = '[Wake Up Page] Edit Wake Up Alarm';
export const DELETE_WAKE_UP_ALARM = '[Wake Up Page] Delete Wake Up Alarm';
export const GET_CONCIERGE_CATEGORIES = '[Concierge Page] Get concierge categories';
export const SET_CONCIERGE_CATEGORIES = '[Concierge Page] Set concierge categories';
export const GET_CONCIERGE_MESSAGES = '[Concierge Page] Get concierge messages';
export const SET_CONCIERGE_MESSAGES = '[Concierge Page] Set concierge messages';
export const SEND_CONCIERGE_MESSAGE = '[Concierge Page] Send concierge predefined message'
export const GET_PREDIFINED_MESSAGES = '[Concierge Page] Get predifined messages';
export const SET_PREDIFINED_MESSAGES = '[Concierge Page] Set predifined messages';
export const GET_USER_INVOICES = '[Concierge Page] Get user invoices';
export const SET_USER_INVOICES = '[Concierge Page] Set user invoices';
export const GET_USER_INVOICES_HISTORY = '[Concierge Page] Get user invoices history';
export const SET_USER_INVOICES_HISTORY = '[Concierge Page] Set user invoices history';
export const READ_MESSAGE = '[Concierge Page] Read Message';
export class GetServeyCategories implements Action {
  readonly type = GET_SURVEY_CATEGORIES;
}
export class SetServeyCategories implements Action {
  readonly type = SET_SURVEY_CATEGORIES;

  constructor(public payload: { id: number; surveyCategories: Array<any> }) {}
}
export class SubmitSurvey implements Action {
  readonly type = SUBMIT_SURVEY;

  constructor(public payload: { survey_id: number; answers: Array<any> }) {}
}
export class GetPreferencesCategories implements Action {
  readonly type = GET_PREFERENCES_CATEGORIES;
}
export class WakeUpTime implements Action {
  readonly type = SUBMIT_WAKE_UP;

  constructor(public payload: any) {}
}

export class GetWakeUpList implements Action {
  readonly type = GET_WAKE_UP_LIST;
}

export class SetWakeUpList implements Action {
  readonly type = SET_WAKE_UP_LIST;

  constructor(public payload: any) {}
}

export class EditWakeUpAlarm implements Action {
  readonly type = EDIT_WAKE_UP_ALARM;

  constructor(public payload: any) {}
}

export class DeleteWakeUpAlarm implements Action {
  readonly type = DELETE_WAKE_UP_ALARM;

  constructor(public payload: any) {}
}
export class GetConciergeCategories implements Action {
  readonly type = GET_CONCIERGE_CATEGORIES;
}
export class SetConciergeCategories implements Action {
  readonly type = SET_CONCIERGE_CATEGORIES;

  constructor(public payload: any) {}
}
export class GetConciergeMessages implements Action {
  readonly type = GET_CONCIERGE_MESSAGES;
  constructor(public payload: number) {}
}
export class SetConciergeMessages implements Action {
  readonly type = SET_CONCIERGE_MESSAGES;

  constructor(public payload: any) {}
}
export class SendConciergePredefinedMessage implements Action {
  readonly type = SEND_CONCIERGE_MESSAGE;

  constructor(public payload: any) {}
}
export class GetPredifinedMessages implements Action {
  readonly type = GET_PREDIFINED_MESSAGES;
}
export class SetPredifinedMessages implements Action {
  readonly type = SET_PREDIFINED_MESSAGES;

  constructor(public payload: any) {}
}
export class GetUserInvoices implements Action {
  readonly type = GET_USER_INVOICES;
}
export class SetUserInvoices implements Action {
  readonly type = SET_USER_INVOICES;

  constructor(public payload: any) {}
}
export class GetUserInvoicesHistory implements Action {
  readonly type = GET_USER_INVOICES_HISTORY;
}
export class SetUserInvoicesHistory implements Action {
  readonly type = SET_USER_INVOICES_HISTORY;

  constructor(public payload: any) {}
}
export class ReadMessage implements Action {
  readonly type = READ_MESSAGE;

  constructor(public payload: any) {}
}

export type GuestActions =
  | GetServeyCategories
  | SetServeyCategories
  | GetPreferencesCategories
  | SubmitSurvey
  | WakeUpTime
  | GetWakeUpList
  | SetWakeUpList
  | EditWakeUpAlarm
  | DeleteWakeUpAlarm
  | GetConciergeCategories
  | SetConciergeCategories
  | GetConciergeMessages
  | SetConciergeMessages
  | GetPredifinedMessages
  | SetPredifinedMessages
  | SendConciergePredefinedMessage
  | GetUserInvoices
  | SetUserInvoices
  | GetUserInvoicesHistory
  | SetUserInvoicesHistory
  | ReadMessage;

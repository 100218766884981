import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import * as fromApp from './store/app.reducer';
import { SplashEffects } from '@app/pages/splash/store/splash.effects';
import { AppEffects } from '@app/store/app.effects';

import { environment } from '@environments/environment';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ChannelsGridComponent } from './components/channels-grid/channels-grid.component';
import { DEFAULT_TIMEOUT, RequestInterceptor } from './middleware/interceptors/request.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ResponseInterceptor } from './middleware/interceptors/response.inteceptor';
import { HomeEffects } from './pages/home/store/home.effects';
import { SharedModule } from './shared.module';
import { ContentListEffects } from './pages/category/store/category.effects';
import { TvEffects } from './pages/tv-and-entertainment/store/tv.effects';
import { SettingsEffects } from './pages/settings/store/settings.effects';
import { InformationEffects } from './pages/information/store/information.effects';
import { HotelServicesEffects } from './pages/hotel_services/store/hotel-services.effects';
import { QuestEffects } from './pages/guest/store/guest.effects';
import { SmartRoomEffects } from './pages/smart-room/store/smart-room.effects';
import { ReservationsEffects } from './pages/reservations/store/reservations.effects';
import { MyErrorHandler } from './services/error.service';
import { DigitInputComponent } from './components/digit-input/digit-input.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ChannelsGridComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(fromApp.appReducer),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      HomeEffects,
      SplashEffects,
      AppEffects,
      ContentListEffects,
      TvEffects,
      SettingsEffects,
      InformationEffects,
      HotelServicesEffects,
      QuestEffects,
      SmartRoomEffects,
      ReservationsEffects
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSkeletonLoaderModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.isLgLegacy,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 60000 },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: MyErrorHandler }
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { scan } from 'rxjs/operators';

@Component({
  selector: 'app-digit-input',
  templateUrl: './digit-input.component.html',
  styleUrls: ['./digit-input.component.scss']
})
export class DigitInputComponent implements OnInit {

  constructor() { }

  @Input() items:any[]=[];
  @Input() value?=this.items[0] || null;
  _currentIndex:number=0;  

  ngOnInit(): void {
  }

  moveUp(){
    //this.value=this.items[this.currentIndex]
    //this.currentIndex.next()
    console.log(this._currentIndex)
    this._currentIndex=((this._currentIndex+1)%(this.items.length));
    console.log(this._currentIndex)
    this.value=this.items[this._currentIndex];
  }

  moveDn(){
    //this.value=this.items[this.currentIndex]
    //this.currentIndex.next()
    this._currentIndex=((this._currentIndex+this.items.length-1)%(this.items.length));
    this.value=this.items[this._currentIndex];
  }


  public getValue(){
    return this.value;
  }

  public reset(){
    this._currentIndex=0;
    this.value=this.items[this._currentIndex];
  }

}

import { Action } from "@ngrx/store";

export const GET_LANGUAGES = '[Languages Page] Get Languages';
export const SET_LANGUAGES = '[Languages Page] Set Languages';
export const UPDATE_LANGUAGE = '[Languages Page] Update Language';
export const GET_ABOUT = ' [About Page] Get About';
export const SET_ABOUT = '[About Page] Set About';
export const UPDATE_PARENTAL_CODE = '[Parental Guidance] Update Code';
export const GET_ABOUT_INFO = '[About page] Get About Info';
export const SET_ABOUT_INFO = '[About page] Set About Info'; 



export class GetLanguages implements Action {
  readonly type = GET_LANGUAGES;

  constructor(public payload: string) {}
}

export class SetLanguages implements Action {
  readonly type = SET_LANGUAGES;

  constructor(public payload: Array<any>) {}
}

export class UpdateLanguage implements Action {
  readonly type = UPDATE_LANGUAGE;

  constructor(public payload: any) {}
}
export class GetAbout implements Action {
  readonly type = GET_ABOUT;

  constructor() {}
}

export class SetAbout implements Action {
  readonly type = SET_ABOUT;

  constructor(public payload: any) {}
}




export class UpdateParentalCode implements Action {
  readonly type = UPDATE_PARENTAL_CODE;

  constructor(public payload: any) {}
}

export class GetAboutInfo implements Action{
  readonly type = GET_ABOUT_INFO;  
}

export class SetAboutInfo implements Action{
  readonly type = SET_ABOUT_INFO;
  constructor(public payload: any){}
}

export type SettingsActions = GetLanguages | SetLanguages | UpdateLanguage | UpdateParentalCode | GetAboutInfo | SetAboutInfo | GetAbout | SetAbout;


class RoomChannelRights{
  Applications!: [
    { Internet: number; },
    { Youtube: number; },
    { Neflix: number; },
    { 'Game 1': number; },
    { 'Game 2': number; },
    { 'Game 3': number; },
    { 'Game 4': number; }
  ];
  Guest!: [
    { Profile: number; },
    { Questionnaire: number; },
    { 'Wake Up': number; },
    { Concierge: number; },
    { Loyalty: number; },
    { Preferences: number; },
    { Invoices: number; }
  ];
  'Hotel & Services': [
    {'Directory of Services': number},
    {'Daily Theme': number},
    {'Activities': number},
    {'Announcements': number},
    {'Facilities': number},
    {'Offers': number},
    {'Contact': number},
  ]
  Information!: [
    { 'Points of Interest': number; },
    { 'Flight Information': number; },
    { 'Bus Information': number; }
  ];
  'Reservations & Ordering': [
    {'Room Service': number},
    {'Facilities Reservations': number},
    {'E-Catalogue': number},
  ]
  Settings!: [
    { Language: number; },
    { 'Parental Guidance': number; },
    { 'Erase my Data': number; },
    { Reboot: number; },
    { 'Network info': number; }
  ];
  'TV & Entrertainment': [
    {TV: number},
    {'Hotel Channels': number},
    {'TV genres': number},
    {Movies: number},
    {PayTv: number},
    {Radio: number},
    {Music: number},
    {'Input source': number},
  ]
}

class RoomChannelTerms{
  [x: string]: any;
  HotelChannels!: {};
  HotelandServices!: {};
  IOAProfile!:{};
  ExecutiveMenu!:{};
}

export class RoomChannelSettings{
  rights!: RoomChannelRights;
  terms!: RoomChannelTerms;
  tv_hotspot!: number;
  tv_via!: number;
}

import { Pipe, PipeTransform } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { cloneDeep } from 'lodash';

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'toFloat' })
export class ToFloatFilterPipe implements PipeTransform {
  constructor(private core: CoreService) {}

  transform(value: any): any {
    let data = cloneDeep(value);
    return parseFloat(data).toFixed(2);
  }
}

<div [id]="id" class="ocgModal modal fade" role="dialog" aria-labelledby="ocgModalLabel" aria-hidden="true" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" [ngClass]="modalSize">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ocgModalLabel">{{ item?.title | titlecase | translate }}</h5>
      </div>
      <ng-content select=".modal-body"></ng-content>
      <ng-content select=".modal-footer"></ng-content>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreService } from '@app/services/core.service';
import 'lodash'

declare const _:any;

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'gender' })
export class GenderFilterPipe implements PipeTransform {
  constructor(private core: CoreService, protected sanitizer: DomSanitizer) {}

  transform(id: number): string {
    let gender: string = '';
    if (_.isEqual(id, 0)) gender = this.core.translate.instant('male');
    if (_.isEqual(id, 1)) gender = this.core.translate.instant('female');
    if (_.isEqual(id, 2)) gender = this.core.translate.instant('other');
    return gender;
  }
}

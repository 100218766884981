import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromApp from '@app/store/app.reducer';
import * as HotelServicesActions from './hotel-services.actions';

@Injectable()
export class HotelServicesEffects {
   /**
   * * Get Content Info from API
   *
   * @param uri
   * @returns []
   */
  @Effect()
  getcontactinfo = this.actions$.pipe(
    ofType(HotelServicesActions.GET_CONTACT_INFO),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([ actionState, storeState ]) => {
      let body = `&id=${storeState.tvbox_info.corporate}`;
      return this.http.post<any>(
        `${this.core.hostUrl}${(actionState as any).payload}${body}`,''
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get content list');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got Contact Info ----------');
        return new HotelServicesActions.SetContactInfo(res[0]);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService
    // private storage: StorageService
  ) {}
}

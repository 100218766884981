import * as HotelServicesActions from './hotel-services.actions';

export interface State {
  contactInfo: any
}

const initialState: State = {
  contactInfo : {}
}

export function hotelServicesReducer(
  state: State = initialState,
  action: HotelServicesActions.HotelServicesActions
): State {
  switch (action.type) {
    case HotelServicesActions.SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.payload
      }
    default:
      return state;
  }
}

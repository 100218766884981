import * as SettingsActions from './settings.actions';
export interface State {
  about: {
    image: string,
    text: string
  } | null
  languages: Array<any>;
  aboutInfo:any;
}


const initialState: State = {
  languages: [],
  aboutInfo: { image: '', text: '' },
  about: null
}


export function settingsReducer(
  state: State = initialState,
  action: SettingsActions.SettingsActions
): State {
  switch (action.type) {
    

    case SettingsActions.SET_ABOUT:
      return {
        ...state,
        about: action.payload
      };
    case SettingsActions.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      }
    case SettingsActions.SET_ABOUT_INFO:
      return{
        ...state,
        aboutInfo: action.payload
      } 
    default:
      return state;
  }
}



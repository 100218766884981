import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CoreService } from '@app/services/core.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {
  @Input() question!: any;
  @Input() id!: number;
  @Output() selectedEvaluation:EventEmitter<any> = new EventEmitter<any>();
  public questionString=this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question || this.question?.question


  constructor(private core: CoreService) { }

  ngOnInit(): void {
    this.questionString=this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question || this.question?.question
  }


  select(evn:any, id:string){
    let input:any = document.getElementById(id);
    input.checked = true
    this.selectedEvaluation.emit({id: this.id, value: input.value});
  }

}

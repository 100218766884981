import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as _ from 'lodash';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'timeZone' })
export class TimeZoneFilterPipe implements PipeTransform {
  timeOptions: any = {hour: '2-digit', minute: '2-digit', hour12: false, timeZone: null};

  constructor(
    private store: Store<fromApp.AppState>
  ) {}

  transform(date: any): Observable<string>  {
   return this.store.select('appStore').pipe(
    filter(state => !_.isUndefined(state.tvSettings.timeZone)),
    take(1),
    map(state => {
      this.timeOptions.timeZone = state.tvSettings.timeZone;
      let now = new Date();
      return now.toLocaleTimeString("en", this.timeOptions);
    })
   )
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromApp from '@app/store/app.reducer';
import * as ContentListActions from './category.actions';
import { ActivatedRoute } from '@angular/router';
import { includes, isEqual } from 'lodash';

@Injectable()
export class ContentListEffects {
   /**
   * * Get Content List from API
   *
   * @param uri
   * @returns []
   */
  @Effect()
  getcontentlist = this.actions$.pipe(
    ofType(ContentListActions.GET_CONTENT_LIST),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([ actionState, storeState ]) => {
      console.log("edw")
      let body: string;
      if (includes((actionState as any).payload, 'facilities')) {
        body = `&id=${storeState.tvbox_info.corporate}&channel_id=${storeState.tvbox_info.channel_id}&isTv=1`;
      }else {
        body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      }

      return this.http.post<any>(
        `${this.core.hostUrl}${(actionState as any).payload}${body}`,''
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get content list');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got ContentList----------');
        if (!isEqual(res, 'no data'))
          return new ContentListActions.SetContentList(res);
        else
          return new ContentListActions.SetContentList([]);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService
    // private storage: StorageService
  ) {}
}

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {  find, forEach, includes, isEqual, isNull, isUndefined } from 'lodash';

import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';

import * as legacyKeys from '@assets/interfaces/keys/legacyKeys.json';
import * as tizenKeys from '@assets/interfaces/keys/tizenKeys.json';
import { ActivatedRoute, Router } from '@angular/router';
import { TizenService } from './tizen.service';
import { LGService } from './lg.service';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ControlService {
  private platform!: string;
  //* Samsung Legacy Keys
  private sammyLegacyKeyMap: Map<number, number> = new Map(
    (legacyKeys as any).sammyLegacyNumbers
  );

  //* Samsung Tizen Keys
  private sammyTizenKeyMap: Map<number, number> = new Map(
    (tizenKeys as any).sammyTizenNumbers
  );
  public tizenChannelMajorNum!: number;
  public tizenUsefulKeys = (tizenKeys as any).tizenUsefulKeys;
  public tizenKeys = (tizenKeys as any).tizenKeys;

  public AudioVolume: number = 10;
  chIndex: number = 0;
  public channels: Array<any> = [];
  public tv_via!: number;
  chNumber:string = '';
  dashes:string = '';
  private timerInterval: any = null;
  public keyPressEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private store: Store<fromApp.AppState>,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private tizen: TizenService,
    private lg: LGService
  ) {
    store.select('appStore').subscribe((res) => {
      this.platform = res.platform;
      this.tv_via = res.roomChannelSettings.tv_via;
    });
    store.select('tvStore').subscribe((channels) => {
      this.channels = channels.channels;
    });
  }

  /**
   ** Common Keydown Function
   * @param evt
   */
  onKeyDown = (evt: KeyboardEvent) => {
    console.info(`pressed: ${evt.key}, code: ${evt.keyCode}`);

    if (isEqual(this.platform, 'SamsungTizen')) {
      this.tizenKeyHandler(evt);
    } else if (isEqual(this.platform, 'LG')) {
      this.lgKeyHandler(evt)
    } else if (isEqual(this.platform, 'PC')) {
      if (this.BACKPressed(evt)) {
        this._location.back();
      }
    }
  };

  /**
   ** Common OKpressed Function
   *
   * @param evt
   * @returns true/false
   */
  OKPressed = (evt: KeyboardEvent) => {
    if (
      evt.keyCode == 13 ||
      evt.keyCode == 29443 ||
      evt.type == 'click' ||
      evt.type == 'submit' ||
      evt.type == 'enter'
    ) {
      return true;
    }
    return false;
  };

   /**
   ** Common Backpressed Function
   *
   * @param evt
   * @returns true/false
   */
  private backButtonKeyCodes = [461, 88, 10009, 115, 27];
  BACKPressed(e: KeyboardEvent) {
    console.info(e.keyCode)
    if (this.backButtonKeyCodes.includes(e.keyCode)) {
      return true;
    }
    return false;
  }

  /**
   * * Tizen RF/IP Key Handle
   * @param evn
   */
  tizenKeyHandler(evn: KeyboardEvent) {
    let win: any = window;
    let keyCode: number = evn.keyCode;
    //evn.stopPropagation(); // TODO SN impl. pause executuion;

    if (this.BACKPressed(evn) || keyCode == this.tizenKeys.Exit ) {
      try {
        this.keyPressEmitter.next('return_pressed');
      } catch (error) {
          console.info("from error" + JSON.stringify(error));
      }
      //* Check if back button pressed or exit button
      setTimeout(() => {
        this.hideTizenChannelList();
      }, 200)
      console.info(window.location.href);

      //* Check if input source changed
      try {
        let source = win.tizen.tvwindow.getSource();
        console.info(JSON.stringify(source));
        if (_.isEqual(source.type, 'HDMI')) {
          win.tizen.tvwindow.hide((s:any) => {
            win.tizen.tvinputdevice.registerKeyBatch(this.tizenUsefulKeys);
          });
        }
      } catch (error:any) {
        console.info("Error name = "+ error.name + ", Error message = " + error.message);
      }

      // TODO Check if modal is open
      window.document.querySelector('.modal-backdrop')?.remove();

      if (document.querySelector('.app-container')?.classList.contains('overlay')) {
        document.querySelector('.app-container')?.classList.remove('overlay');
      }

      if (!!sessionStorage.getItem('onPlaying')) {
        (window as any).SpatialNavigation.enable('footer-btns');
        this.tizen.stopPlayers(sessionStorage.getItem('onPlaying')?.toString());
      }

      if (isNull(this.timerInterval)) {
        this.timerInterval = setInterval(() => {
          //console.info(this.timerInterval);
          clearInterval(this.timerInterval);

          if (!isEqual(this.router.url, '/home')) {
            this._location.back();
          }

          this.timerInterval = null;
        }, 800)
      } else return;

      if (this.router.url.includes('tv') && document.getElementById('app')?.style.opacity == '0') {
        document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
        let chList = document.getElementById('channel_list');
        chList?.setAttribute('style', 'display: none');
        let infoContainer = document.getElementById('ch_info_container');
        infoContainer?.setAttribute('style', 'display: none');
        this.tizen.stopPlayers('ipTv');
      } else if (!this.router.url.includes('home') && keyCode == this.tizenKeys.Exit) {
        this.router.navigate(['home']);
      }

    } else if (keyCode == 415) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let videoElement = document.getElementById('video');
            if (!!videoElement) {
              (videoElement as any).play();
            }
            break;
          default:
        }
      }
    } else if (keyCode == 19) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let videoElement = document.getElementById('video');
            if (!!videoElement) {
              (videoElement as any).pause();
            }
            break;
          default:
        }
      }
    } else if (keyCode == 417) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let videoElement = document.getElementById('video');
            if (!!videoElement) {
              let seekTime = (videoElement as any).currentTime + 10;
              if (seekTime >= 0 && seekTime <= (videoElement as any).duration) {
                (videoElement as any).currentTime = seekTime;
              }
            }
            break;
          default:
        }
      }
    } else if (keyCode == 412) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let videoElement = document.getElementById('video');
            if (!!videoElement) {
              let seekTime = (videoElement as any).currentTime - 10;
              if (seekTime >= 0 && seekTime <= (videoElement as any).duration) {
                (videoElement as any).currentTime = seekTime;
              }
            }
            break;
          default:
        }
      }
    } else if (this.router.url.includes('tv') && keyCode == this.tizenKeys.ChannelUp) {
       //* Handle channel up RF/IP
      if (this.tv_via == 1) {
        win.tizen.tvchannel.tuneUp({
            onsuccess: () => {
              console.info('Tune() is successfully done. And there is a signal.');
              this.tizen.setChannelRfInfo();
            },
            onnosignal: () => {
              console.info(
                'Tune() is successfully done. But there is no signal.'
              );
            },
          },
          (err: any) => {
            console.info(err);
          },
          'ALL'
        );
      } else {
        let lastChannelId = localStorage.getItem('openChId');
        if (!!lastChannelId) {
          win.b2bapis.b2brtpplay.stopChannel(
            (success: any) => {
              console.info('[stopChannel] success result: ' + success);
            },
            (error: any) => {
              console.info(
                '[stopChannel] code :' +
                  error.code +
                  ' error name: ' +
                  error.name +
                  '  message ' +
                  error.message
              );
            }
          );
          if (!!sessionStorage.getItem('onPlaying')) {
            switch(sessionStorage.getItem('onPlaying')) {
              case 'ipTv':
                find(this.channels, (ch, idx) => {
                  if (ch.id == lastChannelId) {
                    if (isEqual(idx, this.channels.length - 1)) {
                      this.tizen.watchIPTV(this.channels[0]);
                    } else {
                      this.tizen.watchIPTV(this.channels[idx + 1]);
                    }
                  }
                })
                break;
              default:
            }
          }
        }
      }
    } else if (this.router.url.includes('tv') && keyCode == this.tizenKeys.ChannelDown ) {
       //* Handle channel down RF/IP
      if (this.tv_via == 1) {
        win.tizen.tvchannel.tuneDown(
          {
            onsuccess: () => {
              console.info(
                'Tune() is successfully done. And there is a signal.'
              );
              this.tizen.setChannelRfInfo();
            },
            onnosignal: () => {
              console.info(
                'Tune() is successfully done. But there is no signal.'
              );
            },
          },
          (err: any) => {
            console.info(err);
          },
          'ALL'
        );
      } else {
        let lastChannelId = localStorage.getItem('openChId');
        if (!!lastChannelId) {
          win.b2bapis.b2brtpplay.stopChannel(
            (success: any) => {
              console.info('[stopChannel] success result: ' + success);
            },
            (error: any) => {
              console.info(
                '[stopChannel] code :' +
                  error.code +
                  ' error name: ' +
                  error.name +
                  '  message ' +
                  error.message
              );
            }
          );

          if (!!sessionStorage.getItem('onPlaying')) {
            switch(sessionStorage.getItem('onPlaying')) {
              case 'ipTv':
                find(this.channels, (ch, idx) => {
                  if (ch.id == lastChannelId) {
                    if (isEqual(idx, 0)) {
                      this.tizen.watchIPTV(this.channels[this.channels.length - 1]);
                    } else {
                      this.tizen.watchIPTV(this.channels[idx - 1]);
                    }
                  }
                })
                break;
              default:
            }
          }
        }
      }
    } else if (keyCode == this.tizenKeys.ChannelList) {

      if (this.tv_via == 1) {
        this.keyPressEmitter.next('ChannelList');
        this.tizen.launchTizenApplication(this.tizen.Apps['Channel-list'], true)
      } else {
        this.hideTizenChannelList();
      }
    } else if (keyCode == this.tizenKeys.Info) {
        if (this.tv_via == 1) {
          this.tizen.setChannelRfInfo();
        } else {
          let infoContainer = document.getElementById('ch_info_container');
          if (infoContainer?.style.display == 'none') {
            infoContainer?.setAttribute('style', 'display: block');
            setTimeout(() => {
              infoContainer?.setAttribute('style', 'display: none');
            }, 8000);
          } else {
            infoContainer?.setAttribute('style', 'display: none');
          }
        }

    } else if (this.sammyTizenKeyMap.has(evn.keyCode)) {

        (document.querySelector('.ch-numbers') as HTMLDivElement).style.display = 'block';
          setTimeout(() => {
            (document.querySelector('.ch-numbers') as HTMLDivElement).style.display = 'none';

            if(parseInt(this.chNumber) >= this.channels.length){
              this.chIndex = this.channels.length - 1;
            }else if(parseInt(this.chNumber) != 0){
              this.chIndex = parseInt(this.chNumber) - 1;
            }
            if (this.tv_via == 1) {
              console.info(JSON.stringify(this.channels[this.chIndex]));
              this.tizen.tizenChannelMajorNum = this.channels[this.chIndex].major;
              this.tizen.watchRFTV();
            } else {
              this.tizen.watchIPTV(this.channels[this.chIndex]);
            }
            document.getElementById('ch-num')!.innerHTML = '';
            document.getElementById('dashes')!.innerHTML = '';
            this.chNumber = '';
            this.dashes = '';
          }, 3000);
          if(this.chNumber.length < 3){
              this.chNumber += this.sammyTizenKeyMap.get(evn.keyCode);
              document.getElementById('ch-num')!.innerHTML = this.chNumber;
              this.chNumber.length == 1 && (this.dashes = '--');
              this.chNumber.length == 2 && (this.dashes = '-');
              this.chNumber.length == 3 && (this.dashes = '');
              document.getElementById('dashes')!.innerHTML = this.dashes;
          }
    }
  }

   hideTizenChannelList () {
    let container = document.getElementById('app');
    let chList = document.getElementById('channel_list');
    if (container?.style.opacity == '0') {
      if (chList?.style.display == 'none') {
        chList?.setAttribute('style', 'display: block;');
        let lastChannelId = localStorage.getItem('openChId');
        find(this.channels, (ch, idx) => {
          if (ch.id == lastChannelId) {
            document.getElementById('ch_'+idx)?.focus();// focus current channel in list
          }
        })
      } else
          chList?.setAttribute('style', 'display: none;');
    } else {
      chList?.setAttribute('style', 'display: none;');
    }
  }

   /**
   * * LG RF/IP Key Handle
   * @param evn
   */
  lgKeyHandler(evn: KeyboardEvent) {
    let win: any = window;
    let keyCode: number = evn.keyCode;

    if (keyCode == 461 || keyCode == 1001 || keyCode == 27) {
      //* Check if back button pressed or exit button

      // TODO Check if modal is open
      window.document.querySelector('.modal-backdrop')?.remove();

      if (document.querySelector('.app-container')?.classList.contains('overlay')) {
        document.querySelector('.app-container')?.classList.remove('overlay');
      }

      //* Check if Application is hidden
      if ((document.querySelector('.app-container') as HTMLElement)?.classList.contains('d-none')) {
        win.hcap.externalinput.setCurrentExternalInput({
          "type" : win.hcap.externalinput.ExternalInputType.COMPONENT,
          "index" : 0,
          "onSuccess" : () => {
              console.info("onSuccess setCurrentExternalInput TV");
              setTimeout(() => {
                document.querySelector('.app-container')?.classList.remove('d-none');
                if (keyCode == 1001) {
                 this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate(['home']);
                 })
                }
              }, 800);
          },
          "onFailure" : (f: any) => {
              console.info("onFailure : errorMessage = " + f.errorMessage);
              win.hcap.externalinput.setCurrentExternalInput({
                "type" : win.hcap.externalinput.ExternalInputType.HDMI,
                "index" : 2,
                "onSuccess" : () => {
                    console.info("onSuccess setCurrentExternalInput TV");
                    setTimeout(() => {
                      document.querySelector('.app-container')?.classList.remove('d-none');
                      if (keyCode == 1001) {
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                         this.router.navigate(['home']);
                        })
                      }
                    }, 800)
                },
                "onFailure" : (f: any) => {
                    console.info("onFailure : errorMessage = " + f.errorMessage);
                }
              })
          }
        });
      }

      if (!!sessionStorage.getItem('onPlaying')) {
        (window as any).SpatialNavigation.enable('footer-btns');
        this.lg.stopPlayers(sessionStorage.getItem('onPlaying') as string);
      }

      if (isNull(this.timerInterval)) {
        this.timerInterval = setInterval(() => {
          clearInterval(this.timerInterval);
          console.info(this.router.url);
          !isEqual(this.router.url, '/home') && this._location.back();

          this.timerInterval = null;
        }, 800)
      } else return;

      if (this.router.url.includes('tv')) {
        document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
        let chList = document.getElementById('channel_list');
        chList?.setAttribute('style', 'display: none');
        let infoContainer = document.getElementById('ch_info_container');
        infoContainer?.setAttribute('style', 'display: none');

        //document.querySelector('body')?.setAttribute('style', 'background-color: black;');
        this.lg.unregisterLGKeys();
        this.lg.LGTVFunctions('0', 'RF');
        this.lg.LGTVFunctions("0", "IP");

      } else if (!this.router.url.includes('home') && keyCode == 1001) {
        this.router.navigate(['home']);
      }
    } else if (this.router.url.includes('tv') && keyCode == 427) {
      //* Channel up
      let lastChannelId = localStorage.getItem('openChId');
      if (!!lastChannelId) {

        //* Stop Channel play
        win.hcap.channel.stopCurrentChannel({
          onSuccess: function () {
            console.info('onSuccess');
          },
          onFailure: function (f: any) {
            console.info('onFailure : errorMessage = ' + f.errorMessage);
          },
        });

        find(this.channels, (ch, idx) => {
          if (ch.id == lastChannelId) {
            if (isEqual(idx, this.channels.length - 1)) {
              this.lg.watchIPTV(this.channels[0]);
            } else {
              this.lg.watchIPTV(this.channels[idx + 1]);
            }
          }
        })
      }
    } else if (this.router.url.includes('tv') && keyCode == 428) {
      //* Channel down
      let lastChannelId = localStorage.getItem('openChId');
      if (!!lastChannelId) {

        //* Stop Channel play
        win.hcap.channel.stopCurrentChannel({
          onSuccess: function () {
            console.info('onSuccess');
          },
          onFailure: function (f: any) {
            console.info('onFailure : errorMessage = ' + f.errorMessage);
          },
        });

        find(this.channels, (ch, idx) => {
          if (ch.id == lastChannelId) {
            if (isEqual(idx, 0)) {
              this.lg.watchIPTV(this.channels[this.channels.length - 1]);
            } else {
              this.lg.watchIPTV(this.channels[idx - 1]);
            }
          }
        })
      }
    } else if (keyCode == 457) {
      if (this.tv_via == 1) {

      } else {
        let infoContainer = document.getElementById('ch_info_container');
        if (infoContainer?.style.display == 'none') {
          infoContainer?.setAttribute('style', 'display: block');
          setTimeout(() => {
            infoContainer?.setAttribute('style', 'display: none');
          }, 8000);
        } else {
          infoContainer?.setAttribute('style', 'display: none');
        }
      }
    } else if (keyCode == 602) {
      if (this.tv_via == 1) {

      } else {

        this.hideTizenChannelList()
      }
    } else if (keyCode == 415) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let win = (window as any);
            win.media.resume({
              "onSuccess" : () => {
                console.info("!--- Media Resume ---!");
              },
              "onFailure" : (f:any) => {
                  console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
            break;
          default:
        }
      }
    } else if (keyCode == 19) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let win = (window as any);
            win.media.pause({
              "onSuccess" : () => {
                console.info("!--- Media Pause ---!");
              },
              "onFailure" : (f:any) => {
                  console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
            break;
          default:
        }
      }
    } else if (keyCode == 417) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let win = (window as any);
            win.media.getPlayPosition({
              "onSuccess" : (s:any) => {
                console.info(s.positionInMs);
                win.media.setPlayPosition({
                  "positionInMs" : s.positionInMs + 10000,
                  "onSuccess" : (s:any) => {
                    console.info("!--- Media Seek 10sec forward ---!");
                  },
                  "onFailure" : (f:any) => {
                      console.info("onFailure : errorMessage = " + f.errorMessage);
                  }
                })
              },
              "onFailure" : (f:any) => {
                  console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
            break;
          default:
        }
      }
    } else if (keyCode == 412) {
      if (!!sessionStorage.getItem('onPlaying')) {
        switch(sessionStorage.getItem('onPlaying')) {
          case 'video':
            let win = (window as any);
            win.media.getPlayPosition({
              "onSuccess" : (s:any) => {
                console.info(s.positionInMs);
                win.media.setPlayPosition({
                  "positionInMs" : s.positionInMs - 10000,
                  "onSuccess" : (s:any) => {
                    console.info("!--- Media Seek 10sec forward ---!");
                  },
                  "onFailure" : (f:any) => {
                      console.info("onFailure : errorMessage = " + f.errorMessage);
                  }
                })
              },
              "onFailure" : (f:any) => {
                  console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
            break;
          default:
        }
      }
    }
  }
}

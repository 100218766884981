import * as SmartRoomActions from './smart-room.actions';

export interface State {
  roomAreas: Array<any>;
}

const initialState: State = {
  roomAreas: []
};

export function SmartRoomReducer(
  state: State = initialState,
  action: SmartRoomActions.SmartRoomActions
): State {
  switch (action.type) {
    case SmartRoomActions.SET_ROOM_AREAS:
      return {
        ...state,
        roomAreas: action.payload,
      };
    default:
      return state;
  }
}

import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from "@angular/core";

import { AlarmComponent } from './alarm.component';

@Injectable({ providedIn: 'root' })
export class AlarmService {
  private ref!:ComponentRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

  createComponent = (component?: any, question?:string) => {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( AlarmComponent );
    const ngContent = this.resolveNgContent(component, question);
    const componentRef = componentFactory.create(this.injector, ngContent);
    this.ref = componentRef;
    return componentRef;
  };

  resolveNgContent(content: any, question?: string) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(content);
    const componentRef = factory.create(this.injector);
    componentRef.hostView.detectChanges();
    return [[componentRef.location.nativeElement]];
  }
}

import { Action } from '@ngrx/store';

export const GET_FACILITIES_CATEGORIES = '[Reservations] Get Facilities Categories';
export const SET_FACILITIES_CATEGORIES = '[Reservations] Set Facilities Categories';
export const SET_FACILITIES = '[Reservations] Set Facilities';
export const GET_FACILITY_SERVICES ='[Reservations] Get Facility Services';
export const SET_FACILITY_SERVICES ='[Reservations] Set Facility Services';

export class GetFacilitiesCategories implements Action {
  readonly type = GET_FACILITIES_CATEGORIES;
}

export class SetFacilitiesCategories implements Action {
  readonly type = SET_FACILITIES_CATEGORIES;

  constructor(public payload: any) {}
}

export class SetFacilities implements Action {
  readonly type = SET_FACILITIES;

  constructor(public payload: Array<any> | null) {}
}

export class GetFacilityServices implements Action {
  readonly type = GET_FACILITY_SERVICES;

  constructor(public payload?: any | null) {}
}

export class SetFacilityServices implements Action {
  readonly type = SET_FACILITY_SERVICES;

  constructor(public payload: Array<any> | null) {}
}


export type reservationsActions =
  | GetFacilitiesCategories
  | SetFacilitiesCategories
  | SetFacilities
  | GetFacilityServices
  | SetFacilityServices;

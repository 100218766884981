import { Action } from '@ngrx/store';

export const GET_CONTACT_INFO = '[Contact Page] Get Contact Info';
export const SET_CONTACT_INFO = '[Contact Page] Set Contact Info';

export class GetContactInfo implements Action {
  readonly type = GET_CONTACT_INFO;

  constructor(public payload: string){};
}
export class SetContactInfo implements Action {
  readonly type = SET_CONTACT_INFO;

  constructor(public payload: any ) {}
}

export type HotelServicesActions =
  | GetContactInfo
  | SetContactInfo;

<div class="header_comp">
  <div class="row h-100">
    <div class="col-2 header_comp__brand" *ngIf="(data$ | async).lastLogo">
      <img [src]="(data$ | async).lastLogo" (error)="core.errorImageHandler($event, core.getFagorExclusiveImages().offlineLogo)" class="navbar-brand" alt="Responsive image">
    </div>
    <div class="col-10 header_comp__infos">
      <div class="actions">
        <span *ngIf="!(data$ | async).deviceOnline" class="actions__offline"></span>
        <span *ngIf="(data$ | async).roomChannelSettings.tv_hotspot == 1 && core.platform != 'PC'" class="actions__wifi">
          <span>{{ (data$ | async).SSID }}:</span>
          <span>{{ (data$ | async).wifiPass }}</span>
        </span>
      </div>
      <div class="date">{{ today | date: "EEE, dd MMMM":"":locale | titlecase }}</div>
      <div class="time">{{ today | date: "HH:mm" | timeZone | async}}</div>
    </div>
  </div>
</div>

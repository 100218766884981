import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import Splide from '@splidejs/splide';
import { OCGSplideSlideComponent } from './slide-item.components';

@Directive({
  selector: '.ocg-slide-item',
})
export class OcgSlideItemElement {}

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(OCGSplideSlideComponent)
  public slides!: QueryList<OCGSplideSlideComponent>;
  @ViewChildren(OcgSlideItemElement, { read: ElementRef })
  private itemsElements!: QueryList<ElementRef>;

  @ViewChild('splideElement') splideElement!: ElementRef;
  public splide: any;

  @Input() selectedSlideIndex!: number;
  @Output() selectedSlideIndexChange = new EventEmitter<number>();

  @Output() onInit = new EventEmitter<any>();
  @Output() onSplideEvent = new EventEmitter<any>();

  @Output() onMounted = new EventEmitter();
  @Output() onUpdated = new EventEmitter();
  @Output() onMove = new EventEmitter();
  @Output() onMoved = new EventEmitter();
  @Output() onDrag = new EventEmitter();
  @Output() onDragging = new EventEmitter();
  @Output() onDragged = new EventEmitter();
  @Output() onVisible = new EventEmitter();
  @Output() onHidden = new EventEmitter();
  @Output() onActive = new EventEmitter();
  @Output() onInactive = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onArrowsMounted = new EventEmitter();
  @Output() onArrowsUpdated = new EventEmitter();
  @Output() onPaginationMounted = new EventEmitter();
  @Output() onPaginationUpdated = new EventEmitter();
  @Output() onNavigationMounted = new EventEmitter();
  @Output() onAutoplayPlay = new EventEmitter();
  @Output() onAutoplayPause = new EventEmitter();
  @Output() onAutoplayPlaying = new EventEmitter();
  @Output() onLazyloadLoaded = new EventEmitter();

  @Input() options: any = {};


  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.splide = new Splide(this.splideElement.nativeElement, this.options);
    this.onInit.emit(this.splide);
    this.addEventListeners();
    this.splide.mount();

    const slidesSubscription = this.slides.changes.subscribe(
      (list: QueryList<OCGSplideSlideComponent>) => {
        this.cdr.detectChanges();

        setTimeout(() => {
          this.splide.destroy();
          this.splide.mount();

          this.addEventListeners();
        });
      }
    );

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.splide) {
      return;
    }

    if (changes['selectedSlideIndex']) {
      const currentIndex = changes['selectedSlideIndex'].currentValue;
      if (currentIndex !== this.splide.index) {
        this.splide.go(currentIndex);
      }
    }

    if (changes['options']) {
      this.splide.options = changes['options'].currentValue;
    }
  }

  addEventListeners() {
    this.splide
      .on('mounted', (...args: any) => {
        this.onMounted.emit(args);
        this.onSplideEvent.emit({
          name: 'mounted',
          args,
        });
      })
      .on('updated', (...args: any) => {
        this.onUpdated.emit(args);
        this.onSplideEvent.emit({
          name: 'updated',
          args,
        });
      })
      .on(
        'move',
        (newIndex: number | undefined, oldIndex: any, destIndex: any) => {
          this.selectedSlideIndexChange.emit(newIndex);
          this.onMove.emit([newIndex, oldIndex, destIndex]);

          this.onSplideEvent.emit({
            name: 'move',
            args: [newIndex, oldIndex, destIndex],
          });
        }
      )
      .on('moved', (...args: any) => {
        this.onMoved.emit(args);

        this.onSplideEvent.emit({
          name: 'moved',
          args,
        });
      })
      .on('drag', (...args: any) => {
        this.onDrag.emit(args);

        this.onSplideEvent.emit({
          name: 'drag',
          args,
        });
      })
      .on('dragging', (...args: any) => {
        this.onDragging.emit(args);

        this.onSplideEvent.emit({
          name: 'dragging',
          args,
        });
      })
      .on('dragged', (...args: any) => {
        this.onDragged.emit(args);

        this.onSplideEvent.emit({
          name: 'dragged',
          args,
        });
      })
      .on('visible', (...args: any) => {
        this.onVisible.emit(args);

        this.onSplideEvent.emit({
          name: 'visible',
          args,
        });
      })
      .on('hidden', (...args: any) => {
        this.onHidden.emit(args);

        this.onSplideEvent.emit({
          name: 'hidden',
          args,
        });
      })
      .on('active', (...args: any) => {
        this.onActive.emit(args);

        this.onSplideEvent.emit({
          name: 'active',
          args,
        });
      })
      .on('inactive', (...args: any) => {
        this.onInactive.emit(args);

        this.onSplideEvent.emit({
          name: 'inactive',
          args,
        });
      })
      .on('click', (...args: any) => {
        this.onClick.emit(args);

        this.onSplideEvent.emit({
          name: 'click',
          args,
        });
      })
      .on('arrows:mounted', (...args: any) => {
        this.onArrowsMounted.emit(args);

        this.onSplideEvent.emit({
          name: 'arrows:mounted',
          args,
        });
      })
      .on('arrows:updated', (...args: any) => {
        this.onArrowsUpdated.emit(args);

        this.onSplideEvent.emit({
          name: 'arrows:updated',
          args,
        });
      })
      .on('pagination:mounted', (...args: any) => {
        this.onPaginationMounted.emit(args);

        this.onSplideEvent.emit({
          name: 'pagination:mounted',
          args,
        });
      })
      .on('pagination:updated', (...args: any) => {
        this.onPaginationUpdated.emit(args);

        this.onSplideEvent.emit({
          name: 'pagination:updated',
          args,
        });
      })
      .on('navigation:mounted', (...args: any) => {
        this.onNavigationMounted.emit(args);

        this.onSplideEvent.emit({
          name: 'navigation:mounted',
          args,
        });
      })
      .on('autoplay:play', (...args: any) => {
        this.onAutoplayPlay.emit(args);

        this.onSplideEvent.emit({
          name: 'autoplay:play',
          args,
        });
      })
      .on('autoplay:pause', (...args: any) => {
        this.onAutoplayPause.emit(args);

        this.onSplideEvent.emit({
          name: 'autoplay:pause',
          args,
        });
      })
      .on('autoplay:playing', (...args: any) => {
        this.onAutoplayPlaying.emit(args);

        this.onSplideEvent.emit({
          name: 'autoplay:playing',
          args,
        });
      })
      .on('lazyload:loaded', (...args: any) => {
        this.onLazyloadLoaded.emit(args);

        this.onSplideEvent.emit({
          name: 'lazyload:loaded',
          args,
        });
      });
  }

  redraw() {
    this.splide.refresh();
  }

  move(idx: number) {
    this.splide.go(idx);
  }

  ngOnDestroy() {
    if (this.splide) {
      this.splide.destroy(true);
    }
  }

}

<div class="survey-checkbox mt-5"></div>
<div class="col-12">
  <h3 class="survey-checkbox--question">{{ questionString }}</h3>
  <form>
    <div class="survey-checkbox__answers focusable-evaluation" [ngClass]="'surveyquest_'+id"  tabindex="-1" *ngFor="let ans of answers" (keydown.enter)="focusCheck($event, ans)">
      <p class="text-white">{{answerTranslate(ans)}}</p>
      <input id="{{ans.id}}" type="radio" name="radio">
      <span class="single_checkmark"></span>
    </div>
  </form>
</div>

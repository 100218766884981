import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlService } from '@app/services/control.service';
import { CoreService } from '@app/services/core.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() question!: any;
  @Input() id!: number;
  @Output() selectedTextarea:EventEmitter<any> = new EventEmitter<any>();
  public questionString=this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question

  constructor(private control: ControlService, private core:CoreService) { }

  ngOnInit(): void {
    this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question || this.question?.question
  }

  focusedInput(evn: KeyboardEvent){
    if(evn.keyCode == 13 || evn.keyCode == 29443){
      //this.control.toggleKeyboard(); //TODO keyboard frame
    }
  }

  saveText(evn: any){
    //console.log(evn.srcElement.value);
    if(evn.srcElement.value == '') return;
    this.selectedTextarea.emit({id: this.id, value: evn.srcElement.value})
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'splash', loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashModule) },
  { path: 'category', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule) },
  { path: 'weather', loadChildren: () => import('./pages/information/weather/weather.module').then(m => m.WeatherModule) },
  { path: 'profile', loadChildren: () => import('./pages/guest/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'bus', loadChildren: () => import('./pages/information/bus/bus.module').then(m => m.BusModule) },
  { path: 'daily-theme', loadChildren: () => import('./pages/hotel_services/daily-theme/daily-theme.module').then(m => m.DailyThemeModule) },
  { path: 'parental-guidance', loadChildren: () => import('./pages/settings/parental-guidance/parental-guidance.module').then(m => m.ParentalGuidanceModule) },
  { path: 'language', loadChildren: () => import('./pages/settings/languages/languages.module').then(m => m.LanguagesModule) },
  { path: 'about', loadChildren: () => import('./pages/settings/about/about.module').then(m => m.AboutModule) },
  { path: 'points-of-interest', loadChildren: () => import('./pages/information/point-of-interest/point-of-interest.module').then(m => m.PointOfInterestModule) },
  { path: 'activities', loadChildren: () => import('./pages/hotel_services/activities/activities.module').then(m => m.ActivitiesModule) },
  { path: 'facilities-categories', loadChildren: () => import('./pages/hotel_services/facilities/facilities.module').then(m => m.FacilitiesModule) },
  { path: 'applications', loadChildren: () => import('./pages/applications/applications.module').then(m => m.ApplicationsModule) },
  { path: 'room-service', loadChildren: () => import('./pages/reservations/room-service/room-service.module').then(m => m.RoomServiceModule) },
  { path: 'contact', loadChildren: () => import('./pages/hotel_services/contact/contact.module').then(m => m.ContactModule) },
  { path: 'tv', loadChildren: () => import('./pages/tv-and-entertainment/tv/tv.module').then(m => m.TvModule) },
  { path: 'radio', loadChildren: () => import('./pages/tv-and-entertainment/radio/radio.module').then(m => m.RadioModule) },
  { path: 'music', loadChildren: () => import('./pages/tv-and-entertainment/music/music.module').then(m => m.MusicModule) },
  { path: 'hotel-channels', loadChildren: () => import('./pages/tv-and-entertainment/hotel-channels/hotel-channels.module').then(m => m.HotelChannelsModule) },
  { path: 'pay-tv', loadChildren: () => import('./pages/tv-and-entertainment/pay-tv/pay-tv.module').then(m => m.PayTvModule) },
  { path: 'questionnaire', loadChildren: () => import('./pages/guest/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule) },
  { path: 'preferences', loadChildren: () => import('./pages/guest/preferences/preferences.module').then(m => m.PreferencesModule) },
  { path: 'e-catalogue', loadChildren: () => import('./pages/reservations/e-catalogue/e-catalogue.module').then(m => m.ECatalogueModule) },
  { path: 'facilities-reservations', loadChildren: () => import('./pages/reservations/facilities/facilities.module').then(m => m.FacilitiesModule) },
  { path: 'wake-up', loadChildren: () => import('./pages/guest/wake-up/wake-up.module').then(m => m.WakeUpModule) },
  { path: 'concierge', loadChildren: () => import('./pages/guest/concierge/concierge.module').then(m => m.ConciergeModule) },
  { path: 'invoices', loadChildren: () => import('./pages/guest/invoices/invoices.module').then(m => m.InvoicesModule) },
  { path: 'notifications', loadChildren: () => import('./pages/guest/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'smart-room', loadChildren: () => import('./pages/smart-room/smart-room.module').then(m => m.SmartRoomModule) },
  { path: 'chromecast', loadChildren: () => import('./pages/tv-and-entertainment/chromecast/chromecast.module').then(m => m.ChromecastModule) },
  { path: 'movies', loadChildren: () => import('./pages/tv-and-entertainment/movies/movies.module').then(m => m.MoviesModule) },
  { path: 'about', loadChildren: () => import('./pages/settings/about/about.module').then(m => m.AboutModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AppInitialActions from '@app/store/app.actions';
import * as fromApp from '@app/store/app.reducer';
import { CoreService } from './core.service';
import { findIndex, isEqual } from 'lodash';
import { StorageService } from './storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LGService {
  private firstrun:boolean = true;
  private bootInterval:boolean = false;
  constructor(
    private store: Store<fromApp.AppState>,
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   *  *Initial LG Tv
   *  @param core service
   */
  init(core: CoreService): Promise<any> {
    setTimeout(() => {
      this.bootInterval = true
    }, 65000)
    let promise = new Promise((resolve, reject) => {
      let win: any = window;

      win.hcap.property.getProperty({
        "key" : "soft_ap",
        "onSuccess" : function(s: any) {console.info("onSuccess : soft_ap property value = " + s.value);},
        "onFailure" : function(f: any) {console.info("onFailure : errorMessage = " + f.errorMessage);}
      });

      win.hcap.property.setProperty({"key" : "soft_ap_ui", "value" : "1",
          "onSuccess" : function() {
            console.info("onSuccess soft_ap_ui 1");
            win.hcap.property.setProperty({
              "key" : "soft_ap",
              "value" : '0',
              "onSuccess" : function() {console.info("onSuccess soft_ap 0");},
              "onFailure" : function(f:any) {console.info("onFailure : soft_ap 0 " + JSON.stringify(f));}
            });
          },
          "onFailure" : function(f:any) {console.info("onFailure : soft_ap_ui" + JSON.stringify(f));}
      });

      // win.hcap.property.setProperty({"key" : "maximum_volume_level", "value" : "20",
      //     "onSuccess" : function() {
      //       console.info("onSuccess maximum_volume_level set as 20");
      //     },
      //     "onFailure" : function(f:any) {console.info("onFailure : soft_ap_ui" + JSON.stringify(f));}
      // });

      //* Add list key
      win.hcap.key.addKeyItem({
        "keycode" : 0x00000000,
        "virtualKeycode" : win.hcap.key.Code.LIST,
        "attribute" : 2, // 2 processed by application
        "onSuccess" : function() {
            console.info("onSuccess addKeyItem LIST");
        },
        "onFailure" : function(error: any) {
            console.info("onFailure : errorMessage = " + error.errorMessage);
        }
      });

      //* Initial Properties
      win.hcap.property.setProperty({"key" : "display_resolution", "value" : "1920x1080",
          "onSuccess" : function() {/* console.info("onSuccess display_resolution"); */},
          "onFailure" : function(error: any) {/* console.info("onFailure : display_resolution" + f.errorMessage); */}
      });
      win.hcap.property.setProperty({"key" : "tv_channel_attribute_floating_ui", "value" : "0",
          "onSuccess" : function() {/* self.data.toastr.show("onSuccess"); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : tv_channel_attribute_floating_ui " + f.errorMessage); */}
      });
      win.hcap.property.setProperty({"key" : "tv_channel_ui", "value" : "0",
          "onSuccess" : function() {/* self.data.toastr.show("onSuccess"); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : tv_channel_ui " + f.errorMessage); */}
      });
      win.hcap.property.setProperty({"key" : "tv_volume_ui", "value" : "0",
          "onSuccess" : function() {/* self.data.toastr.show("onSuccess"); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : tv_volume_ui " + f.errorMessage); */}
      });
      win.hcap.property.setProperty({"key" : "boot_sequence_option", "value" : "1",
          "onSuccess" : function() {/* self.data.toastr.show("onSuccess"); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : boot_sequence_option " + f.errorMessage); */}
      });
      win.hcap.property.setProperty({"key" : "mute_on_tv_input", "value" : "1",
          "onSuccess" : function() {/* self.data.toastr.show("onSuccess"); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : mute_on_tv_input " + f.errorMessage); */}
      });
      win.hcap.system.setNoSignalImage({"noSignalImage" : false,
          "onSuccess" : function() {/* self.data.toastr.success(JSON.stringify(s)); */},
          "onFailure" : function(error: any) {/* self.data.toastr.error("onFailure : noSignalImage" + f.errorMessage); */}
      });

      win.hcap.network.getNetworkInformation({
        "onSuccess" : async (result: any) => { //------------------------- LG TV ------
          let IP = null, MAC = null, Duid = null, deviceOnline = false;
          //* Get Tv IP
          try {IP = result.ip_address} catch (e:any) {
            console.info("getIp exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
          }
          //* Get Tv MAC address
          try {MAC = await this.getMAC(IP)} catch (e:any) {
            console.info("getMac exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
          }
          //* Get Tv token
          try {Duid = await this.getDeviceToken()} catch (e:any) {
            console.info("getToken exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
          }
          //* Get Tv online
          try {deviceOnline = await core.onlineCheck()} catch (e:any) {
            console.info("getDevices exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
          }

          //* Save initial tv data to NgRx State
          this.store.dispatch(
            new AppInitialActions.SetPlatformValues({
              IPv4: IP != null ? IP : 'Not available',
              IPv6: 'Not available',
              MAC: MAC != null ? MAC : 'Not available',
              deviceToken: Duid,
              deviceOnline: deviceOnline
            })
          );

          //* catch necessary LG TV keys
          this.unregisterLGKeys();
          //* Initial additional classes
          this.getTVApps(core);

          //* Input Event Listener
          document.addEventListener("external_input_changed", () => {
              console.info("Event 'external_input_changed' is received.");
              if (!this.bootInterval) {
                return false;
              }
              if (!!sessionStorage.getItem('onPlaying') && sessionStorage.getItem('onPlaying') !== 'radio') {
                document.querySelector('.app-container')?.classList.add('d-none');
              }
              if (!!sessionStorage.getItem('input_from_app')) {
                document.querySelector('.app-container')?.classList.add('d-none');
                sessionStorage.removeItem('input_from_app');
              }
              win.hcap.externalinput.getCurrentExternalInput({
                "onSuccess":  (s:any) => {
                  this.store.pipe(take(1)).subscribe(res => {
                      let startupInputType = res.appStore.startupInputType;
                      let startupInputIndex = res.appStore.startupInputIndex;
                      if ((this.getTvInputTypeName(s.type) !== startupInputType || (this.getTvInputTypeName(s.type) === startupInputType &&  s.index !== startupInputIndex)) && sessionStorage.getItem('onPlaying') !== 'radio') {
                        document.querySelector('.app-container')?.classList.add('d-none');
                      } else {
                        document.querySelector('.app-container')?.classList.remove('d-none');
                      }
                  })
                },
              "onFailure": function (f:any) {
                console.info("onFailure : errorMessage = " + f.errorMessage);
            }
              })
              return false;
            },
            false
          );
          this.setupInitalInput();
          console.info('------ LG TV Ready ------');
          resolve(true);
        },
        "onFailure" : (error: any)=> { //------------------------- LG TV ------
          //console.info(JSON.stringify(error));
          resolve(false);
        }
      });

    });
    return promise;
  };

  /**
 *  *Get LG Tv Device Token
 *  @returns token: string;
 */
  getDeviceToken(): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let win: any = window;
      win.hcap.property.getProperty({"key" : "serial_number",
        "onSuccess" : (s:any)=> {
          resolve(s.value)
        },
        "onFailure" : (err:any) => {
          console.info("onFailure : errorMessage = " + err.errorMessage);
        }
      });
    });
    return promise;
  }

  /**
   *  *Get LG Tv Mac Address
   *  @returns mac_address: string;
   */
  getMAC(ip: any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let win: any = window;
      win.hcap.network.getNumberOfNetworkDevices({
        "onSuccess" : (res: any) => {
          for (var i = 0; i < res.count; i++) {
            win.hcap.network.getNetworkDevice({
              "index" : i,
              "onSuccess" : (device: any) => {
                if (ip === device.ip) {
                  resolve(device.mac);
                }
              },
              "onFailure" : (err:any) => {
                console.info("onFailure : errorMessage = " + err.errorMessage);
              }
            });
          }
        },
        "onFailure" : (err: any) => {
          console.info("onFailure : errorMessage = " + err.errorMessage);
        }
      });

    });
    return promise;
  };

  /**
   *  * Get Tv Apps
   *  @param core service
   */
   getTVApps(core: CoreService) {
    let tvAppsMapping:any = {
      "YouTube": "Youtube",
      "Web Browser": "Internet",
      "Netflix": null
    }
    let win: any = window;
    win.hcap.preloadedApplication.getPreloadedApplicationList({
      "onSuccess" : (apps: any) => {
        let appsArray: Array<any> = [];
        for (var i = 0; i < apps.list.length; i++) {
          if (tvAppsMapping.hasOwnProperty(apps.list[i].title)) {
            appsArray.push({ //save apps in navigationItems data structure
              title: tvAppsMapping[apps.list[i].title],
              imgPath: apps.list[i].iconFilePath,
              slug: apps.list[i].id,
              hover: false,
              enabled: 1,
              blockedWhenOffline:1
          });
          }
        }
        console.info("LG TV filtered Apps :" + JSON.stringify(appsArray))
        this.store.dispatch(new AppInitialActions.SetTvApplictions(appsArray));
      },
      "onFailure" : function(error: any) {
        console.info("onFailure : getPreloadedApplicationList " + error.errorMessage);
      }
    });
  }

   /**
   *  * Set Tv Hotspot
   *  @param core service
   */
  setTVHotSpot(core: CoreService) {
    let win: any = window;
    win.hcap.property.setProperty({"key" : "soft_ap_ui", "value" : "1",
      "onSuccess": () => {
        console.info("!---- Enable LG Hotspot --- !");

        //* Config Hotspot
        win.hcap.network.setSoftAP({
          "channel" : 9,
          "signalStrength" : 5,
          "vlanId" : 0,
          "vlanIp" : "192.168.11.3",
          "vlanSubnet" : "255.255.255.0",
          "vlanGateway" : "192.168.11.1",
          "onSuccess" : (s:any)=> {

              //! Get Password for storage if exist (Deprecated)
              //let pass = this.storage.read('hotspotPass').hotspotPass;
              // if (!pass) {
              //   pass = core.makeRandomPass(8);
              //   this.storage.save('hotspotPass', pass);
              // }

              let SSID: string = '';
              let PASSWORD: any;
              this.store.select('appStore').pipe(
                filter(appState => !!appState.tvbox_info.weefee),
                take(1)
              ).subscribe(appState => {
                SSID = appState.tvbox_info.weefee.ssid;
                PASSWORD = appState.tvbox_info.weefee.password
              })


              //* Enable Hotspot
              setTimeout(() => {

               //* Set password programmaticaly
                win.hcap.property.setProperty({"key" : "soft_ap_password", "value" : PASSWORD,
                  "onSuccess" : ()=> {
                    console.info(`!---Set Hotspot Password : ${PASSWORD}`);
                  },
                  "onFailure" : (f: any)=> {console.info("onFailure : soft_ap_password" + f.errorMessage);}
                });

                 //* Set SSID
                win.hcap.property.setProperty({"key" : "tv_name", "value" : SSID,
                "onSuccess" : ()=> {
                  console.info(`!---Set Hotspot SSID : ${SSID}`);
                },
                  "onFailure" : function(f:any) {console.info("onFailure setProperty: tv_name" + f.errorMessage);}
                });

                win.hcap.property.setProperty({"key" : "soft_ap", "value" : "1",
                    "onSuccess" : ()=> {
                      console.info("onSuccess softAP 1");
                      this.store.dispatch(new AppInitialActions.SetHotspot({ssid: SSID, pass: PASSWORD})); //? Pass Hotspot SSID/PASS to state
                    },
                    "onFailure" : (f:any)=> {console.info("onFailure : soft_ap" + f.errorMessage);}
                });

              }, 3000);
          },
          "onFailure" : (e:any)=> {
            console.info("onFailure setSoftAP : errorMessage = " + e.errorMessage);
            setTimeout(() => {
                win.hcap.property.setProperty({"key" : "soft_ap", "value" : "0",
                "onSuccess": () => {
                  this.setTVHotSpot(core);
                }
              })
            }, 3000)
          }
        });
      },
      "onFailure" : (e: any)=> {console.info("onFailure : soft_ap_ui" + e.errorMessage);}
    });
  }

  watchRFTV(){
    try {
      let win:any = window;
      win.hcap.externalinput.getCurrentExternalInput({
          "onSuccess" : (s:any) => {
              console.info('ExternalInputType '+s.type+' '+s.index)
              if(s.type == win.hcap.externalinput.ExternalInputType.TV){
                  console.info('externalinput already on TV, starting...')
                  this.initRFChannel();
              }else{
                  console.info('externalinput NOT on TV, setting CurrentExternalInput...');
                  
                  win.hcap.externalinput.setCurrentExternalInput({
                    "type" : win.hcap.externalinput.ExternalInputType.TV,
                    "index" : 0,
                    "onSuccess" : () => {
                        console.info("onSuccess setCurrentExternalInput TV");
                        this.initRFChannel();
                    },
                    "onFailure" : (f:any) => {
                        console.info("onFailure : errorMessage = " + f.errorMessage);
                        this.initRFChannel(); // init anyway
                    }
                });
           
              }
          },
          "onFailure" : (f:any) => {
              console.log("onFailure : errorMessage = " + f.errorMessage);
          }
      });
    } catch (error: any) {
        console.info('watchLG_RFTV error: '+error.toString());
    }
  }

   /**
   ** RF channel initialization
   */
  initRFChannel(){
    let win:any = window;

    win.hcap.channel.requestChangeCurrentChannel({
        "channelType" : win.hcap.channel.ChannelType.RF,
        "logicalNumber" : 1,
        "rfBroadcastType" : win.hcap.channel.RfBroadcastType.TERRESTRIAL,
        "onSuccess" : function() {
            console.info('onSuccess requestChangeCurrentChannel');
        },
        "onFailure" : function(f:any) {
            console.info('onFailure requestChangeCurrentChannel');
        }
    });
    //document.querySelector('body')?.setAttribute('style', 'background-color: transparent;');
    this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' });
    this.registerLGKeys();
    this.LGTVFunctions('1', 'RF');
  }

   /**
   ** IPTV Watch Tv
   * @param channel
   */
  watchIPTV(channel:any, radio?: any) {
    let win: any = window;
    let link = this.parseLink(channel.link);
    let chObj = this.getChannelObj(link);

    console.info(JSON.stringify(chObj));

    win.hcap.externalinput.getCurrentExternalInput({
    "onSuccess" : (s:any) => {
      console.info('getCurrentExternalInput onsuccess');
      console.info(s.type +' '+s.index);

      if (!radio) {
        this.setChannelInfo(channel.id);
        let channelInfo = document.getElementById('ch_info_container');
        channelInfo?.setAttribute('style', 'display: block');
        setTimeout(() => {
          channelInfo?.setAttribute('style', 'display: none');
        }, 8000);
      }

      localStorage.setItem('openChId', channel.id);
      if(s.type == win.hcap.externalinput.ExternalInputType.TV && !radio){
        win.hcap.channel.requestChangeCurrentChannel(chObj);
        this.LGTVFunctions("1", 'IP');
        this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' })
        console.info(1);
      }else if (radio){
        win.hcap.channel.requestChangeCurrentChannel(chObj);
        this.LGTVFunctions("1", 'RADIO');
        sessionStorage.setItem('onPlaying', 'radio');
        //this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' })
      } else {
        win.hcap.externalinput.setCurrentExternalInput({
          "type" : 1,
          "index" : 0,
          "onSuccess" : ()=> {
            console.info('setCurrentExternalInput onSuccess');
            win.hcap.channel.requestChangeCurrentChannel(chObj);
            this.LGTVFunctions("1", 'IP');
            this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' })
            console.info(2);
          },
          "onFailure" : function(f:any) {
            console.info("setCurrentExternalInput = " + f.errorMessage);
          }
        });
      }
      },
      "onFailure" : function(f:any) {
        console.log("onFailure : errorMessage = " + f.errorMessage);
      }
    });
  }

  /**
   ** Reboot Tv
   */
  reboot() {
    let win: any = window;
    win.hcap.power.reboot({
      "onSuccess" : function() {
          console.log("onSuccess");
      },
      "onFailure" : function(f: any) {
          console.log("onFailure : errorMessage = " + f.errorMessage);
      }
    });
  }

   /**
    ** IPTV parse link functionality
    * @param chlink
    * @returns link: array
    */
   parseLink(chlink: string): any {
    let win: any = window;
    var link: { ip: string; port: number; type: any } = {
      ip: '1.1.1.1',
      port: 1234,
      type: chlink.startsWith('rtp')
        ? win.hcap.channel.IpBroadcastType.RTP
        : win.hcap.channel.IpBroadcastType.UDP,
    };
    if (chlink.includes('@')) {
      link.ip = chlink.split('@')[1].split(':')[0];
    } else {
      link.ip = chlink.split('//')[1].split(':')[0];
    }
    link.port = parseInt(chlink.split(':')[2]);
    return link;
  }

  /**
   ** Get channel Object modified for LG
   * @param params
   * @returns channel: object
   */
  getChannelObj(params: any) {
    return {
      channelType: (window as any).hcap.channel.ChannelType.IP,
      ip: params.ip,
      port: params.port,
      ipBroadcastType: params.type,
      onSuccess: function () {
        console.info('requestChangeCurrentChannel onSuccess');
      },
      onFailure: function (f: any) {
        console.info('requestChangeCurrentChannel Error ' + f.errorMessage);
      },
    };
  };

   /**
    ** Extra Lg Functionality
    * @param param
    * @param type
    */
   LGTVFunctions(param: any, type: string) {
    //mute - unmute TV, show - hide channel/volume/floating UI, TV controller handling
    let win: any = window;
    win.hcap.property.setProperty({
      key: 'tv_channel_attribute_floating_ui',
      value: param,
      onSuccess: function () {},
      onFailure: function (error: any) {},
    });
    win.hcap.property.setProperty({
      key: 'tv_caption_ui',
      value: param,
      onSuccess: function () {  },
      onFailure: function (error: any) {},
    });
    win.hcap.property.setProperty({
      key: 'tv_volume_ui',
      value: param,
      onSuccess: function () {},
      onFailure: function (error: any) {},
    });

    if (type == 'RF') {
      win.hcap.property.setProperty({
        key: 'tv_channel_ui',
        value: param,
        onSuccess: function () {
          console.info('onsuccess setProperty tv_channel_ui');
        },
        onFailure: function (f: any) {
          console.info('onFailure setProperty tv_channel_ui');
        },
      });
    }

    if (param == '1') {
      if (type == 'RF') {
        
        win.hcap.mode.setHcapMode({
          mode: win.hcap.mode.HCAP_MODE_2,
          onSuccess: function () {           
            console.info('onSuccess setHcapMode HCAP_MODE_2');
          },
          onFailure: function (f: any) {
            console.info(
              'onFailure setHcapMode: errorMessage = ' + f.errorMessage
            );
          },
        });
      }

      win.hcap.property.setProperty({
        key: 'application_channel_control',
        value: type == 'RF' ? '0' : '1',
        onSuccess: function () {},
        onFailure: function (error: any) {},
      });
      win.hcap.property.setProperty({
        key: 'mute_on_tv_input',
        value: '0',
        onSuccess: function () {},
        onFailure: function (error: any) {},
      });
    } else {
      if (type == 'IP') {
        win.hcap.channel.stopCurrentChannel({
          onSuccess: () => {},
          onFailure: function (error: any) {},
        });
      } else if (type == 'RADIO') {
        win.hcap.channel.stopCurrentChannel({
          onSuccess: () => {},
          onFailure: function (error: any) {},
        });
      } else {
        win.hcap.volume.getVolumeLevel({
          onSuccess: function (s: any) {
            console.info('onSuccess getVolumeLevel : level = ' + s.level);
            // self.data.currentTVVolume = s.level;
            win.hcap.volume.setVolumeLevel({
              // mute when exiting
              level: 10,
              onSuccess: function () {
                console.info('onSuccess setVolumeLevel mute');
              },
              onFailure: function (f: any) {
                console.info(
                  'onFailure setVolumeLevel: errorMessage = ' + f.errorMessage
                );
              },
            });
          },
          onFailure: function (f: any) {
            console.info(
              'onFailure getVolumeLevel: errorMessage = ' + f.errorMessage
            );
            // self.data.currentTVVolume = 10;
            win.hcap.volume.setVolumeLevel({
              // mute when exiting
              level: 10,
              onSuccess: function () {
                console.info('onSuccess setVolumeLevel mute');
              },
              onFailure: function (f: any) {
                console.info(
                  'onFailure setVolumeLevel: errorMessage = ' + f.errorMessage
                );
              },
            });
          },
        });
        win.hcap.mode.setHcapMode({
          mode: win.hcap.mode.HCAP_MODE_1,
          onSuccess: function () {
            
            console.info('onsuccess setHcapMode HCAP_MODE_1');
          },
          onFailure: function (f: any) {
            console.info('onFailure setHcapMode HCAP_MODE_1');
          },
        });
        win.hcap.channel.stopCurrentChannel({
          onSuccess: function () {
            console.info('onsuccess stopCurrentChannel');
          },
          onFailure: function (f: any) {
            console.info('onFailure stopCurrentChannel');
          },
        });
      }
      win.hcap.property.setProperty({
        key: 'mute_on_tv_input',
        value: '1',
        onSuccess: function () {
          /*  console.info(" mute onSuccess"); */
        },
        onFailure: function (f: any) {
          /* console.info("onFailure : errorMessage = " + f.errorMessage); */
        },
      });
    }
  }

  /**
   ** Register Keys function
   */
  registerLGKeys() {
    let win: any = window;
    win.hcap.key.addKeyItem({
      keycode: 0x00000000,
      virtualKeycode: win.hcap.key.Code.LIST,
      attribute: 0, // 0 processed by TV
      onSuccess: function () {
        console.info('onSuccess addKeyItem LIST');
      },
      onFailure: function (f: any) {
        console.info('onFailure : errorMessage = ' + f.errorMessage);
      },
    });
    win.hcap.key.addKeyItem({
      keycode: 0x00000000,
      virtualKeycode: win.hcap.key.Code.GUIDE,
      attribute: 0, // 0 processed by TV
      onSuccess: function () {
        console.info('onSuccess addKeyItem GUIDE');
      },
      onFailure: function (f: any) {
        console.info('onFailure : errorMessage = ' + f.errorMessage);
      },
    });
  }

  /**
   ** Unregister Keys functionality
   */
  unregisterLGKeys() {
    let win: any = window;
    win.hcap.key.clearKeyTable({
      onSuccess: function () {
        console.info('onSuccess clearKeyTable');
      },
      onFailure: function (f: any) {
        console.info('onFailure : errorMessage = ' + f.errorMessage);
      },
    });
  }

  /**
   ** Set Channel Info Banner
   * @param id
   */
  setChannelInfo(id: number) {
    console.info('--- setting channel info ---');
    let channels: Array<any> = [];
    let idx: number;
    this.store.select('tvStore').subscribe((tvState) => {
      channels = tvState.channels;
    });
    idx = findIndex(channels, (ch) => ch.id == id);
    document.getElementsByClassName('ch_name')[0].innerHTML =
      (idx + 1).toString() + '. ' + channels[idx].tvchannel_name;
    let ch_pic: any = document.getElementsByClassName('ch_pic')[0];
    ch_pic.style.backgroundImage =
      'url(' + "'" + channels[idx].image + "'" + ')';
    (document.querySelector('.progress') as HTMLDivElement).style.display =
      'none';
  }

  /**
   * * Play Media File
   * @param type
   * @param link
   */
  playPauseMedia(type:string, link: string) {
    sessionStorage.setItem('onPlaying', type);

    switch(type) {
      case 'video':
        this.playMedia(link, 'video', 'video/mpeg4');
        break;
      case "stream":
        this.playMedia(link, 'audio', 'audio/mpeg');
        break;
      case "audio":
        this.playMedia(link, 'audio', 'audio/mpeg');
        break;
      default:
    }
  }

  /**
   * * Play Media File
   * @param link
   */
   playMedia(link: string, type: string, format: string) {
    let win: any = window;
    win.hcap.Media.startUp({
      "onSuccess" : () => {
          console.info("!--- success media startup --!");
          win.media = win.hcap.Media.createMedia({
              "url" : link,
              "mimeType" : format
          });
          win.media.play({
            'onSuccess': () => {
              console.info('!--- success media play ---!');
              if (isEqual(type, 'video')) {
                document.querySelector('.app-container')?.setAttribute('style', 'opacity:0;');
              }

              win.hcap.property.setProperty({
                key: 'tv_volume_ui',
                value: '1'
              });
            },
            'onFailure': (f:any) => {
              console.info("onFailure : errorMessage = " + f.errorMessage);
            }
          })
      },
      "onFailure" : (f:any) => {
          console.info("onFailure : errorMessage = " + f.errorMessage);
      }
    });
  }

  playStream(link: string) {
    let win: any = window;
    let upLink = this.parseLink(link);
    let param = this.getChannelObj(upLink);

    console.info(JSON.stringify(param));

    if (this.firstrun) {
      win.hcap.externalinput.setCurrentExternalInput({
        "type" : win.hcap.externalinput.ExternalInputType.TV,
        "index" : 1,
        "onSuccess" : () => {
            win.hcap.channel.requestChangeCurrentChannel(param);
            this.LGTVFunctions("1", "IP");
            this.firstrun = false;
        },
        "onFailure" : function(error: any) {}
      });
    } else {
      win.hcap.channel.requestChangeCurrentChannel(param);
      this.LGTVFunctions("1", "IP");
    }
  }

  stopPlayers(type:string) {
    console.info("stopPlayers: " +type);
    let win: any = window;
    setTimeout(() =>  sessionStorage.removeItem('onPlaying'), 300);

    switch(type) {
      case 'video':
        win.media.stop({
          "onSuccess" : () => {
            console.info("!--- Media Stop ---!");
            document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
            win.media.destroy({
              "onSuccess" : () => {
                console.info("!--- Media Destroy ---!");
                win.hcap.Media.shutDown({
                  "onSuccess" : () => {
                    console.info("!--- Media ShutDown ---!");
                  },
                  "onFailure" : (f: any) => {
                    console.info("onFailure : errorMessage = " + f.errorMessage);
                  }
                });
              },
              "onFailure" : (f: any) => {
                console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
          },
          "onFailure" : (f: any) => {
              console.info("onFailure : errorMessage = " + f.errorMessage);
          }
        });
        break;
      case 'audio':
        win.media.stop({
          "onSuccess" : () => {
            console.info("!--- Media Stop ---!");
            win.media.destroy({
              "onSuccess" : () => {
                console.info("!--- Media Destroy ---!");
                win.hcap.Media.shutDown({
                  "onSuccess" : () => {
                    console.info("!--- Media ShutDown ---!");
                  },
                  "onFailure" : (f: any) => {
                    console.info("onFailure : errorMessage = " + f.errorMessage);
                  }
                });
              },
              "onFailure" : (f: any) => {
                console.info("onFailure : errorMessage = " + f.errorMessage);
              }
            })
          },
          "onFailure" : (f: any) => {
              console.info("onFailure : errorMessage = " + f.errorMessage);
          }
        });
        break;
      case 'radio':
        this.store.pipe(take(1)).subscribe(res => {
          console.info("state type: " + res.appStore.startupInputType + "type raw: " + this.getTvInputTypeFromName(res.appStore.startupInputType) + " index: " + res.appStore.startupInputIndex );
          win.hcap.externalinput.setCurrentExternalInput({
            "type" : this.getTvInputTypeFromName(res.appStore.startupInputType),
            "index" : res.appStore.startupInputIndex,
            "onSuccess" : ()=> {
              console.info('setCurrentExternalInput onSuccess');
              win.hcap.channel.stopCurrentChannel({
                onSuccess: function () {
                  console.info('onSuccess');
                },
                onFailure: function (f: any) {
                  console.info('onFailure : errorMessage = ' + f.errorMessage);
                },
              })
            },
            "onFailure" : function(f:any) {
              console.info("setCurrentExternalInput = " + f.errorMessage);
            }
          });
      })
      this.LGTVFunctions("0", 'IP');
        break;
      default:
    }
  }

  launchLGApplication(item: any) {
    let win: any = window;
    if(item.title == 'Input source'){
      win.hcap.key.sendKey({ // send input key to open input source
          "virtualKeycode" : win.hcap.key.Code.INPUT,
          "onSuccess" : () => {
              console.info("onSuccess virtualKeycode");
              this.router.navigateByUrl('category?slug=tv-and-entertainment&reload=1');
              sessionStorage.setItem('input_from_app', '1');
          },
          "onFailure" : (error: any) => {
              console.info("onFailure : errorMessage = " + error.errorMessage);
          }
      });
    }else{
      this.runApp(item.type == 'external-source' ? item.slug.lg : item.slug); // get alternative slug
    }
    return;
  }

  /**
 *  * Run An App
 *  @param app_id
 */
  runApp(app_id: string) {
    let win: any = window;
    win.hcap.preloadedApplication.launchPreloadedApplication({
      "id" : app_id,
      "onSuccess" : function() {
          console.info("onSuccess");
      },
      "onFailure" : function(error: any) {
          console.info("onFailure : errorMessage = " + error.errorMessage);
      }
    });
  }

  /**
 *  * Run Browser
 *  @param link
 */
   openBrowser(link: string) {
    let win: any = window;
    win.hcap.preloadedApplication.launchPreloadedApplication({
      "id" : '244115188075859022',
      "parameters" : `{'params':{'target': '${link}', 'noiframe': 'true'}}`,
      "onSuccess" : function() {
          console.info("onSuccess");
      },
      "onFailure" : function(error: any) {
          console.info("onFailure : errorMessage = " + error.errorMessage);
      }
    });
  }

  /**
 * *Chromecast Functionality
 */
  openChromecast() {
    let win:any = window;
    win.hcap.externalinput.setCurrentExternalInput({
      "type" : win.hcap.externalinput.ExternalInputType.HDMI,
      "index" : 0,
      "onSuccess" : () => {
        console.info("onSuccess");
        document.querySelector('.app-container')?.classList.add('d-none');
        this.router.navigateByUrl('/chromecast?category=tv-and-entertainment&slug=chromecast&reload=1')
      },
      "onFailure" : (f:any) => {
          console.info("onFailure : errorMessage = " + f.errorMessage);
          document.querySelector('.app-container')?.classList.add('d-none');
          this.router.navigateByUrl('/chromecast?category=tv-and-entertainment&slug=chromecast&reload=1');
      }
    })
  }

  /**
   * Setup up external input to Component if exists else to last available HDMI input
   */

  setupInitalInput() {
    let win:any = window;
    let inputs:Array<any> = [];
    console.info('------ GET TV INPUT ------');

    win.hcap.externalinput.getExternalInputList({
      "onSuccess": (s:any) => {
          let input:any;
          for (var i = 0; i < s.list.length; i++) {
            input = {name: null, index:null};
            input.name = this.getTvInputTypeName(s.list[i].type);
                    if (input.name !== null) {
                           input.index = s.list[i].index;
                           let foundInput = _.findIndex(inputs, { 'name': input.name } );
                           if (foundInput !== -1) {
                             inputs[foundInput] = {
                               name: input.name,
                               index: input.index
                             }
                           } else {
                             inputs.push(input);
                           }
                     }
            }
            let currentInputType:any = null;
            win.hcap.externalinput.getCurrentExternalInput({
                "onSuccess": (s:any) => {
                  currentInputType = this.getTvInputTypeName(s.type);
                  if (currentInputType !== "Component" && _.findIndex(inputs, { 'name': 'Component' }) !== -1) {
                    win.hcap.externalinput.setCurrentExternalInput({
                      "type": win.hcap.externalinput.ExternalInputType.COMPONENT,
                      "index": 0,
                      "onSuccess": () => {
                          console.info("onSuccess setting tv input to component");
                          if (!_.isNull(currentInputType)) {
                            this.store.dispatch(new AppInitialActions.SetTVInputs({
                              startupInputType:  currentInputType,
                              startupInputIndex:0
                             }))
                          }
                      },
                      "onFailure": (f:any) => {
                          console.info("onFailure : errorMessage = " + f.errorMessage);
                      }
                    });
                  } else if (currentInputType !== "HDMI" && currentInputType !== "Component" && _.findIndex(inputs, { 'name': 'HDMI' }) !== -1) {
                    let hdmi_input_index = _.findIndex(inputs, { 'name': 'HDMI' });
                    win.hcap.externalinput.setCurrentExternalInput({
                      "type": win.hcap.externalinput.ExternalInputType.HDMI,
                      "index": inputs[hdmi_input_index].index,
                      "onSuccess": () => {
                          console.info("onSuccess setting tv input to last hdmi input");
                          if (!_.isNull(currentInputType)) {
                            this.store.dispatch(new AppInitialActions.SetTVInputs({
                              startupInputType: win.hcap.externalinput.ExternalInputType.HDMI,
                              startupInputIndex:inputs[hdmi_input_index].index
                             }))
                          }
                      },
                      "onFailure": (f:any) => {
                          console.info("onFailure : errorMessage = " + f.errorMessage);
                      }
                    });
                  } else {
                    if (!_.isNull(currentInputType)) {
                      this.store.dispatch(new AppInitialActions.SetTVInputs({
                        startupInputType: currentInputType,
                        startupInputIndex: s.index
                       }))
                    }
                  }
                },
                "onFailure": function (f:any) {
                    console.info("onFailure : errorMessage = " + f.errorMessage);
                }
          });

              // if (_.findIndex(inputs, { 'name': 'Component' } ) !== -1) {

              // }

              console.info("(" + s.list[i].type + "," + s.list[i].index + "," + s.list[i].name + ")");
      },
      "onFailure": function (f:any) {
        console.info("onFailure : errorMessage = " + f.errorMessage);
      }
    });
  }


  /**
   * Converts input constant to string for the desired inputs or null for the undesired ones
   * @param type
   * @returns string|null
   */
  getTvInputTypeName (type:number) {
    let win:any = window;
    switch (type) {
      case win.hcap.externalinput.ExternalInputType.TV :
        return 'TV';
      case win.hcap.externalinput.ExternalInputType.HDMI :
       return 'HDMI';
      case win.hcap.externalinput.ExternalInputType.COMPOSITE :
       return 'Composite';
      case win.hcap.externalinput.ExternalInputType.COMPONENT :
       return 'Component';
       case win.hcap.externalinput.ExternalInputType.USB :
       return 'Component';
      default:
        return null;
    }
  }

  getTvInputTypeFromName (type:string) {
    let win:any = window;
    switch (type) {
      case 'TV' :
        return win.hcap.externalinput.ExternalInputType.TV;
      case 'HDMI' :
       return win.hcap.externalinput.ExternalInputType.HDMI;
      case 'Composite' :
       return  win.hcap.externalinput.ExternalInputType.COMPOSITE;
      case 'Component' :
       return  win.hcap.externalinput.ExternalInputType.COMPONENT;
      default:
        return null;
    }
  }

  wakeFromVirtualStandby() {
    let win:any = window;
    console.info("wakeFromVirtualStandby");
    try {
      win.hcap.power.getPowerMode({
        "onSuccess":  (mode:any) => {
          console.info(JSON.stringify(mode) + 'Powermode' + win.hcap.power.PowerMode.WARM);
          if (mode.mode === win.hcap.power.PowerMode.WARM) {
            win.hcap.power.setPowerMode({
              "mode": win.hcap.power.PowerMode.NORMAL,
              "onSuccess":  () => {
                  console.info("onSuccess setting monitor to on");
              },
              "onFailure": (error:any) => {
                  console.info("onFailure : errorMessage = " + error.errorMessage);
              }
          });
          }
        },
        "onFailure":  (error:any) => {
            console.info("onFailure : errorMessage = " + error.errorMessage);
        }
    });
    } catch(e:any) {
      console.info("error from try" + JSON.stringify(e));
    }

  }
}

<div aria-live="polite" aria-atomic="true" style="position: relative; min-height: 200px;">
  <div [id]="id" class="toast" role="alert" style="position: absolute; top: 0.5rem; right: 1rem; z-index: 100;"
    data-delay="3000" data-autohide="false">
    <div class="toast-header">
      <span class="rounded mr-2"
        [ngClass]="{'bg-danger': type == 'alert', 'bg-info': type == 'info', 'bg-success': type == 'success', 'bg-warning': type == 'warning'}"></span>
      <strong class="mr-auto">{{ title }}</strong>
      <!-- <small>11 mins ago</small> -->
      <button *ngIf="closeBtn" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body">
      {{ content | translate }}
  </div>
</div>

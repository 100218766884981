<div class="player">
   <div class="container" *ngIf="type=='audio' || type == 'stream'">
     <div class="row">
       <div class="col-6">
          <div class="player--overlay" [ngStyle]="{'background-image': 'url(' +item$.image + ') '}"></div>
          <div class="player__info-wrapper">
            <div class="player--artwork" [ngStyle]="{'background-image': 'url(' +item$.image + ') '}"></div>
          </div>
       </div>
       <div class="col-6 d-flex flex-column">
          <h2 class="player--channel-name">{{'You are listening to:'|translate}} <span>{{ !!item$.tvchannel_name ? item$.tvchannel_name : item$.title }}</span></h2>
          <div class="player--info">
            <table class="table w-50">
              <tbody>
                <tr *ngIf="!!item$.band">
                  <th scope="row" width="50">{{ 'Artist' | translate }}</th>
                  <td>{{ item$.band }}</td>
                </tr>
            </table>
          </div>
          <div class='eq_box mt-auto' *ngIf="play">
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
            <div class='bar'></div>
          </div>
       </div>
     </div>
     <div class="row player__controls" *ngIf="hasControls">
       <div class="col-4 icon">
        <sprite-icon class="prev" tabindex="-1" icon="prv" (keydown.enter)="changeMediaPage(-1)"></sprite-icon>
       </div>
       <div class="col-4 icon">
        <sprite-icon class="play_pause" (keydown.enter)="$event.preventDefault(); mediaPlayToggle(item$.link, !play)" tabindex="-1" [icon]="!play ? 'play':'pause'"></sprite-icon>
      </div>
      <div class="col-4 icon">
        <sprite-icon class="next" tabindex="-1" icon="next" (keydown.enter)="changeMediaPage(1)"></sprite-icon>
      </div>
     </div>
   </div>
</div>

export class Profile{
  address_name: string = '';
  age: number = 1;
  country_name: string = '';
  display_welcome_name!: number;
  email!: string;
  firstname: string = '';
  gender: number = 0;
  image: string = '';
  lastname: string = '';
  location: string = '';
  loyalty: number = 0;
  phone: string = '';
  room_no: string = '';
  tour_operator: string = '';
  userEmail: string = '';
  userFullname: string = '';
  user_id: number = -1;
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';

import * as SplashActions from '@app/pages/splash/store/splash.actions';
import { Corporate } from '@app/models/corporate';
import { CoreService } from '@app/services/core.service';
import * as AppInitialActions from '@app/store/app.actions';
import { isEqual } from 'lodash';
import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import { environment } from '@environments/environment';

@Injectable()
export class SplashEffects {
  /**
   * * Get Corporates
   *
   * @param channel_id
   * @returns Corporates[]
   */
  @Effect()
  getCorporates = this.actions$.pipe(
    ofType(SplashActions.GET_CORPORATES),
    switchMap(()=> {
      return this.http.post<Corporate[]>(`${this.core.hostUrl}tvbox/get-corporates`, '');
    }),
    map(corporates => {
      console.info('----------got corporates----------');
      return new SplashActions.SetCorporates(corporates);
    })
  )

  /**
   * * Check Room
   *
   * @param roomnumber
   * @param corporate
   * @returns string
   */
  @Effect()
  checkroom = this.actions$.pipe(
    ofType(SplashActions.CHECK_ROOM),
    switchMap((action:SplashActions.CheckRoom)=> {
      let body = `&roomnumber=${action.payload.roomnumber}&corporate=${action.payload.corporate}`;
      return this.http.post<any>(`${this.core.hostUrl}tvbox/checkroom`, body);
    }),
    map(res => {
      return new SplashActions.SetRoomStatus(res);
    })
  )

  /**
   * * Create TV Box
   *
   * @param roomnumber
   * @param token
   * @param corporate
   * @param device_type
   * @returns key
   */
  @Effect()
  createtvbox = this.actions$.pipe(
    ofType(SplashActions.CREATE_TV_BOX),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([appState,storeState])=> {
      let body = `&roomnumber=${(appState as any).payload.roomnumber}&token=${(appState as any).payload.token}&corporate=${(appState as any).payload.corporate}&device_type=${(appState as any).payload.device_type}&mac_address=${storeState.MAC}&ip_address=${storeState.IPv4}&version=${environment.version}`;
      return this.http.post<any>(`${this.core.hostUrl}tvbox/createtvbox`, body);
    }),
    map(res => {
      if(!isEqual(res, 'not_avail_model')){
        return new AppInitialActions.SetUniqueId(res.key);
      }
      return new AppInitialActions.SetUniqueId('');
    })
  )


  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private core: CoreService,
    private store$: Store<fromApp.AppState>,
  ) {}
}

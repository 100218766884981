import { Component, OnInit } from '@angular/core';
import { CoreService } from '@app/services/core.service';

import * as fromApp from '@app/store/app.reducer';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  data$!: Observable<any>;
  envelopeFocused:boolean = false;
  locale!:string;
  today: Date | undefined;

  constructor(private store: Store<fromApp.AppState>, public core: CoreService) { }

  ngOnInit(): void {
    this.data$ = this.store.select('appStore');
    this.store.select('appStore').subscribe(appState => {
      if (_.isNull(appState.room.tv_settings_language)) {
        this.locale = this.core.translate.getDefaultLang() == 'gr' ? 'el' :  this.core.translate.getDefaultLang();
      } else {
        this.locale = appState.room.tv_settings_language == 'gr' ? 'el' : appState.room.tv_settings_language;
      }
    });

    this.data$.pipe(
      filter(appState => _.isEqual(appState.roomChannelSettings.tv_hotspot, 1)),
      take(1)
    ).subscribe(() => {
      this.core.enabledHotspot()
    })

    //? Set interval to get real time event
    setInterval(() => {
        this.today = new Date();
    }, 1000);
  }
}

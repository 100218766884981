export class Room{
  channel_genres: string | null = null;
  checkedin_date: number | null = null;
  checkedout_date: number | null = null;
  corporate: number = 1;
  parental_code: string = '0000';
  persons: number = 1;
  pin: number | null= null;
  room_number: number = 0;
  tv_settings_language: string = 'en';
  tv_settings_readme: number = 0;
  user_id: number = -1;
  wakeup: string = '';
  knx: any = null;
  check_in_id: number | null = null;
}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
} from '@angular/core';
import { isUndefined } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent } from './modal.component';
import { ParentalControlComponent } from './parental-control/parental-control.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  parentalConfirmation = new BehaviorSubject(false);
  private ref!:ComponentRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

  createComponent = (component?: any, question?:string) => {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ModalComponent
    );
    const ngContent = this.resolveNgContent(component, question);
    const componentRef = componentFactory.create(this.injector, ngContent);
    this.ref = componentRef;
    return componentRef;
  };

  open(modalRef: any) {
    modalRef.show();
  }

  hide(modalRef?: any) {
    console.log(modalRef)
    if (isUndefined(modalRef)) {
      const { nativeElement } = this.ref.location;
      this.ref.instance.hide();
      nativeElement.remove();
    } else {
      modalRef.hide();
    }

    setTimeout(() => {
      window.document.querySelector('.modal-backdrop')?.remove();
    }, 200)
  }

  resolveNgContent(content: any, question?: string) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(content);
    const componentRef = factory.create(this.injector);
    (<ParentalControlComponent>componentRef.instance).question = question as string;
    componentRef.hostView.detectChanges();
    return [[componentRef.location.nativeElement]];
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AppInitialActions from '@app/store/app.actions';
import * as TvActions from '@app/pages/tv-and-entertainment/store/tv.actions';
import * as fromApp from '@app/store/app.reducer';
import { CoreService } from './core.service';
import { findIndex, includes, isNull } from 'lodash';

import { environment } from '@environments/environment';

import * as tizenKeys from '@assets/interfaces/keys/tizenKeys.json';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TizenService {
  private volumeElement: any;
  public tizenKeys = (tizenKeys as any).tizenKeys;
  public tizenUsefulKeys = (tizenKeys as any).tizenUsefulKeys;
  public tizenChannelMajorNum!: number;

  public Apps: any = {
    //'ScreenMirroring-app-tv': 'Screen Mirroring',
    'ScreenMirroringLFD-app-tv':'Screen Mirroring',
    Youtube: 'Youtube',
    //'ep-hotel-btplayer': 'Bluetooth Player',
    Internet: 'Internet',
    //Spotify: 'Spotify',
    // Plex: 'Plex',
    Epg: 'Programme Guide',     
    //ChannelList:'Channel List', // TODO
    //'Netflix':'Netflix', // TODO
    //'EP Hotel Alarm':'TV Alarm',// TODO
    //'ConnectionGuideApp':'ConnectionGuideApp',
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   *  *Initial Tizen Tv
   *  @param core service
   */
  init(core: CoreService): Promise<any> {
    let promise = new Promise( async (resolve, reject) => {
      let win: any = window;
      let IP = null, MAC = null, deviceOnline = false, Duid = null;

      //* Get Tv IP
      try { IP = win.webapis.network.getIp(); } catch (e:any) {
        console.info('getIp exception [' +e.code +'] name: ' +e.name + ' message: ' + e.message);
      }

      //* Get Tv MAC address
      try { MAC = win.webapis.network.getMac();} catch (e:any) {
        console.info( 'getMac exception [' + e.code +'] name: ' + e.name +' message: ' + e.message);
      }

      //* Get Tv if online/offline
      try {
        let conType = win.webapis.network.getActiveConnectionType();
        let conn = await core.onlineCheck();
        if (conType != 0 && conn) deviceOnline = true;
      } catch (e:any) {
        console.info( 'isConnectedToGateway exception [' + e.code + '] name: ' + e.name + ' message: ' + e.message);
      }

      //* Get Tv token
      try {
        Duid = win.webapis.productinfo.getDuid();
      } catch (error:any) {
        console.info(' error code = ' + error.code);
      }

      //* Save initial tv data to NgRx State
      this.store.dispatch(
        new AppInitialActions.SetPlatformValues({
          IPv4: IP != null ? IP : 'Not available',
          IPv6: 'Not available',
          MAC: MAC != null ? MAC : 'Not available',
          deviceToken: Duid,
          deviceOnline: deviceOnline,
        })
      );
      //* catch necessary tizen TV keys
      win.tizen.tvinputdevice.registerKeyBatch(Object.keys(this.tizenKeys));
      //* Initial additional classes
      this.getTVApps(core);
      this.setTvLanguage();
      this.setTVNTP();
      this.hasChromecast();

      console.info('------ Tizen TV Ready ------');
      resolve(true);
    });
    return promise;
  }

  /**
   *  * Get Tv Apps
   *  @param core service
   */
  getTVApps(core: CoreService) {
    let win: any = window;
    win.tizen.application.getAppsInfo(
      (TizenApps: Array<{ name: string; id: any; iconPath: string }>) => {
        let appsArray: Array<any> = [];
        for (let app of TizenApps) {
          if (app.id.includes('low') || app.name == '' || app.name == 'App' || app.id.includes('knox')) {
            //skip netflix lowmem
            continue;
          }
          //console.info(app.name +' - '+ app.id);
          // app.name == 'tv-viewer' && (this.Apps[app.name] = app.id); //save tv viewer to tizenApps array
          // app.name == 'Epg' && (this.Apps[app.name] = app.id);
          // app.name == 'Channel-list' && (this.Apps[app.name] = app.id);
          if (this.Apps.hasOwnProperty(app.name)) {
            let icon = app.iconPath;
            if (icon == '' || app.name == 'EP Hotel Alarm') {
              icon = 'assets/images/icons/'+this.Apps[app.name]+'.svg';
            }

            appsArray.push({
              title: this.Apps[app.name],
              imgPath: icon,
              slug: app.id,
              hover: false,
              enabled: 1,
              blockedWhenOffline: 1,
            });
          }
        }
        this.store.dispatch(new AppInitialActions.SetTvApplictions(appsArray));
      }
    );
  }

  /**
   *  * Launch An Tizne Application
   *  @param app_id
   *  @param checkIfRunning
   */
  launchTizenApplication(app_id: string, checkIfRunning: boolean) {
    if (checkIfRunning) {
      this.checkIfTizenAppRuns(app_id);
    } else {
      if (includes(app_id, 'browser')) {
        this.launchTizenBrowser(null);
      } else {
        this.runTizenApp(app_id);
      }
    }
  }

  /**
   *  * Check if an Application already running on background
   *  @param app_id
   */
  checkIfTizenAppRuns(app_id: string): any {
    let win: any = window;
    let conId: any = null;
    win.tizen.application.getAppsContext((contexts: Array<any>) => {
      for (let con of contexts) {
        if (con.appId == app_id) {
          conId = con.id;
          break;
        }
      }
      if (!!conId) {
        win.tizen.application.kill(
          conId,
          () => {
            this.runTizenApp(app_id);
          },
          (err: any) => {
            console.info(JSON.stringify(err));
          }
        );
      } else {
        this.runTizenApp(app_id);
      }
    });
  }
  /**
   *  * Run An App
   *  @param app_id
   */
  runTizenApp(app_id: string) {
    let win: any = window;
    try {
      win.tizen.application.launch(
        app_id,
        (e: any) => {
          console.info('app launched successfully ' + app_id);
        },
        (err: any) => {
          console.info(JSON.stringify(err));
        }
      );
    } catch (error) {
      console.info(error);
    }
  }

  /**
   *  * Run Browser with link
   *  @param link
   */
  launchTizenBrowser(link: any) {
    let win: any = window;
    let appControl = new win.tizen.ApplicationControl(
      'http://tizen.org/appcontrol/operation/search',
      link,
      'text/html',
      null
    );
    win.tizen.application.launchAppControl(
      appControl,
      'org.tizen.browser',
      (succ: any) => {},
      (err: any) => {
        console.info('Error could not launch browser');
      }
    );
  }

  setTvLanguage() {
    let win: any = window;
    try {
      win.b2bapis.b2bcontrol.setLanguage(environment.locale.code,
        () => {
          // TODO dynamic
          console.info(`setLanguage success ${environment.locale.code}`);
        },
        (err: any) => {
          console.info(`setLanguage error ${environment.locale.code} ` + err.code + ' ' + err.name + ' ' + err.message);
        }
      );
    } catch (err:any) {
      console.info('caught setLanguage error catch ' + err.code + ' ' + err.name + ' ' + err.message);
    }
  }

  setTVNTP() {
    let win: any = window;
    win.b2bapis.b2bcontrol.setNTPUse('USE', /// unlock NTP usage
      () => {
        console.info('setNTPUse success');
        var zone = null;
        try {
          zone = win.b2bapis.b2bcontrol.getCurrentTimeZone();
        } catch (e:any) {
          console.info('[getCurrentTimeZone] exception [' +e.code +'] name: ' + e.name +' message: ' + e.message );
        }
        if (null !== zone) {
          console.info('[getCurrentTimeZone] type: ' + zone);
          win.b2bapis.b2bcontrol.setNTPServerAddress(environment.locale.NTPServer,
            () => {
              console.info('setNTPServerAddress success!');
            },
            (err: any) => {
              console.info('setNTPServerAddress error ' + err);
            }
          );

          win.b2bapis.b2bcontrol.setCurrentTimeZone(environment.locale.timezone,
            () => console.info('setCurrentTimeZone success'),
            (err: any) =>
              console.info(`setCurrentTimeZone error: ${err.toString()}`)
          );
        }
      },
      (err: any) => console.info(`setNTPUse error: ${err.toString()}`)
    );
  }

  /**
 *  * Set Tv Hotspot
 *  @param core service
 */
  setTVHotSpot(core: CoreService) {
    let win: any = window;
    win.event.preventDefault();
    // * Set Device Name fro SSID
    let name: String = "";
    this.store.select('appStore').subscribe(appState => {
      name = `${appState.tvbox_info.name}`;
    })
    win.webapis.network.setTVName(name);
    let id = win.webapis.network.getSoftAPSSID();
    console.info('getSoftAPSSID: ' + id);

    // * Set Device Hotspot Password
    win.webapis.network.setSoftAPSecurityKeyAutoGeneration(0);
    let key = win.webapis.network.getSoftAPSecurityKey();
    console.info('getSoftAPSecurityKey: ' + key);

     // * Enable Hotspot
    let enbld = win.webapis.network.isSoftAPEnabled();
    console.info('isSoftAPEnabled ' + enbld);
    if (!enbld) {
      win.webapis.network.enableSoftAP();
      setTimeout(() => {
        enbld = win.webapis.network.isSoftAPEnabled();
        if (enbld) {
          this.store.dispatch(new AppInitialActions.SetHotspot({ssid: id, pass: key})); //? Pass Hotspot SSID/PASS
        }
      }, 500);
    } else
      setTimeout(() => {
        this.store.dispatch(new AppInitialActions.SetHotspot({ssid: id, pass: key})); //? Pass Hotspot SSID/PASS
      }, 500);
  }


  /**
   * * RF Watch Tv
   */
  watchRFTV = () => {
    let win: any = window;
    //document.addEventListener('keydown', this.tizenKeyHandler);
    try {
      win.tizen.tvinputdevice.unregisterKeyBatch(this.tizenUsefulKeys);
      win.tizen.tvwindow.getAvailableWindows(
        () => {
          win.tizen.tvwindow.show(
            (s: any) => {
              console.info('showsuccess ' + s);
              win.tizen.tvchannel.tune(
                {
                  major: this.tizenChannelMajorNum,
                },
                {
                  onsuccess: () => {
                    this.volumeElement.value = win.tizen.tvaudiocontrol.getVolume();
                    this.setChannelRfInfo();
                  },
                  onnosignal: () => {
                    console.info(
                      'Tune() is successfully done. But there is no signal.'
                    );
                  },
                },
                (err: any) => {
                  console.info('tune ' + err);
                }
              );
            },
            (e: any) => {
              console.info('showerror ' + e);
            },
            ['0px', '0px', '1920px', '1080px'],
            'MAIN',
            'BEHIND'
          );
        },
        (err: any) => {
          console.info('getavailablewindows ' + err);
        }
      );
    } catch (error:any) {
      console.info(
        'Error name = ' + error.name + ', Error message = ' + error.message
      );
    }
  };

  /**
   * IPTV Watch Tv
   */
  watchIPTV(channel: any, radio?: boolean) {
    let win: any = window,
      windowW = 1920,
      windowH = 1080;

    console.info('----- Unregistering Buttons --------');
    win.tizen.tvinputdevice.unregisterKeyBatch(this.tizenUsefulKeys);
    sessionStorage.setItem('onPlaying', 'ipTv');

    console.info('setDisplayRect..');
    win.b2bapis.b2brtpplay.setDisplayRect(
      0,
      0,
      windowW,
      windowH,
      (success: any) => {
        console.info('success result: ' + success);
      },
      (error: any) => {
        console.info('Could not setDisplayRect ' + error);
      }
    );

    win.webapis.avplay.setListener({
      onstreamcompleted: () => {
        win.webapis.avplay.stop();
      },
    });

    if (!radio) {
      this.setChannelInfo(channel.id);
      let channelInfo = document.getElementById('ch_info_container');
      channelInfo?.setAttribute('style', 'display: block');
      setTimeout(() => {
        channelInfo?.setAttribute('style', 'display: none');
      }, 8000);
    }

    console.info('opening link.. ' + channel.link);
    win.webapis.avplay.open(channel.link);
    console.info(
      `------ Start Playing Channel ${channel.tvchannel_name} ------`
    );
    localStorage.setItem('openChId', channel.id);
    //* Start Channel Play
    win.b2bapis.b2brtpplay.playChannel(
      channel.link,
      (success: any) => {
        console.info('success result: ' + success);
      },
      (error: any) => console.info('Could not play channel ' + error)
    );

    this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' })
  }

  setChannelInfo(id: number) {
    console.info('--- setting channel info ---');
    let channels: Array<any> = [];
    let idx: number;
    this.store.select('tvStore').pipe(take(1)).subscribe((tvState) => {
      channels = tvState.channels;
    });
    idx = findIndex(channels, (ch) => ch.id == id);
    document.getElementsByClassName('ch_name')[0].innerHTML =
      (idx + 1).toString() + '. ' + channels[idx].tvchannel_name;
    let ch_pic: any = document.getElementsByClassName('ch_pic')[0];
    ch_pic.style.backgroundImage =
      'url(' + "'" + channels[idx].image + "'" + ')';
    (document.querySelector('.progress') as HTMLDivElement).style.display =
      'none';
  }

  setChannelRfInfo() {
    let win = window as any;
    var channel = win.tizen.tvchannel.getCurrentChannel();
    var program = win.tizen.tvchannel.getCurrentProgram();
    console.info('--- setting channel info ---');
    document.getElementsByClassName('ch_name')[0].innerHTML = channel.channelName;
    document.getElementsByClassName('ch_pic')[0].innerHTML = channel.major;

    if (program != null) {
      document.getElementsByClassName('d_title')[0].innerHTML = program.title;
      document.getElementsByClassName('desc')[0].innerHTML =  program.detailedDescription;

      let start, end;
      start = { hours: 0, minutes: 0 };
      end = { hours: 0, minutes: 0 };
      start.hours = program.startTime.getHours() < 10 ? `0${program.startTime.getHours()}`: program.startTime.getHours();
      start.minutes = program.startTime.getMinutes() < 10 ? `0${program.startTime.getMinutes()}` : program.startTime.getMinutes();
      let endTime = program.startTime.addDuration(
        new win.tizen.TimeDuration(program.duration, "SECS")
      );
      end.hours = endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours();
      end.minutes = endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}`: endTime.getMinutes();

      document.getElementsByClassName('starts-ends')[0].innerHTML =
        start.hours +
        ":" +
        start.minutes +
        " - " +
        end.hours +
        ":" +
        end.minutes;
      let now = win.tizen.time.getCurrentDateTime();
      let dt = now.difference(program.startTime);
      dt = dt.length / 1000; //convert to ms to sec
      let percentage = (dt * 100) / program.duration;
      document.getElementsByClassName('progress-bar')[0].setAttribute('style', `width: ${percentage}%;`);
      document.getElementsByClassName('progress')[0].setAttribute('style', `opacity: 1;`);
    } else {
      document.getElementsByClassName('d_title')[0].innerHTML = document.getElementsByClassName('desc')[0].innerHTML = document.getElementsByClassName('starts-ends')[0].innerHTML = "";
      document.getElementsByClassName('progress')[0].setAttribute('style', `opacity: 0;`);
    }

    let channelInfo = document.getElementById('ch_info_container');
    channelInfo?.setAttribute('style', 'display: block');
    setTimeout(() => {
      channelInfo?.setAttribute('style', 'display: none');
    }, 8500);
  }

  /**
   **  Media Player Toggle
   */
  tizenPlayPauseMedia(type: string, link: string) {
    sessionStorage.setItem('onPlaying', type);

    switch(type) {
      case "video":
        this.playVideo(link);
        break;
      case "stream":
        this.playStream(link);
        break;
      case "audio":
        this.playStream(link);
        break;
      default:
        break;
    }
  }

   /**
   **  Video Player Play
   */
  playVideo(link: string) {
    let win: any = window;
    win.tizen.tvinputdevice.unregisterKeyBatch(this.tizenUsefulKeys);

    document.querySelector('.app-container')?.setAttribute('style', 'opacity:0;');

    try {
      let videoElement = document.createElement('video');

      videoElement.id = 'video';
      videoElement.width = 1920;
      videoElement.height = 1080;
      videoElement.src = link ;
      videoElement.style.background='black';
      videoElement.autoplay = true;
      videoElement.load();

      document.body.appendChild(videoElement);

    } catch (error) {
      console.info(error);
    }
  }

  /**
  **  Stream Player Play
  */
  playStream(link: string) {
    let win: any = window;
    win.tizen.tvinputdevice.unregisterKeyBatch(this.tizenUsefulKeys);
    // document.querySelector('.app-container')?.setAttribute('style', 'opacity:0;');

    try {
      win.webapis.avplay.stop();
        win.webapis.avplay.close();
        win.webapis.avplay.open(link);
        win.webapis.avplay.setTimeoutForBuffering(10);
        win.webapis.avplay.setBufferingParam(
          'PLAYER_BUFFER_FOR_PLAY',
          'PLAYER_BUFFER_SIZE_IN_SECOND',
          5
        );
        win.webapis.avplay.setBufferingParam(
          'PLAYER_BUFFER_FOR_RESUME',
          'PLAYER_BUFFER_SIZE_IN_SECOND',
          10
        );
        win.webapis.avplay.prepareAsync(
          () => {
            win.webapis.avplay.play();
          },
          (err: any) => {
            win.webapis.avplay.stop();
          }
        );
    } catch (error) {
      console.info(error);
    }
  }

   /**
  **  Stream Player Pause
  */
  pauseMedia(type: string) {
    let win: any = window;
    switch(type) {
      case 'stream':
        try {
          win.webapis.avplay.prepareAsync(
            () => {
              win.webapis.avplay.pause();
            },
            (err: any) => {
              win.webapis.avplay.stop();
            }
          );
        } catch (error) {
          console.info(error);
        }
        break;
      case 'audio':
        try {
          win.webapis.avplay.prepareAsync(
            () => {
              win.webapis.avplay.pause();
            },
            (err: any) => {
              win.webapis.avplay.stop();
            }
          );
        } catch (error) {
          console.info(error);
        }
        break;
      default:
    }
  }

  /**
   * *Stop Players
   */
  stopPlayers(type?: string) {
      console.info('-----------stop players----------');
      let win: any = window;
      document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
      sessionStorage.removeItem('onPlaying');

      switch(type) {
        case 'video':
          let videoElement = document.getElementById('video');
          (videoElement as any).pause();
          (videoElement as any).removeAttribute('src'); // empty source
          (videoElement as any).load();
          (videoElement as any).remove();     
          win.webapis.avplay.stop();
          win.webapis.avplay.close();     
          break;
        case 'stream':
          win.webapis.avplay.stop();
          win.webapis.avplay.close();
          break;
        case 'audio':
          win.webapis.avplay.stop();
          win.webapis.avplay.close();
          break;
        case 'ipTv':
          win.b2bapis.b2brtpplay.stopChannel(
            (success: any) => {
              console.info('[stopChannel] success result: ' + success);
            },
            (error: any) => {
              console.info(
                '[stopChannel] code :' +
                  error.code +
                  ' error name: ' +
                  error.name +
                  '  message ' +
                  error.message
              );
            }
          );

          win.tizen.tvwindow.hide((s:any) => {
            this.tizenChannelMajorNum = win.tizen.tvchannel.getCurrentChannel().major;
          });
          break;
        default:
      }

      win.tizen.tvinputdevice.registerKeyBatch(this.tizenUsefulKeys);
  }

  /**
   * *Reboot Tv
   */
  reboot() {
    let win: any = window;
    win.b2bapis.b2bcontrol.rebootDevice((succ: any) => {
      console.info('--------- rebooting ------');
    });
  };

 /**
   * * Has Chromecast Plug In
   */
  hasChromecast() {
    let win: any = window;
    try {
      win.tizen.systeminfo.getPropertyValue("VIDEOSOURCE",
        (videoSource: any) => {
          let connectedVideoSources = videoSource.connected;
          for (var i = 0; i < connectedVideoSources.length; i++) {
            console.info("--------------- Source " + i + " ---------------");
            console.info("type = " + connectedVideoSources[i].type);
            console.info("number = " + connectedVideoSources[i].number);
            if (connectedVideoSources[i].type === "HDMI" && connectedVideoSources[i].number === 1) {
              console.info('!--- Has Chromecast on HDMI1---!')
              this.store.dispatch(new AppInitialActions.SetChromecastEnable(true)); //? Check if has device on HDMI1 (Chromecast)
            }
          }
        },
        (error:any) => console.info("getPropertyValue(VIDEOSOURCE) is failed. Error name = "+ error.name + ", Error message = " + error.message)
      );
    } catch (error: any) {
      console.info("Error name = "+ error.name + ", Error message = " + error.message);
    }
  }

  /**
   * *Chromecast Functionality
   */
  openChromecast() {
    let win: any = window;
    try {
      win.tizen.systeminfo.getPropertyValue("VIDEOSOURCE",
        (videoSource: any) => {
          let connectedVideoSources = videoSource.connected;
          for (var i = 0; i < connectedVideoSources.length; i++) {
            console.info("--------------- Source " + i + " ---------------");
            console.info("type = " + connectedVideoSources[i].type);
            console.info("number = " + connectedVideoSources[i].number);
            if (connectedVideoSources[i].type === "HDMI" && connectedVideoSources[i].number === 1) {
                // set HDMI as input source of the TV window
                win.tizen.tvwindow.setSource(connectedVideoSources[i],
                  (source: any, type: any) => {
                    console.info("setSource() is successfully done. source name = " + source.name + ", source port number = " + source.number);
                    win.tizen.tvwindow.show((windowRect:any, type:any) => {
                      this.tizenUsefulKeys.push("MediaPlay", "MediaPause");
                      win.tizen.tvinputdevice.unregisterKeyBatch(this.tizenUsefulKeys);
                      sessionStorage.setItem('onPlaying', 'chromecast');
                      console.info("Rectangle : [" + windowRect[0] + ", " + windowRect[1] + ", " + windowRect[2] + ", " + windowRect[3] + "]");
                      this.router.navigate([], {relativeTo: this.route, queryParams: { isOpen: 1 }, queryParamsHandling:'merge' })
                    }, null, ["0%", "0%", "100%", "100%"], "MAIN");
                  },
                  (error: any) =>  console.info("setSource() is failed. Error name = "+ error.name + ", Error message = " + error.message)
                );
                break;
            }
          }
        },
        (error:any) => console.info("getPropertyValue(VIDEOSOURCE) is failed. Error name = "+ error.name + ", Error message = " + error.message)
      );
    } catch (error:any) {
        console.info("Error name = "+ error.name + ", Error message = " + error.message);
    }
  }
}

import * as TvActions from './tv.actions';

export interface State {
 channels: Array<any>,
 mediaContent: Array<any>,
 payment: any,
 chromecast: any,
}

const initialState: State = {
  channels: [],
  mediaContent: [],
  payment: {},
  chromecast: {} || null
}

export function tvReducer(
  state: State = initialState,
  action: TvActions.tvPageActions
): State {
  switch (action.type) {
    case TvActions.SET_CHANNELS_DATA:
      return {
        ...state,
        channels: action.payload
      }
    case TvActions.SET_RADIO_DATA:
      return {
        ...state,
        mediaContent: action.payload
      }
    case TvActions.SET_MUSIC_DATA:
      return {
        ...state,
        mediaContent: action.payload
      }
    case TvActions.SET_MOVIES_DATA:
      return {
        ...state,
        mediaContent: action.payload
      }
    case TvActions.SET_PAYMENT_TV:
      return {
        ...state,
        payment: action.payload
      }
    case TvActions.SET_PAYTV_CHANNELS:
      return {
        ...state,
        channels: action.payload
      }
    case TvActions.SET_CHROMECAST_CONTENT:
      return {
        ...state,
        chromecast: action.payload
      }
    case TvActions.SET_HOTEL_CHANNELS:
      return {
        ...state,
        channels: action.payload
      }
    default:
      return state;
  }
}

<div class="alarm" [ngStyle]="{'background-image': 'url(' +imageSrc+ ') '}">
  <div class="card" style="max-width: 30rem;" *ngIf="text != ''">
    <div class="card-header">
      <h3>{{ 'Alarm' | translate }}</h3>
    </div>
    <div class="card-body">
      <h2>{{ text }}</h2>
    </div>
  </div>
</div>
<div #audio class="html-audio"></div>

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ControlService } from '@app/services/control.service';
import { CoreService } from '@app/services/core.service';
import { TizenService } from '@app/services/tizen.service';
import { Store } from '@ngrx/store';
import { forEach, isEmpty, isEqual, isUndefined } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import * as fromApp from '@app/store/app.reducer';
import * as AppStoreActions from '@app/store/app.actions';
import { filter, take } from 'rxjs/operators';
import { LGService } from '@app/services/lg.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('footerAnimation', [
      state('hidden', style({ transform: 'translateY(150%)', opacity: '0' })),
      state('shown', style({ transform: 'translateY(0)', opacity: '1' })),
      state(
        'shownInside',
        style({ transform: 'translateY(-50%)', opacity: '1' })
      ),
      transition('hidden <=> shown', animate('0.5s  ease-out')),
      transition('hidden <=> shownInside', animate('0.5s  ease-out')),
    ]),
    trigger('buttonsAnimation', [
      state(
        'hidden',
        style({ transform: 'translateY(300%)', opacity: '0', height: '0' })
      ),
      state('shown', style({ transform: 'translateY(0)' })),
      transition('hidden <=> shown', animate('0.5s  ease-out')),
    ]),
  ],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  public defaultMenu: any[] = [];
  public footerMenu: any = null;
  public showFooter: { home: boolean; inside: boolean } = {
    home: true,
    inside: false,
  };
  public showControls: boolean = true;
  private routeSubscription: Subscription = new Subscription();
  public state$!: Observable<any>;

  // @ViewChild('menu') menuEl!: ElementRef;

  constructor(
    public core: CoreService,
    private router: Router,
    private _location: Location,
    private cdRef: ChangeDetectorRef,
    private control: ControlService,
    private tizen: TizenService,
    private lg: LGService,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.defaultMenu = this.core.navigationItems;
    this.routeSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url === '/home') {
          // on Home Page show footer
          this.showFooter.home = true;
          this.showFooter.inside = false;
        }
      }
    });
    this.state$ = this.store.select('appStore');
    this.state$.pipe(
      filter(appState => !isEmpty(appState.navigationMenu))
    ).subscribe(appState => {
      this.footerMenu = appState.navigationMenu.filter((item:any)=>(item.isActive));
      //console.log(this.footerMenu);
      //this.footerMenu = appState.navigationMenu.filter((item:any)=>{return ((item.enabled===1) && item.items.some((subItem:any)=>subItem.enabled===1))});
      
    })
  }

  ngAfterViewInit() {
    this.core.SN.add({
      selector: '#footer .focusable',
      id: 'footer',
      rememberSource: false,
      restrict: 'none',
    });

    this.core.SN.add({
      selector: '.btn-row .btn',
      id: 'footer-btns',
      rememberSource: false,
      restrict: 'none',
    });

    this.core.SN.disable('footer-btns');

    setTimeout(() => {
      this.core.SN.focus('footer');
    }, 4000);
    this.cdRef.detectChanges(); //TODO Temp fix
  }

  onFocus(event: any, index: number) {
    if (!isEmpty(this.footerMenu[index].focusColor))
      event.target.style.backgroundColor = this.footerMenu[index].focusColor;
  }

  onBlur(event: any, index: number) {
    if (!isEmpty(this.footerMenu[index].focusColor))
      event.target.style.backgroundColor = this.footerMenu[index].backgroundColor;
  }
  onCategoryClicked(item: any) {
    if (item.standalone == 'page') {
      this.router.navigate([item.slug], { queryParams: { slug: item.slug } });
    } else {
      let itemArray: Array<any> = [];
      this.store.select('appStore').pipe(take(1)).subscribe(appState => {
        forEach(appState.navigationMenu, (obj, key) => {
          if (item.slug == obj.slug) {
            forEach(obj.items, (elem) => {
             if(elem.enabled == 1) {
              itemArray.push(elem); //* Get category enabled items
             }
            })
          }
        });
      })
      //* check if category enabled items are more than one
      if (itemArray.length == 1) {
        //* if there is one direct navigate inside
        this.router.navigate([itemArray[0].slug], {
          queryParams: { category: item.slug, slug: itemArray[0].slug }
        });
      } else {
        //* else navigate by default
        this.router.navigate(['category'], { queryParams: { slug: item.slug } });
      }
    }
    this.core.SN.disable('footer');
    this.showFooter.home = false;
    if (this.showFooter.inside) {
      this.toggleFooter();
    }

    setTimeout(() => {
      this.core.SN.focus('category-items');
    }, 1000)
  }

  animDone(e: any) {
    console.log(e.toState)
    if (e.toState == 'shown') {
      this.core.SN.enable('footer');
      this.core.SN.disable('footer-btns');
      this.core.SN.focus('.focusable');
    } else if (e.toState == 'shownInside') {
      this.core.SN.set('footer', {
        leaveFor: { left: '', right: '', up: '', down: 'footer-btns' },
      });
      this.core.SN.set('footer-btns', {
        leaveFor: { left: '', right: '', up: 'footer', down: '' },
      });
      this.core.SN.enable('footer');
      this.core.SN.enable('footer-btns');
    } else if (e.toState == 'hidden') {
      this.core.SN.enable('footer-btns');
    }
  }

  /**
   * * Menu overlay
   */
  toggleFooter() {
    this.showFooter.inside = !this.showFooter.inside;
    if (this.showFooter.inside) {
      // show footer with overlay
      document.querySelector('.app-container')?.classList.add('overlay');
    } else {
      document.querySelector('.app-container')?.classList.remove('overlay');
    }
  }

  /**
   * * Navigate to Menu
   */
  navigateToHome() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      if (isEqual(this.core.platform, 'SamsungTizen')) {
        if (!!sessionStorage.getItem('onPlaying')) {
          this.tizen.stopPlayers(sessionStorage.getItem('onPlaying')?.toString());
        }
      } else if (isEqual(this.core.platform, 'LG')) {
        if (!!sessionStorage.getItem('onPlaying')) {
          this.lg.stopPlayers(sessionStorage.getItem('onPlaying') as string);
        }
      }
      this.router.navigate(['home']);
    })
  }

  navigateBack(evn: KeyboardEvent) {
    if (this.control.OKPressed(evn)) {
      console.info('back')
      if (isEqual(this.core.platform, 'SamsungTizen')) {
        if (!!sessionStorage.getItem('onPlaying')) {
          this.tizen.stopPlayers(sessionStorage.getItem('onPlaying')?.toString());
        }
      } else if (isEqual(this.core.platform, 'LG')) {
        if (!!sessionStorage.getItem('onPlaying')) {
          this.lg.stopPlayers(sessionStorage.getItem('onPlaying') as string);
        }
      }
      this.store.select('appStore').pipe(take(1)).subscribe(appState => {
        if(appState.iframeBack){          
          setTimeout(()=>this.store.dispatch(new AppStoreActions.ResetIframeStep()),100)
          window.history.go(-appState.iframeStep-1);
          
        }else{
          this.store.dispatch(new AppStoreActions.AddIframeStep(-2))
          this._location.back();
        }
        if (this.showFooter.inside) {
          this.toggleFooter();
        }
      })
      
      
    }
    
  }

  navigateBackClick(event: MouseEvent){
    //Only click-touch event 
    if (event.detail !== 1) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    
    if(event){
      console.log("click")
      this._location.back();
      if (this.showFooter.inside) {
        this.toggleFooter();
      }
    }  
  }

  ngOnDestroy() {
    this.core.SN.remove('footer');
    this.core.SN.remove('footer-btns');
    this.routeSubscription.unsubscribe();
  }
}

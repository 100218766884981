import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SpriteIconComponent } from './components/sprite-icon/sprite-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselComponent, CarouselItemElement } from './components/carousel/carousel.component';
import { CarouselItemDirective } from './components/carousel/carousel-item.directive';
import { LanguageFilterPipe } from '@app/middleware/pipes/language-filter.pipe';
import { ToFloatFilterPipe } from '@app/middleware/pipes/toFloat-filter.pipe';
import { SafeFilterPipe } from '@app/middleware/pipes/safeUrl-filter.pipe';
import { ModalComponent } from '@app/components/modal/modal.component';
import { ParentalControlComponent } from './components/modal/parental-control/parental-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlayerComponent } from './components/player/player.component';
import { AlertInfoComponent } from './components/alert-info/alert-info.component';
import { EvaluationComponent } from './components/survey-questions/evaluation/evaluation.component';
import { TextareaComponent } from './components/survey-questions/textarea/textarea.component';
import { CheckboxComponent } from './components/survey-questions/checkbox/checkbox.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { ToastComponent } from './components/toast/toast.component';
import { GenderFilterPipe } from './middleware/pipes/gender-filter.pipe';
import { AgeRangeFilterPipe } from './middleware/pipes/age_range-filter.pipe';
import { VideoComponent } from './components/video/video.component';
import { SafeHtmlPipe } from './middleware/pipes/safeHtml-filter.pipe';
import { AlarmComponent } from './components/alarm/alarm.component';
import { TimeZoneFilterPipe } from './middleware/pipes/timeZone-filter.pipe';

import myLocaleAr from '@angular/common/locales/ar'
import myLocaleDe from '@angular/common/locales/de'
import myLocaleEs from '@angular/common/locales/es'
import myLocaleFr from '@angular/common/locales/fr'
import myLocaleEl from '@angular/common/locales/el'
import myLocaleIt from '@angular/common/locales/it'
import myLocaleNl from '@angular/common/locales/nl'
import myLocaleRu from '@angular/common/locales/ru'
import myLocaleTr from '@angular/common/locales/tr';
import { SlideComponent } from './components/slide/slide.component'
import { OCGSplideSlideComponent } from './components/slide/slide-item.components';
import { RemoveTonationPipe } from './middleware/pipes/removeTonation.pipe';
import { DigitInputComponent } from './components/digit-input/digit-input.component';
import { StripHtmlPipe } from './middleware/pipes/stripHtml.pipe';

registerLocaleData(myLocaleEs);
registerLocaleData(myLocaleFr);
registerLocaleData(myLocaleEl);
registerLocaleData(myLocaleAr);
registerLocaleData(myLocaleDe);
registerLocaleData(myLocaleIt);
registerLocaleData(myLocaleNl);
registerLocaleData(myLocaleRu);
registerLocaleData(myLocaleTr);

@NgModule({
  declarations: [
    SpriteIconComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    LanguageFilterPipe,
    ToFloatFilterPipe,
    SafeFilterPipe,
    ModalComponent,
    ParentalControlComponent,
    PlayerComponent,
    AlertInfoComponent,
    EvaluationComponent,
    TextareaComponent,
    CheckboxComponent,
    IframeComponent,
    ToastComponent,
    GenderFilterPipe,
    AgeRangeFilterPipe,
    TimeZoneFilterPipe,
    VideoComponent,
    SafeHtmlPipe,
    AlarmComponent,
    SlideComponent,
    OCGSplideSlideComponent,
    RemoveTonationPipe,
    DigitInputComponent,
    StripHtmlPipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    SpriteIconComponent,
    TranslateModule,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    LanguageFilterPipe,
    ToFloatFilterPipe,
    SafeFilterPipe,
    ModalComponent,
    FormsModule,
    ReactiveFormsModule,
    AlertInfoComponent,
    EvaluationComponent,
    TextareaComponent,
    CheckboxComponent,
    IframeComponent,
    GenderFilterPipe,
    AgeRangeFilterPipe,
    VideoComponent,
    SafeHtmlPipe,
    AlarmComponent,
    TimeZoneFilterPipe,
    SlideComponent,
    OCGSplideSlideComponent,
    RemoveTonationPipe,
    DigitInputComponent, 
    StripHtmlPipe
  ]
})
export class SharedModule { }

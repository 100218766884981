import { Component, Input } from '@angular/core';

@Component({
  selector: 'sprite-icon',
  template: `
    <svg viewBox="0 0 80 80">
      <use attr.xlink:href="assets/sprites/sprite.svg?v14#{{icon}}"></use>
    </svg>
  `,
  styleUrls: ['./sprite-icon.component.scss']
})
export class SpriteIconComponent {
  @Input() icon: string = '';
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { switchMap, map, withLatestFrom, mergeMap, tap, take } from 'rxjs/operators';
import * as SettingsActions from "@app/pages/settings/store/settings.actions";

import * as fromApp from './app.reducer';
import * as AppInitialActions from './app.actions';
import * as HomePageActions from '@app/pages/home/store/home.actions';
import * as TvPageActions from '@app/pages/tv-and-entertainment/store/tv.actions';
import { CoreService } from '@app/services/core.service';
import { StorageService } from '@app/services/storage.service';
import { Room } from '@app/models/room';
import { Profile } from '@app/models/profile';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { RoomChannelSettings } from '@app/models/roomchannelsettings';
import { cloneDeep, forEach, isEqual, isNull, filter, isEmpty } from 'lodash';
import { of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class AppEffects {
  /**
   * * Checks If TV exists in Backend
   *
   * @param deviceToken
   * @returns unique_id
   */
  @Effect()
  checkiftvexists = this.actions$.pipe(
    ofType(AppInitialActions.CHECK_IF_TV_EXISTS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&token=${storeState.deviceToken}&mac_address=${storeState.MAC}`; //`&token=${action.payload.unique_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}tvbox/checkiftvexists`,
        body
      );
    }),
    map((res) => {
      if (res.exists) {
        return new AppInitialActions.SetUniqueId(res.unique_id);
      }
      return new AppInitialActions.SetUniqueId('');
    })
  );

  /**
   * * Get TVBOX Info
   *
   * @param unique_id
   * @returns corporate, tvbox_id, channel_id, room_id
   * @actions SetTvBoxInfo, GetUserInfo, GetRoomChannelSettings, GetTvSettings, GetLastLogo
   */
  @Effect()
  gettvboxinfo = this.actions$.pipe(
    ofType(AppInitialActions.GET_TVBOX_INFO),
    map((action: AppInitialActions.GetTvBoxInfo) => action.payload),
    switchMap((payload) => {
      let body = `&unique_id=${payload}`;
      return this.http.post<any>(
        `${this.core.hostUrl}tvbox/get-tvbox-info`,
        body
      );
    }),
    mergeMap((res) => {
      if (res == 'error') {
        console.info('Server returned Error. get tv box info');
        throw 'Server returned Error. get tv box info';
      } else {
        console.info('----------got tvboxinfo----------');
        return [
          new AppInitialActions.SetTvBoxInfo(res[0]), //* SetTvBoxInfo
          new AppInitialActions.GetRoomChannelSettings(), //* Get GetRoomChannelSettings
          new AppInitialActions.GetTvSettings(), //* Get GetTvSettings
          new AppInitialActions.GetLastLogo(), //* Get Brand Logo
          new HomePageActions.GetChannelBackground() //* Get Home Page Background Images,
        ];
      }
    }),
  );

  /**
   * * Get USER Info
   *
   * @param room_id
   * @returns profile, room, welcomemsg
   * @actions SetUserInfo, GetRoomPush
   */
  @Effect()
  getuserinfo = this.actions$.pipe(
    ofType(AppInitialActions.GET_USER_INFO),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      return this.http.post<any>(
        `${this.core.hostUrl}user/admin/get-user-info&id=${storeState.tvbox_info.room_id}&corp_id=${storeState.tvbox_info.corporate}`,
        ''
      );
    }),
    mergeMap((res: any) => {
      let userInfo: any;
      if (res[0].profile.hasOwnProperty('0')) {
        // server fail error
        console.info('Server returned Error. get user info');
        let room = new Room(), profile = new Profile(); // default values except those that came in
        room = {
          ...room,
          parental_code: res[0].room.parental_code,
          tv_settings_language: res[0].room.tv_settings_language || 'en',
        };
        profile = {
          ...profile,
          email: res[0].profile.email,
          display_welcome_name: res[0].profile.display_welcome_name,
        };
        userInfo = {
          room: room,
          profile: profile,
          welcomemsg: res[0].welcomemsg,
        };
      } else {
        console.info('----------got userinfo----------');
        userInfo = res[0];
      }
      return [

        new AppInitialActions.SetUserInfo(userInfo),
        new AppInitialActions.GetLanguage(),//* GetLanguage
        new AppInitialActions.GetRoomPush(), //* GetRoomPush

        new AppInitialActions.GetNavigationMenu(),//* Get Footer Navigation Elements
        new AppInitialActions.IsOnline(), //* Update if Device is Online
        new AppInitialActions.DoUpdateIp(), //* Update IP

      ];
    })
  );

  /**
   * * Get Language
   */
  @Effect({ dispatch: false })
  getlanguage = this.actions$.pipe(
    ofType(AppInitialActions.GET_LANGUAGE),
    withLatestFrom(this.store$.select('appStore')),
    take(1),
    tap(([actionState, storeState]) => {
      console.info('----------got language----------' + storeState.room.tv_settings_language);
      if (!!storeState.room.tv_settings_language)
        this.core.setTranslation(storeState.room.tv_settings_language);
      else {
        this.store$.dispatch(new SettingsActions.GetLanguages('languages/languages/getpluslanguages'));
      }
      this.store$.dispatch(new SettingsActions.GetAbout());
    })
  );





  /**
   * * Get Room Push
   *
   * @param user_id
   * @param channel_id
   * @returns roomPushMessages
   * @actions SetRoomPush
   */
  @Effect()
  getroompush = this.actions$.pipe(
    ofType(AppInitialActions.GET_ROOM_PUSH),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&user_id=${storeState.profile.user_id}&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}room_push/room-push/get-all-messages`,
        body
      );
    }),
    mergeMap((res) => {
      if (res.error) {
        console.info('Server returned Error. get tv box info');
        throw 'Server returned Error. ' + res.alert;
      } else {
        console.info('----------got room push----------');
        let unreadedmessage = 0;
        let hasImportance = false
        forEach(res, (msg) => {
          msg.read == 0 && unreadedmessage++;
          if (msg.importance == 1 && msg.read == 0) hasImportance = true;
        })
        return [
          new AppInitialActions.IncreamentUnreadedMessages(unreadedmessage),
          new AppInitialActions.UnreadedMessagesHasImportance(hasImportance),
          new AppInitialActions.SetRoomPush(res),
          new AppInitialActions.GetConciergeNotifications(),
          new AppInitialActions.GetHouseKeepingSettings()
        ]
      }
    })
  );

  /**
   * * Get Concierge Notifications
   *
   * @param user_id
   */
  @Effect()
  getconciergenotifications = this.actions$.pipe(
    ofType(AppInitialActions.GET_CONCIERGE_NOTIFICATIONS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&user_id=${storeState.profile.user_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}concierge/concierge/concierge-messages`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get tv box info');
        throw 'Server returned Error. ' + res.alert;
      } else {
        console.info('----------got concierge notifications----------');
        let count: number = 0;

        if(res != 'no data'){
          res.forEach((mes:any) => {
            if(mes.towards == 'customer' && mes.has_unread == true){

              count++;
            }
          });
        }
        return new AppInitialActions.SetConciergeNotifications(count);
      }
    })
  );


  /**
   * * Get housekeeping settings
   *
   * @param user_id
   */
  @Effect()
  gethousekeepingsettings = this.actions$.pipe(
    ofType(AppInitialActions.GET_HOUSEKEEPING_SETTINGS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&checkedInUserId=${storeState.room.check_in_id}`;
      return this.http.get<any>(
        `${this.core.hostUrl}in-house/housekeeping/status${body}`);
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get housekeeping settings ');
        throw 'Server returned Error. ' + res.alert;
      } else {
        console.info('----------got housekeeping settings----------');
        console.log(res)
        return new AppInitialActions.SetHouseKeepingSettings(res);
      }
    })
  );

  /**
  * * Send dnd status
  * @param check_in_id
  * @param flag
  */
  @Effect()
  setdnd = this.actions$.pipe(
    ofType(AppInitialActions.SET_DO_NOT_DISTURB),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&checkedInUserId=${storeState.room.check_in_id}&flag=${(actionState as any).payload}`;
      return this.http.post<any>(
        `${this.core.hostUrl}in-house/housekeeping/do-not-disturb`, body);
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. send dnd status');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('---------send dnd status----------');
        return new AppInitialActions.GetHouseKeepingSettings();
      }
    })
  );

   /**
  * * Send cleanup status
  * @param check_in_id
  * @param flag
  */
   @Effect()
   setcleanup = this.actions$.pipe(
     ofType(AppInitialActions.SET_CLEAN_MY_ROOM),
     withLatestFrom(this.store$.select('appStore')),
     switchMap(([actionState, storeState]) => {
       let body = `&checkedInUserId=${storeState.room.check_in_id}&flag=${(actionState as any).payload}`;
       return this.http.post<any>(
         `${this.core.hostUrl}in-house/housekeeping/clean-my-room`, body);
     }),
     map((res) => {
       if (res.error) {
         console.info('Server returned Error. send clean-up-room status');
         throw 'Server returned Error. ' + res.alert ? res.alert : '';
       } else {
         console.info('---------send clean-up-room status----------');
         return new AppInitialActions.GetHouseKeepingSettings();
       }
     })
   );
  


  /**
   * * Get Room Channel Settings
   *
   * @param channel_id
   * @returns rights, terms, tv_hotspot, tv_via
   * @action SetRoomChannelSettings
   */
  @Effect()
  getroomchannelsettings = this.actions$.pipe(
    ofType(AppInitialActions.GET_ROOM_CHANNEL_SETTINGS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}channel/get-room-channel-tv-settings`,
        body
      );
    }),
    mergeMap((res) => {
      if (res.error) {
        console.info('Server returned Error. get room channel settings');
        throw 'Server returned Error. ' + res.alert;
      } else {
        console.info('----------got room channel settings----------');
        return [
          new AppInitialActions.SetRoomChannelSettings(res), //* GetRoomChannelSettings
          new AppInitialActions.GetUserInfo(), //* GetUserInfo
        ]
      }
    })
  );

  /**
   * * Get TV Settings
   *
   * @param channel_id
   * @returns model
   * @actions SetTvSettings
   */
  @Effect()
  gettvsettings = this.actions$.pipe(
    ofType(AppInitialActions.GET_TV_SETTINGS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&corporate_id=${storeState.tvbox_info.corporate}`;
      return this.http.post<any>(
        `${this.core.hostUrl}tvsettings/tv-settings/gettvsettings`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get tv settings');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got tv settings----------');
        return new AppInitialActions.SetTvSettings(res[0]);
      }
    })
  );

  /**
   * * Get Last Logo
   *
   * @param channel_id
   * @returns link
   * @actions SetLastLogo
   */
  @Effect()
  getlastlogo = this.actions$.pipe(
    ofType(AppInitialActions.GET_LAST_LOGO),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}channel-content/getlastlogo`,
        body
      );
    }),
    map((res) => {
      if (res[0].error) {
        console.info('Server returned Error. get channel background');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got last logo----------');
        return new AppInitialActions.SetLastLogo(res[0].link);
      }
    })
  );

  /**
  * * Do Reboot
  */
  @Effect({ dispatch: false })
  doReboot = this.actions$.pipe(
    ofType(AppInitialActions.DO_REBOOT),
    withLatestFrom(this.store$.select('appStore')),
    take(1),
    switchMap(([actionState, storeState]) => {
      let body = `&status=1&unique_id=${storeState.unique_id}&mac_address=${storeState.MAC}&version=${environment.version}&ip_address=${storeState.IPv4}`;
      return this.http.post<any>(
        `${this.core.hostUrl}tvbox/updatestatus-plus`,
        body
      );
    }),
    map(res => {
      this.core.rebootState(res[0]);
    })
  );

  /**
   * * UpDate Status
   */
  @Effect({ dispatch: false })
  updateStatus = this.actions$.pipe(
    ofType(AppInitialActions.DO_UPDATE_STATUS),
    withLatestFrom(this.store$.select('appStore')),
    take(1),
    switchMap(([actionState, storeState]) => {
      let body = `&status=1&unique_id=${storeState.unique_id}&version=${environment.version}&ip_address=${storeState.IPv4}&isPlus=1`;
      return this.http.post<any>(
        `${this.core.hostUrl}tvbox/updatestatus-plus`,
        body
      );
    }),
    map(res => {
      if (res[0].error) {
        console.info('Server returned Error. update status');
        throw 'Server returned Error. ' + res[0].alert ? res[0].alert : '';
      } else {
        console.info('----------update status settings----------');
      }
    })
  );

  /**
 * * Is Online Device
 */
  @Effect({ dispatch: false })
  isonline = this.actions$.pipe(
    ofType(AppInitialActions.ISONLINE),
    withLatestFrom(this.store$.select('appStore')),
    take(1),
    switchMap(([actionState, storeState]) => {
      let body = `&mac=${storeState.unique_id}`;
      return this.http.get<any>(
        `${this.core.hostUrl}tvbox/isonline${body}`,
      );
    }),
    map(res => {
      if (res != 1) {
        console.info('Server returned Error. update status');
        throw 'Server returned Error. ' + res[0].alert ? res[0].alert : '';
      } else {
        console.info('----------Device is online----------');
      }
    })
  );

  /**
  * * Get Offline Mode
  *
  * @param unique_id
  * @actions SetTvBoxInfo, GetUserInfo, GetRoomChannelSettings, GetTvSettings, GetLastLogo
  */
  @Effect()
  setOffline = this.actions$.pipe(
    ofType(AppInitialActions.SET_OFFLINE_MODE),
    map((action: AppInitialActions.SetOfflineMode) => action.payload),
    mergeMap((payload) => {
      console.info('---- Enable Offline Mode ----');
      if (isNull(JSON.parse(this.storage.read('user_info').user_info).room.tv_settings_language)) {
        if (environment.domain.includes('fagorconnect')) {
          this.core.setTranslation('es');
        } else {
          this.core.setTranslation('en');
        }
      } else {
        this.core.setTranslation(JSON.parse(this.storage.read('user_info').user_info).room.tv_settings_language);
      }

      let roomChannelSettings: RoomChannelSettings = {
        rights: JSON.parse(this.storage.read('rights').rights),
        tv_via: JSON.parse(this.storage.read('tv_via').tv_via),
        tv_hotspot: JSON.parse(this.storage.read('tv_hotspot').tv_hotspot),
        terms: JSON.parse(this.storage.read('terms').terms)
      }

      let channel_list: any;
      if (!!JSON.parse(this.storage.read('tv_channels').tv_channels)) {
        channel_list = this.core.changeImagePath(JSON.parse(this.storage.read('tv_channels').tv_channels));
      }

      let hotelChannels: any;
      if (!!JSON.parse(this.storage.read('hotel_channels').hotel_channels)) {
        hotelChannels = this.core.changeImagePath(JSON.parse(this.storage.read('hotel_channels').hotel_channels));
      }

      return [
        new AppInitialActions.SetTvBoxInfo(JSON.parse(this.storage.read('tvbox_info').tvbox_info)), //* SetTvBoxInfo
        new AppInitialActions.SetUserInfo(JSON.parse(this.storage.read('user_info').user_info)),
        new AppInitialActions.SetTvSettings(JSON.parse(this.storage.read('tv_settings').tv_settings).model),
        new AppInitialActions.SetRoomChannelSettings(roomChannelSettings),
        new AppInitialActions.SetLastLogo(JSON.parse(this.storage.read('last_logo').last_logo).link),
        new HomePageActions.SetChannelBackground(JSON.parse(this.storage.read('channel_background').channel_background)),
        new AppInitialActions.SetNavigationMenu(JSON.parse(this.storage.read('items').items)),
        new TvPageActions.SetChannelsData(channel_list),
        //new TvPageActions.SetHotelChannels(hotelChannels)
      ]
    })
  );


  /**
 * * Navigation Menu Footer
 */
  @Effect({ dispatch: false })
  navigationUpdate = this.actions$.pipe(
    ofType(AppInitialActions.GET_NAVIGATION_MENU),
    withLatestFrom(this.store$.select('appStore')),
    take(1),
    switchMap(([actionState, storeState]) => {
      return this.http.post<any>(
        `${this.core.hostUrl}dynamic-menu/dynamic-menu/list`,
        ''
      );
    }),
    map((res) => {
      if (!res.error) {
        let navigationItems = cloneDeep(res.items);
        let storeState: any = [];
        this.store$.select('appStore').subscribe(appState => storeState = cloneDeep(appState));


        //* Enabled / Disabled Parent category if all children enabled is equal to zero
        forEach(navigationItems, (item) => {
          console.log(item);
          let sumCategory: number = 0;
          forEach(item.items, (subCategory, index: number) => {
            //* Disable input source in devices that not have
            if ((isEqual(this.core.platform, 'tvBox') || isEqual(this.core.platform, 'PC')) && isEqual(subCategory.title, 'Input source')) {
              subCategory.enabled = 0; //* disable input source in tvBox
            }
            sumCategory = sumCategory + subCategory.enabled;
          })
          if (sumCategory === 0 && (item.slug != 'weather') && item.slug != 'applications') {
            item.isActive = false;
          }
          // this.store$.select("appStore").pipe(
          //   filter(appState => !isEmpty(appState.appications)),
          //   take(1)
          // ).subscribe(appState => {
          //  if (item.slug == 'applications') {
          //   item.isActive = true;
          //  }
          // })
        });

        //* KNX Handler
        forEach(navigationItems, (item, key) => {
          if (storeState.room.knx != null && storeState.room.knx.enabled == 1 && item.slug == 'smart-room') {
            navigationItems[key].isActive = true;
          } else {
            if (item.slug == 'smart-room') {
              let weatherObj = navigationItems[key + 1].items[navigationItems[key + 1].items.length - 1]
              navigationItems[key + 1].items[navigationItems[key + 1].items.length - 1].enabled = 0;
              weatherObj['backgroundColor'] = item.backgroundColor;
              weatherObj['borderColor'] = item.borderColor;
              weatherObj['isActive'] = true;
              weatherObj['items'] = [];
              navigationItems[key] = weatherObj;
            }
          }
        });

        if (!isEmpty(storeState.roomChannelSettings.rights)) { // If no application selected at plus display hide the dynamic menu button
          const rights = storeState.roomChannelSettings.rights;

          const showApplications = filter(rights.Applications, (right, idx) => {
            return right[`Applications_${idx}`] === 1
          }).length > 0;

          if (!showApplications) {
            navigationItems = filter(navigationItems, navItem => {
              return navItem.slug !== "applications";
            })
          }

          let index;
          if ((index = _.findIndex(navigationItems, { 'slug': 'settings' })) !== -1) {
            navigationItems[index].items = _.filter(navigationItems[index].items, (el) => el.slug !== 'parental-guidance'); //Remove parental guidance from settings menu
            navigationItems = _.map(navigationItems, el => { //For development purposes
                return {
                  ...el,
                  items: _.map(el.items, item => {
                    if (item.slug === "/about") {
                      return {
                        ...item,
                        standalone: "page"
                      }
                    }
                    return item;
                  })
                }
            });
          }

          if ((index = _.findIndex(navigationItems, { 'slug': 'tv-and-entertainment' })) !== -1) {
            navigationItems[index].items = _.filter(navigationItems[index].items, (el) => el.slug !== 'pay-tv'); //Remove pay tv from tv and entertaiment
          }
        }
        this.store$.dispatch(new AppInitialActions.SetNavigationMenu(navigationItems))
      } else {
        this.store$.dispatch(new AppInitialActions.SetNavigationMenu([]))
      }

    }),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService,
    private storage: StorageService
  ) { }
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { cloneDeep, forEach, isEmpty, isEqual } from 'lodash';

import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import { CoreService } from '@app/services/core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() item$!: any;
  @Input() hasControls: boolean = false;
  public play: boolean = true;
  private routeSubscription: Subscription = new Subscription();
  public type!:string;

  constructor(
    private core: CoreService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (isEmpty(this.item$)) {
      this.routeSubscription = this.route.paramMap.subscribe(params => {
        if (params.get('id')) {
          this.store.select('tvStore').subscribe((content) => {
            forEach(content.mediaContent, (ctx: any) => {
              if (isEqual(ctx.id, parseInt(params.get('id') as string))){
                this.item$ = ctx;
              }
            });
          });
        }

        if (params.get('type')) {
          this.type = params.get('type') as string;
        }
      })

      this.hasControls = window.history.state.hasControls;
    }

    if (this.hasControls) {
      this.mediaPlayToggle(this.item$.link, this.play);
      setTimeout(() => {
        this.core.SN.focus('player-play_pause');
      }, 600);
    }
  }

  ngAfterViewInit() {
    this.core.SN.add({
      selector: '.player__controls .prev',
      id: 'player-prev',
      rememberSource: true,
      restrict: 'self-first',
    });
    this.core.SN.add({
      selector: '.player__controls .next',
      id: 'player-next',
      rememberSource: true,
      restrict: 'self-first',
    });
    this.core.SN.add({
      selector: '.player__controls .play_pause',
      id: 'player-play_pause',
      rememberSource: true,
      restrict: 'self-first',
    });
  }

  mediaPlayToggle(link: string, toggle: boolean) {
    this.play = toggle;
    if (toggle) {
      this.core.playMedia(this.type, link);
    } else {
      this.core.pauseMedia(this.type);
    }
  }

  changeMediaPage = (index: number) => {
    let mediaContent: Array<any> = [];
    this.store.select('tvStore').subscribe((content) => {
      mediaContent = cloneDeep(content.mediaContent);
    });

    forEach(mediaContent, (ctx: any, idx: number) => {
      if (isEqual(ctx.id, this.item$.id)) {
        this.mediaPlayToggle(mediaContent[idx + index].link, true);
        this.router.navigate([`${this.hasControls ? 'music': 'radio'}/channel`, mediaContent[idx + index].id], {state: {hasControls: this.hasControls }, skipLocationChange: true});
      }
    });
  };

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.core.SN.remove('player-play_pause');
    this.core.SN.remove('player-next');
    this.core.SN.remove('player-prev');
  }
}

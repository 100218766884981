<div class="evaluation mt-5 pb-5">
  <div class="col-12">
    <h3 class="evaluation--question">{{ questionString }}</h3>
    <div class="evaluation--selector">
      <form>
        <input id="{{'excellent'+id}}" type="radio" name="evaluation_circles" value="5"  />
        <div  tabindex="-1" class="evaluation--circle excellent focusable-evaluation" [ngClass]="'surveyquest_'+id" (keydown.enter)="select($event, 'excellent'+id)"></div>
        <input id="{{'very_good'+id}}" type="radio" name="evaluation_circles" value="4" />
        <div  tabindex="-1" class="evaluation--circle very_good focusable-evaluation" [ngClass]="'surveyquest_'+id" (keydown.enter)="select($event, 'very_good'+id)"></div>
        <input id="{{'good'+id}}" type="radio" name="evaluation_circles" value="3"/>
        <div  tabindex="-1" class="evaluation--circle good focusable-evaluation" [ngClass]="'surveyquest_'+id" (keydown.enter)="select($event, 'good'+id)"></div>
        <input id="{{'bad'+id}}" type="radio" name="evaluation_circles" value="2" />
        <div  tabindex="-1" class="evaluation--circle bad focusable-evaluation" [ngClass]="'surveyquest_'+id" (keydown.enter)="select($event, 'bad'+id)"></div>
        <input id="{{'poor'+id}}" type="radio" name="evaluation_circles" value="1" />
        <div  tabindex="-1" class="evaluation--circle poor focusable-evaluation" [ngClass]="'surveyquest_'+id" (keydown.enter)="select($event, 'poor'+id)"></div>
      </form>
    </div>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isLgLegacy: false,
  version: require('../../package.json').version,
  platforms: [
    {name: 'PC', code: 100},
    {name: 'SamsungLegacy', code:1},
    {name: 'SamsungTizen', code:2},
    {name: 'LG', code:3},
    {name: 'Philips', code:4},
    {name: 'tvBox', code: 6},
  ],

  locale: { code: 'en_GR', NTPServer: 'gr.pool.ntp.org', timezone: 'Europe/Athens'}, //? only for tizen
  debugWebsocketHost: '', //? Debug Websocket Host
  protocol: 'https://', //! Important
  domain: 'hhotels47000.ocg360.com',
  passKey: 'pass=nF8_hT#!bRw7_Ak6',
  storage: 'localstorage', //* localstorage/device,
  localServerURL:'http://localhost',
};


/*

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from "@angular/core";

import * as AppInitialActions from '@app/store/app.actions';
import * as fromApp from '@app/store/app.reducer';
import { Store } from "@ngrx/store";
import { CoreService } from "./core.service";
import "lodash";

declare const _:any;

@Injectable({ providedIn: 'root' })
export class PhilipsService {
  public Apps: any = {
    "com.google.android.youtube.tv": "Youtube",
    "com.google.android.youtube.tvmusic": "Youtube Music",
    "com.opera.sdk.example": "Internet"
  };

  constructor(private store: Store<fromApp.AppState>) {}

  /**
 *  *Initial Philips Tv
 *  @param core service
 */
  init(core: CoreService): Promise<any> {
    let promise = new Promise( async (resolve, reject) => {
      let IP = null, MAC = null, Duid = null, deviceOnline = false;

      //* Get IP from tv
      try {IP = (window as any).android.getIPAddress()} catch (e:any) {
        console.info("getToken exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
      }

      //* Get Mac from tv
      try {MAC = (window as any).android.getMacAddress()} catch (e:any) {
        console.info("getToken exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
      }

      //* Get Tv token
      try {Duid = await this.getDeviceToken(core)} catch (e:any) {
        console.info("getToken exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
      }

      //* Get Tv online
      try {deviceOnline = await core.onlineCheck()} catch (e:any) {
        console.info("getDevices exception [" + e.code + "] name: " + e.name+ " message: " + e.message);
      }


      this.getApplicationList();
      this.store.dispatch(
        new AppInitialActions.SetPlatformValues({
          IPv4: IP || "Not available",
          IPv6: 'Not available',
          MAC: MAC || "Not available",
          deviceToken: Duid,
          deviceOnline: deviceOnline,
        })
      );
      console.info('------ Android Philips Ready ------');
      resolve(true);
    });
    return promise;
  }

  /**
   *  *Get Smart Box Device Token
   *  @returns token: string;
   */
  getDeviceToken(core: CoreService): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let token = localStorage.getItem('bx_token');
      if(!token) {
        token = core.makeRandomPass(8);
        localStorage.setItem('bx_token', token);
      }
      resolve(token);
    });
    return promise;
  }

  getApplicationList() {
    let win = (window as any);
    let appList = win.android.getApplicationsList();
    let appArray = appList.slice(1, -1).split(',');

    let appArrayList:any = [];
    _.forEach(appArray, (app:any)=> {
      //console.log(app.trim());
      if (this.Apps.hasOwnProperty(app.trim())) {
        let icon = 'assets/images/icons/'+this.Apps[app.trim()]+'.svg';

        appArrayList.push({
          title: this.Apps[app.trim()],
          imgPath: icon || null,
          slug: app.trim(),
          hover: false,
          enabled: 1,
          blockedWhenOffline: 1,
        });
      }
    });

    //console.log(appArrayList);
    this.store.dispatch(new AppInitialActions.SetTvApplictions(appArrayList));
  }

   /**
   ** Save Channel List to Java
   * @param channels
   */
   saveChannelListToJava(channelList:any) {
      let win = (window as any);
      win.android.saveChannelListToJava(JSON.stringify(channelList));
      console.info('channels list saved to java');
   }


  /**
   ** IPTV Watch Tv
   * @param channel
   * @param radio (optional)
   */
   watchIPTV(channel:any, radio?: any) {
    let win = (window as any);
    let idx = 0;

    console.info(
      `------ Start Playing Channel ${channel.tvchannel_name} ------`
    );

    if (!radio) {
      this.store.select('tvStore').subscribe(state => {
        idx = _.findIndex(state.channels, (ch:any, i:any) => ch.id == channel.id);
      })
    } else {
      this.store.select('tvStore').subscribe(state => {
        idx = _.findIndex(state.mediaContent, (ch:any, i:any) => ch.id == channel.id);
      })

    }

    win.android.startPlayer(idx, radio);

    console.info(`------ Channel PLaying Success ------`);
   }

    /**
   ** Reboot Tv
   */
  reboot() {
    let win = (window as any);
    win.android.rebootDevice();
    console.info('reboot from java');
  }

    /**
   ** Watch RF Tv
    */
  watchRf() {
    let win = (window as any);
    win.android.runApp("org.droidtv.playtv", "org.droidtv.playtv.PlayTvActivity");
  }

  openChromecast() {
    let win = (window as any);

    win.android.runApp("org.droidtv.hotspot.castwizard", "org.droidtv.hotspot.castwizard.CastWizardActivity");
  }

  openApp(item:any) {
    let win = (window as any);
    console.log(item);
    win.android.runApp(item.slug, null);
  }

  openInput() {
    let win = (window as any);

    win.android.runApp("org.droidtv.channels", "org.droidtv.channels.htv.HTVChannelsActivity");
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { cloneDeep } from 'lodash';

/*
 * Localize filter to tranform lang form API
 */
@Pipe({ name: 'langFilter' })
export class LanguageFilterPipe implements PipeTransform {
  constructor(private core: CoreService) {}

  transform(value: any): any {
    let data = cloneDeep(value);
    if (!!data.channelContentLangs) {
      for (let langContent of data.channelContentLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title !== '') data.title = langContent.title;
          if (langContent.content !== '') data.content = langContent.content;
        }
      }
    } else if (!!data.poicatLangs) {
      for (let langContent of data.poicatLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title !== '') data.title = langContent.title;
          if (langContent.description !== '') data.description = langContent.description;
        }
      }
    } else if (!!data.poiLangs) {
      for (let langContent of data.poiLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title !== '') data.title = langContent.title;
          if (langContent.description !== '') data.description = langContent.description;
        }
      }
    } else if (!!data.facLangs) {
      for (let langContent of data.facLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title !== '') data.title = langContent.title;
          if (langContent.description !== '') data.description = langContent.description;
        }
      }
    } else if (!!data.hfacLangs) {
      for (let langContent of data.hfacLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title !== '') data.title = langContent.title;
          if (langContent.name !== '') data.name = langContent.name;
          if (langContent.description !== '') data.description = langContent.description;
          if (langContent.content !== '') data.content = langContent.content;
        }
      }
    } else if (!!data.predefinedMessageTranslations) {
      for (let langContent of data.predefinedMessageTranslations) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.message !== '') data.message = langContent.message;
        }
      }
    } else if (!!data.messagesLangs) {
      for(let langContent of data.messagesLangs){
        if(langContent.language == this.core.currentLang){
          langContent.title != '' && (data.title = langContent.title)
          langContent.description != '' && (data.description = langContent.description)
        }
      }
    } else if (!!data?.serviceLangs) { //* Get Service traslation from OCG
      for (let langContent of data.serviceLangs) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.name !== '') data.name = langContent.name;
          if (langContent.description !== '') data.description = langContent.description;
        }
      }
    } else if (!!data?.translations) {
      for(let langContent of data.translations){
        if(langContent.language == this.core.currentLang){
          langContent.title != '' && (data.title = langContent.title)
        }
      }
      data.translations[this.core.currentLang] != '' && (data.name = data.translations[this.core.currentLang])
    } else if (!!data.streamingMoviesLang) {
      for(let langContent of data.streamingMoviesLang){
        if(langContent.language == this.core.currentLang){
          langContent.title != '' && (data.title = langContent.title)
          langContent.description != '' && (data.description = langContent.description)
        }
      }
    } else {
      for (let langContent of data) {
        if (langContent.language == this.core.currentLang) {
          if (langContent.title != '') data.title = langContent.title;
          if (langContent.text != '') data.text = langContent.text;
          if (langContent.from != '') data.from = langContent.from;
        }
      }
    }

    return data;
  }
}

import { Action } from '@ngrx/store';

export const GET_IP_CHANNELS_DATA = '[TV Page] Get IP Channels Data';
export const SET_CHANNELS_DATA = '[Tv Page] Set Channels Data';
export const GET_HOTEL_CHANNELS = '[TV Page] Get Hotel Channels';
export const SET_HOTEL_CHANNELS = '[Tv Page] Set Hotel Channels';
export const GET_RADIO_DATA = '[Radio Page] Get Radio Data';
export const SET_RADIO_DATA = '[Radio Page] Set Radio Data';
export const GET_MUSIC_DATA = '[Music Page] Get Music Data';
export const SET_MUSIC_DATA = '[Music Page] Set Music Data';
export const PAY_TV = '[Pay Tv Page] Pay Tv';
export const SET_PAYMENT_TV = '[Pay Tv Page] Set Tv Payment';
export const GET_PAYTV_CHANNELS = '[Pay Tv Page] Get PayTv Channels';
export const SET_PAYTV_CHANNELS = '[Music Page] Set PayTv Channels';
export const GET_CHROMECAST_CONTENT = '[TV Page] Get Chromecast';
export const SET_CHROMECAST_CONTENT = '[TV Page] Set Chromecast';
export const GET_MOVIES_DATA = '[Movies Page] Get Movies Data';
export const SET_MOVIES_DATA = '[Movies Page] Set Movies Data';

export class GetIpChannelsData implements Action {
  readonly type = GET_IP_CHANNELS_DATA;
  constructor(public payload: string ) {}
}
export class SetChannelsData implements Action {
  readonly type = SET_CHANNELS_DATA;

  constructor(public payload: any ) {}
}
export class GetRadioData implements Action {
  readonly type = GET_RADIO_DATA;
  constructor(public payload: string ) {}
}
export class SetRadioData implements Action {
  readonly type = SET_RADIO_DATA;

  constructor(public payload: any ) {}
}
export class GetMusicData implements Action {
  readonly type = GET_MUSIC_DATA;
}
export class SetMusicData implements Action {
  readonly type = SET_MUSIC_DATA;

  constructor(public payload: any ) {}
}
export class GetMoivesData implements Action {
  readonly type = GET_MOVIES_DATA;
}
export class SetMoviesData implements Action {
  readonly type = SET_MOVIES_DATA;

  constructor(public payload: any ) {}
}
export class PayTv implements Action {
  readonly type = PAY_TV;

  constructor(public payload: { check: number} ) {}
}
export class SetPaymentTv implements Action {
  readonly type = SET_PAYMENT_TV;

  constructor(public payload: any ) {}
}
export class GetPayTvChannels implements Action {
  readonly type = GET_PAYTV_CHANNELS;
}
export class SetPayTvChannels implements Action {
  readonly type = SET_PAYTV_CHANNELS;

  constructor(public payload: any ) {}
}
export class GetChromecastContent implements Action {
  readonly type = GET_CHROMECAST_CONTENT;
}
export class SetChromecastContent implements Action {
  readonly type = SET_CHROMECAST_CONTENT;

  constructor(public payload: any ) {}
}
export class GetHotelChannels implements Action {
  readonly type = GET_HOTEL_CHANNELS;
  constructor(public payload: string ) {}
}
export class SetHotelChannels implements Action {
  readonly type = SET_HOTEL_CHANNELS;

  constructor(public payload: any ) {}
}

export type tvPageActions =
  | GetIpChannelsData
  | SetChannelsData
  | GetRadioData
  | SetRadioData
  | GetMusicData
  | SetMusicData
  | PayTv
  | SetPaymentTv
  | GetPayTvChannels
  | SetPayTvChannels
  | GetChromecastContent
  | SetChromecastContent
  | GetMoivesData
  | SetMoviesData
  | GetHotelChannels
  | SetHotelChannels;


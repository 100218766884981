import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromApp from '@app/store/app.reducer';
import * as InformationActions from './information.actions';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class InformationEffects {
   /**
   * * Get Points Of interest Categories
   *
   * @param corp_id
   * @returns array of points of interest categories
   */
  @Effect()
  getbusinformations = this.actions$.pipe(
    ofType(InformationActions.GET_POI_CATEGORIES_LIST),
    map((action: InformationActions.GetPoiCategoriesList) => action.payload),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([ payload, storeState ]) => {
      let body = `&corp_id=${storeState.tvbox_info.corporate}`;
      return this.http.post<any>(
        `${this.core.hostUrl}${payload}`,
        body
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get points of interest categories');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got points of interest categories ----------');
        return new InformationActions.SetPoiCategoriesList(res.data)
      }
    })
  );

  /**
   * * Get Point Of interest QRcodes Android
   *
   * @param poi_id
   * @returns array of points of interest categories
   */
  @Effect()
  getqrcodesandroid = this.actions$.pipe(
    ofType(InformationActions.GET_QRCODES_ANDROID),
    map((action: InformationActions.GetPointQRCodesAndroid) => action.payload),
    switchMap(payload => {
      let body = `&poi_id=${payload}&device_type=android`;
      return this.http.post(
        `${this.core.hostUrl}poi/getpoi-qrcode`,
        body,
        { responseType: 'text' }
      );
    }),
    map(res => {
      if(!res){
        console.info('Server returned Error. get points of interest categories');
        throw('Server returned Error.');
      } else {
        console.info('----------got point QR code----------');
        let result = this._sanitizer.bypassSecurityTrustUrl(res);
        return new InformationActions.SetPointQRCodesAndroid(result);
      }
    })
  );

  /**
   * * Get Point Of interest QRcodes Ios
   *
   * @param poi_id
   * @returns array of points of interest categories
   */
  @Effect()
  getqrcodesios = this.actions$.pipe(
    ofType(InformationActions.GET_QRCODES_IOS),
    map((action: InformationActions.GetPointQRCodesAndroid) => action.payload),
    switchMap(payload => {
      let body = `&poi_id=${payload}&device_type=ios`;
      return this.http.post(
        `${this.core.hostUrl}poi/getpoi-qrcode`,
        body,
        { responseType: 'text' }
      );
    }),
    map(res => {
      if(!res){
        console.info('Server returned Error. get points of interest categories');
        throw('Server returned Error.');
      } else {
        console.info('----------got point QR code----------');
        let result = this._sanitizer.bypassSecurityTrustUrl(res);
        return new InformationActions.SetPointQRCodesIos(result);
      }
    })
  );

   /**
   * * Get Bus Informations API
   *
   * @param channel_id
   * @param date_start
   * @param date_end
   * @param user_id
   * @returns array of bus informations per day
   */
    @Effect()
    getbusinformationsdata = this.actions$.pipe(
      ofType(InformationActions.GET_BUS_INFOS),
      map((action: InformationActions.GetBusInfos) => action.payload),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([ payload, storeState ]) => {
        let body = `&date_from=${payload.date_from}&date_to=${payload.date_to}&user_id=${storeState.profile.user_id}`;
        return this.http.post<any>(
          `${this.core.hostUrl}timeline/timeline/getbustimeline&id=${storeState.tvbox_info.channel_id}`,
          body
        );
      }),
      map((res) => {
        if(res.error){
          console.info('Server returned Error. get bus informations');
          throw('Server returned Error. '+res.alert ? res.alert : '');
        } else {
          console.info('----------got bus informations ----------');
          return new InformationActions.SetBusInfos(res)
        }
      })
    );

  /**
   * * Get Weather
   *
   * @param channel_id
   * @returns images option  poi
   */
  @Effect()
  getweatherdata = this.actions$.pipe(
    ofType(InformationActions.GET_WEATHER_DATA),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([ actionState, storeState ]) => {
      let body = `&user_id=${storeState.profile.user_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}widgets/get-plus-weather&id=${storeState.tvbox_info.channel_id}`,
        body
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get channel background');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got weather data----------');
        return new InformationActions.SetWeatherData(res[0].forecast)
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService,
    private _sanitizer: DomSanitizer
    // private storage: StorageService
  ) {}
}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Device} from '@capacitor/device';

import { environment } from '@environments/environment';
import * as navigationItems from '@assets/interfaces/navigationItems.json';

import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as AppInitialActions from '@app/store/app.actions';
import { ScriptService } from './script.service';
import { TizenService } from './tizen.service';
import { LGService } from './lg.service';
import { TvBox } from './tvbox.service';
import { PhilipsService } from './philips.service';

@Injectable({ providedIn: 'root' })
export class CoreService {
  public hostUrl: string = `${environment.protocol}${environment.domain}/web/index.php?r=`;
  public platform: string = 'PC';
  public SN = (window as any).SpatialNavigation;
  deviceGotToken = new BehaviorSubject(false);
  public navigationItems: any[] = (navigationItems as any).default;
  public deviceToken: string = '';
  public deviceOnline: boolean | undefined;
  public unique_id: string | undefined;
  public loading: boolean = true;
  public showRegistration: AsyncSubject<boolean> = new AsyncSubject();
  public playWelcomeAnimation: boolean = false;
  public currentLang: string = '';
  private imageNames:Array<string> = ['image','logo','picture','poi','link','mobile'];
  private audioNames:Array<string> = ['sound'];

  constructor(
    public router: Router,
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    private scriptService: ScriptService,
    private tizen: TizenService,
    private lg: LGService,
    private philips: PhilipsService,
    private tvbox: TvBox
  ) {
  }

  /**
   *  Debug Web Socket for TV
   */
  debugWebSocket(): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      if (!_.isEqual(this.platform, 'PC') && !_.isEqual(environment.debugWebsocketHost, '')) {
        var connection = new WebSocket(environment.debugWebsocketHost);
        connection.onopen = () => {
          console.info = (message: any) => {
            connection.send(this.platform + ': ' + message);
          };
          window.onerror = (e, url, lineno, colno, err: any) => {
            connection.send(
              this.platform +
                ': ' +
                e.toString() +
                ' ' +
                url +
                ' ' +
                lineno +
                ' ' +
                colno +
                ' ' +
                err.message
            );
          };
          console.info(navigator.userAgent);
          console.info('------ Debug Websocket Ready ------');
          connection.onerror = () => {
            console.info('------ Debug Websocket Error ------');
            connection.close();
            resolve(false);
          };
          resolve(true);
        };
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  /**
   *  Platfrorm Initial
   */
  platformInitialize = async () => {
    try {
      if (_.isEqual(this.platform, 'SamsungLegacy')) {
        //!--------------- SamsungLegacy ----------//
        this.scriptService.load('WebApis1.0', 'Widget', 'CAPH').then(() => {
          //this.legacy.init(); //* Platform Init
          // console.info(this.legacy.readObject('unique_id'));
        });
      } else if (_.isEqual(this.platform, 'SamsungTizen')) {
        //!--------------- SamsungTizen ----------//
        let loadedScripts = await this.scriptService.load(
          'TizenWebApis',
          'TizenB2BApis'
        );
        if (!!loadedScripts) {
          console.info('------ Loaded Scripts ------');
          await this.tizen.init(this);
          this.router.navigate(['home']);
        }
      } else if (_.isEqual(this.platform, 'LG')) {
        //!--------------- LG ----------//
        let loadedScripts = await this.scriptService.load(
          'HCAP'
        );
        if (!!loadedScripts) {
          console.info('------ Loaded Scripts ------');
          await this.lg.init(this);
          this.router.navigate(['home']);
        }
      } else if (_.isEqual(this.platform, 'Philips')) {
        //!--------------- Philips ----------//
        await this.philips.init(this);
        this.router.navigate(['home']);
      } else if (_.isEqual(this.platform, 'tvBox')) {
        //!--------------- tvBox ----------//
        await this.tvbox.init(this);
        this.router.navigate(['home']);
      } else {
        //!--------------- PC ----------//
        const deviceUuid = (await Device.getId()).uuid;
        this.store.dispatch(
          new AppInitialActions.SetPlatformValues({
            IPv4: 'Not available',
            IPv6: 'Not available',
            MAC: deviceUuid,
            deviceToken: deviceUuid,
            deviceOnline: true,
          })
        );

        this.router.navigate(['home']);
      }
    } catch (error) {
      console.info(error);
    }
  };

  /**
   * * Watch Channel/Radio by Platform
   * @param channel
   * @param radio
   */
  watchChannel(channel: any, radio?: boolean) {
    if (!radio || (_.isEqual(this.platform, 'Philips'))) { //* hide App
      document.querySelector('.app-container')?.setAttribute('style', 'opacity:0;');
    }
    if (this.platform == 'SamsungLegacy') {
      //this.legacy.watchIPTV(item);
    } else if (this.platform == 'LG') {
      this.lg.watchIPTV(channel, radio);
    } else if (this.platform == 'SamsungTizen') {
      this.tizen.watchIPTV(channel, radio);
    }  else if (this.platform == 'Philips') {
      this.philips.watchIPTV(channel, radio);
    }  else if (this.platform == 'tvBox') {
      this.tvbox.watchIPTV(channel, radio);
      document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
    } else {
      document.querySelector('.app-container')?.setAttribute('style', 'opacity:1;');
    }
  }

   /**
   * * Watch Media by Platform
   * @param channel
   * @param radio
   */
    playMedia(type: string, link: string) {
      if (this.platform == 'LG') {
        this.lg.playPauseMedia(type, link);
      } else if (this.platform == 'SamsungTizen') {
        this.tizen.tizenPlayPauseMedia(type, link);
      }  else if (this.platform == 'tvBox') {}
    }

    pauseMedia(type: string) {
      if (this.platform == 'LG') {
        this.lg.stopPlayers(type);
      } else if (this.platform == 'SamsungTizen') {
        this.tizen.pauseMedia(type);
      } else if (this.platform == 'Philips') {
      }  else if (this.platform == 'tvBox') {}
    }

  /**
   * * Run Smart Tv Application by Platform
   * @param item
   */
  launchApp(item: any) {
    if (_.isEqual(this.platform, 'SamsungTizen')) {
      this.tizen.launchTizenApplication(
        item.standalone == 'external-source' ? item.slug.tizen : item.slug,
        false
      );
    } else if (_.isEqual(this.platform, 'LG')) {
      this.lg.launchLGApplication(item);
    } else if (_.isEqual(this.platform, 'Philips')) {
      if (item.standalone == 'external-source') {
        this.philips.openInput();
      }else {
        this.philips.openApp(item);
      }
    } else if (_.isEqual(this.platform, 'tvBox')) {
      this.tvbox.openApp(item);
    }
  }
  /**
   * * Open Link On Browser By Platform
   * @param link
   */
  openBrowserApp(link: string) {
    if (_.isEqual(this.platform, 'SamsungTizen')) {
      this.tizen.launchTizenBrowser(link);
    } else if (_.isEqual(this.platform, 'LG')) {
      this.lg.openBrowser(link);
    } else {
      window.open(link, '_blank');
    }
  }
  /**
   * * Open OCG Application External on a browser
   * @param item
   */
  launchOcgApp(item: any) {
    if (item.slug == 'room-service') {
      let roomId:number = 0;
      let lang:string = '';
      this.store.select('appStore').subscribe(appState => {
        roomId = appState.tvbox_info.room_id;
        lang = appState.room.tv_settings_language
      })

      this.openBrowserApp(`${this.hostUrl}sales/orders/tv-create&fac_id=null&view=null&position=null&lang=${lang}&is_tv=1&room_id=${roomId}`);
    }
  }

  /**
   * * Format Timestamp to Time
   * @param timestamp
   * @returns time
   */
  formatTimestampToTime = (timestamp: any) => {
    let date = new Date(timestamp * 1000);

    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = '0' + date.getMinutes();

    return hours + ':' + minutes.substr(-2);
  };

  /**
   * * Set Translation Init
   * @param value
   */
  setTranslation = (value: any) => {
    this.translate.addLangs(['en','fr','gr','it','ar','de','es','ru','tr', 'nl',]);
    this.translate.setDefaultLang(value);
    this.currentLang = value;
  };

  /**
   * * Reboot Device by Platform
   * @param data
   */
  rebootState(data?: any) {
    if (this.platform == 'LG') {
      this.lg.reboot();
    } else if (this.platform == 'SamsungTizen') {
      this.tizen.reboot();
    } else if (this.platform == 'Philips') {
      this.philips.reboot();
    } else if (this.platform == 'tvBox') {
      this.tvbox.reboot();
    } else {
      window.location.href = '/';
    }
  }

  /**
   * * Set Background Color Value
   * @param value
   */
  setBackround = (value: string) => {
    document.body.style.backgroundColor = value;
  };

  /**
   * * Check if Device is Online
   * @returns true/false
   */
  onlineCheck():Promise<boolean> { // make requests to check if device is online
    return new Promise(resolve => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', this.hostUrl+'tvbox/checkiftvexists');
        try {
            xhr.send();
            xhr.onload = (e)=>{
                this.deviceOnline = (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304 ));
                resolve(true);
            }
            xhr.onerror = (e)=>{
                this.deviceOnline = false;
                console.info('device is OFFLINE');
                resolve(false);
            }
        } catch (error) {
            this.deviceOnline = false;
            console.info('xhr error '+error);
            console.info('device is OFFLINE');
            resolve(false);
            //setTimeout(()=>this.onlineCheck(),4000);
        }
    });
  }
  /**
   * * Change Image Path from cloud to local
   * @param item
   */
  changeImagePath(item: any){
    if(Array.isArray(item) || item instanceof Object){
      this.imageNames.forEach(element => {
          if((element in item) && (item[element] != null)){
            const regex = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
            let m;
            if ((m = regex.exec(item[element])) !== null) {
              //? The result can be accessed through the `m`-variable.
              m.forEach((match, groupIndex) => {
                  let bucket = match.split('/')[1];
                  item['local_link'] = environment.localServerURL + match.replace(`${bucket}/`, 'assets/bucket/');
              });
            }
          }
      });

      if('data' in item){
          for(let inner_item of item.data){
              this.changeImagePath(inner_item);
          }
      }else if(Array.isArray(item)){
          for(let inner_item of item){
              this.changeImagePath(inner_item);
          }
      }else{
        for(let key in item){
          if(Array.isArray(item[key])){
            this.changeImagePath(item[key]);
          } else if (key == 'body') {
            this.changeImagePath(item[key].message);
          }
        }
      }
    }
    return item;
  }

  /**
   * * Change Audio Path from cloud to local
   * @param item
   */
  changeAudioPath(item: any){
    if(Array.isArray(item) || item instanceof Object){
      this.audioNames.forEach(element => {
          if((element in item) && (item[element] != null)){
            const regex = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
            let m;
            if ((m = regex.exec(item[element])) !== null) {
              //? The result can be accessed through the `m`-variable.
              m.forEach((match, groupIndex) => {
                  item['local_link'] = environment.localServerURL + match;
              });
            }
          }
      });

      if('data' in item){
          for(let inner_item of item.data){
              this.changeImagePath(inner_item);
          }
      }else if(Array.isArray(item)){
          for(let inner_item of item){
              this.changeImagePath(inner_item);
          }
      }else{
        for(let key in item){
          if(Array.isArray(item[key])){
            this.changeImagePath(item[key]);
          } else if (key == 'body') {
            this.changeImagePath(item[key].message);
          }
        }
      }
    }
    return item;
  }

  /**
   * * Image Error Handler
   * @param evn
   * @param link
   */
  errorImageHandler(evn: any, link:string) {
    evn.target.src = link;
  }

  /**
   * * Open Chromecast
   */
  openChromecast() {
    if (_.isEqual(this.platform, 'SamsungTizen')) {
      this.tizen.openChromecast();
    } else if (_.isEqual(this.platform, 'LG')) {
      this.lg.openChromecast();
    } else if (_.isEqual(this.platform, 'Philips')) {
      this.philips.openChromecast();
    }
  }

  /**
   * * Enable Hotsport on Device by Platform
   */
  enabledHotspot() {
    if (_.isEqual(this.platform, 'SamsungTizen')) {
      this.tizen.setTVHotSpot(this);
    } else if (_.isEqual(this.platform, 'LG')) {
      this.lg.setTVHotSpot(this);
    } else {
      console.info("!---- Has Hotspot To Enable ---!")
    }
  }

  /**
   * * Make Random Pass function
   * @param length
   * @returns string
   */
  makeRandomPass(length: number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * * Change to Fagor
   * @param length
   * @returns string
   */
  getFagorExclusiveImages() {
    if (environment.domain.includes('fagorconnect')) {
      return {
        offlineLogo: "assets/images/logo_white_offline_fagor.png",
        splashLogo: "assets/images/splash/fagor.gif"
      }
    } else {
      return {
        offlineLogo: "assets/images/fallback_ocg_logo.png",
        splashLogo: "assets/images/splash/logo_plus_appear.gif"
      }
    }
  }
}

export class TVSettings{
  currencySymbol!: string;
  movie_duration!: number | null;
  movie_rent!: number;
  movies_external!: number;
  movies_external_link!: string;
  music_duration!: number | null;
  music_rent!: number;
  paytv_duration!: number | null;
  paytv_rent!: number;
  price!: number;
  timeZone!:string
}

import { Corporate } from '@app/models/corporate';
import * as SplashActions from './splash.actions';

export interface State {
  corporates: Corporate[];
  roomstatus: string;
}

const initialState: State = {
  corporates: [],
  roomstatus: ''
}


export function splashReducer(
  state: State = initialState,
  action: SplashActions.SplashActions
): State {
  switch (action.type) {
    case SplashActions.SET_CORPORATES:
      return {
        ...state,
        corporates: [...state.corporates, ...action.payload]
      };
    case SplashActions.SET_ROOM_STATUS:
      return {
        ...state,
        roomstatus: action.payload
      };
    default:
      return state;
  }
}

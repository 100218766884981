import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '@app/services/core.service';
import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-parental-control',
  templateUrl: './parental-control.component.html',
  styleUrls: ['./parental-control.component.scss']
})
export class ParentalControlComponent implements OnInit, AfterViewInit {
  public parentalForm!: FormGroup;
  @Input() question!: string;

  constructor(private modalService: ModalService, private fb: FormBuilder, private store: Store<fromApp.AppState>, private core: CoreService) {
    this.parentalForm = fb.group({
      parentalNum: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.core.SN.focus('form-control');
      this.core.SN.disable('category-items');
      this.core.SN.disable('footer-btns');
    }, 1000);
  }

  ngAfterViewInit() {
    this.core.SN.add({
      selector: '.modal-footer .btn',
      id: 'confirm-btn',
      rememberSource: true,
      restrict: 'self-first',
    });

    this.core.SN.add({
      selector: '.modal-body .form-control',
      id: 'form-control',
      rememberSource: true,
      restrict: 'self-first',
    });
  }

  update(evn: any) {
    this.parentalForm.patchValue({[evn.target.id]: evn.target.value});
  }

  cancel() {
    this.core.SN.enable('footer-btns');
    this.core.SN.enable('category-items');
    this.modalService.hide();
    setTimeout(()=> {
      this.core.SN.focus('footer-btns');
    }, 400)
  }


  confirm() {
    let parentalNumber = `${this.parentalForm.value.parentalNum}`;
    this.store.select('appStore').subscribe(appState => {
       if (appState.room.parental_code == parentalNumber) {
          this.core.SN.enable('footer-btns');
          this.modalService.parentalConfirmation.next(true);
       }
    });
  }
}

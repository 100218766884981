import {Component, Input, TemplateRef, ViewChild} from "@angular/core";

@Component({
    selector: 'splide-slide',
    template: '<ng-template #slideContent><ng-content></ng-content></ng-template>'
})
export class OCGSplideSlideComponent {
    @ViewChild('slideContent')
    public slideContent: TemplateRef<any> | undefined;
    @Input() disabled!:boolean;
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import * as fromApp from '@app/store/app.reducer';
import { Store } from "@ngrx/store";
import * as SmartRoomActions from './smart-room.actions';
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { CoreService } from "@app/services/core.service";

@Injectable()
export class SmartRoomEffects {

  /**
 * * Get Room Areas
 *
 * @param room_id
 * @returns array of room areas
 */
  @Effect()
  getbusinformations = this.actions$.pipe(
    ofType(SmartRoomActions.GET_ROOM_AREAS),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([appState, storeState]) => {
      return this.http.post<any>(
        `${this.core.hostUrl}rooms/rooms/room-areas&id=${storeState.tvbox_info.room_id}`,
        ''
      );
    }),
    map((res) => {
      if(res.error){
        console.info('Server returned Error. get smart room areas');
        throw('Server returned Error. '+res.alert ? res.alert : '');
      } else {
        console.info('----------got smart room areas ----------');
        return new SmartRoomActions.SetRoomAreas(res.areas);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService
    // private storage: StorageService
  ) {}
}

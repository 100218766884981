import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import * as fromApp from '@app/store/app.reducer';
import * as ReservationsActions from './reservations.actions';
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { CoreService } from "@app/services/core.service";
import { isEqual } from "lodash";

@Injectable()
export class ReservationsEffects {

   /**
   * * Get Reservations Category
   *
   * @param channel_id
   * @returns ip channels array
   */
    @Effect()
    getReservationsCategories = this.actions$.pipe(
      ofType(ReservationsActions.GET_FACILITIES_CATEGORIES),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        return this.http.post<any>(
          `${this.core.hostUrl}facilities/facilities/getfacilitycategories&id=${storeState.tvbox_info.corporate}&channel_id=${storeState.tvbox_info.channel_id}&isTv=1`,
          ''
        );
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. get facilities categories');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('----------got facilities categories ----------');
          return new ReservationsActions.SetFacilitiesCategories(res);
        }
      })
    );

    /**
   * * Get Facility Services
   * @param id fac_id
   */
    @Effect()
   getReservationServiceSettings$ = this.actions$.pipe(
      ofType(ReservationsActions.GET_FACILITY_SERVICES),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        console.log((actionState as any).payload)
        this.store$.dispatch(new ReservationsActions.SetFacilities((actionState as any).payload));
        return this.http.get<any>(
          `${this.core.hostUrl}facilities/services/getservices&fac_id=${
            (actionState as any).payload.id
          }`
        );
      }),
      map((res) => {
        if (!isEqual(res, 'no data')) {
          console.info('!--- Get Reservation Services ---!');
          return new ReservationsActions.SetFacilityServices(res);
        }
        return new ReservationsActions.SetFacilityServices(null);
      })
    );


  constructor(
    private actions$: Actions,
    private store$: Store<fromApp.AppState>,
    private http: HttpClient,
    private core: CoreService
  ) {}
}

import * as reservationsActions from './reservations.actions';

export interface State {
  facilityCategories: Array<any>;
  facilities: Array<any> | null;
  services: Array<any> | null;
}

const initialState: State = {
  facilityCategories: [],
  facilities: [],
  services: null,
}

export function reservationsReducer(
  state: State = initialState,
  action: reservationsActions.reservationsActions): State {
    switch (action.type) {
      case reservationsActions.SET_FACILITIES_CATEGORIES:
        return {
          ...state,
          facilityCategories: action.payload
        }
      case reservationsActions.SET_FACILITIES:
        return {
          ...state,
          facilities: action.payload
        }
      case reservationsActions.SET_FACILITY_SERVICES:
        return {
          ...state,
          services: action.payload
        };
      default:
        return state;
    }
}

import { Component, Input, OnInit } from '@angular/core';

import Toast from 'bootstrap.native/dist/components/toast-native.esm.js';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  private toast: any;
  @Input() id: any;
  @Input() content: string = '';
  @Input() title?: string = '';
  @Input() type: string = '';
  @Input() closeBtn: boolean = false;

  constructor() {
    console.info('toast ---- loaded ---');
  }

  ngOnInit(): void {}

  toggle() {
    this.toast = new Toast('#'+this.id);
    this.toast.show();

    setTimeout(()=>{
      document.querySelector('app-toast')?.remove();
    }, 5000)
  }

  show() {
    // this.toast = new Toast('#'+this.id);
    this.toast.show();
  }

  hide() {
    this.toast.hide();
  }

}

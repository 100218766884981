import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreService } from '@app/services/core.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() question!: any;
  @Input() id!: number;
  @Input() answers!: any;
  @Output() selectedCheckbox: EventEmitter<any> = new EventEmitter<any>();
  private checkedItems:any[] = []
  public questionString=this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question || this.question?.question

  constructor(private core:CoreService) {}

  ngOnInit(): void {
    this.questionString=this.question?.questionsLangs.find((itm:any)=>(itm.language==this.core.currentLang)).question || this.question?.question
  }

  focusCheck(evn: any, ans: any) {
    evn.stopPropagation();
    evn.preventDefault();
    if (evn.srcElement.children[1].checked == true) {
      //uncheck and remove
      evn.srcElement.children[1].checked = false;
      var i = this.checkedItems.findIndex((id) => id == ans.id);
      this.checkedItems.splice(i, 1);
    } else {
      //check and add
      
      evn.srcElement.children[1].checked = true;
      this.checkedItems.push(ans.id);
    }
    this.selectedCheckbox.emit({id: this.id, value: this.checkedItems});
  }

  answerTranslate(answer:any){
    return answer.answersLang.find((itm:any)=>(itm.language==this.core.currentLang)).answer || answer.answer;
  }

}


import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'removeTonation' })
export class RemoveTonationPipe implements PipeTransform {
    transform(value?: string): string | null {
        if (value != null) {
            value = value.replace(/Ά|Α|ά/g, 'α')
                .replace(/Έ|Ε|έ/g, 'ε')
                .replace(/Ή|Η|ή/g, 'η')
                .replace(/Ί|Ι|ί|ΐ|ϊ/g, 'ι')
                .replace(/Ό|Ο|ό/g, 'ο')
                .replace(/Ύ|Υ|ύ|ΰ|ϋ/g, 'υ')
                .replace(/Ώ|Ω|ώ/g, 'ω')
                .replace(/Σ|ς/g, 'σ');
            return value;
        } 
        return null;
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/services/core.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { concatMap, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as AppInitialActions from '@app/store/app.actions';
import * as QuestActions from './guest.actions';
import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import { isEqual, orderBy, sortBy } from 'lodash';

@Injectable()
export class QuestEffects {
  /**
   * * Get Survey Categories
   *
   * @param channel_id
   * @returns []
   */
  @Effect()
  getsurveycategories = this.actions$.pipe(
    ofType(QuestActions.GET_SURVEY_CATEGORIES),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}questionnaire/surveys/get-plus-survey`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('----------got Survey Categories----------');
        return new QuestActions.SetServeyCategories({id: res[0].id,surveyCategories: res[0].surveyCategories});
      }
    })
  );

  /**
   * * Submit Answers
   *
   * @param user_id
   * @param survey_id
   * @param answers array
   */
  @Effect({ dispatch: false })
  submitanswers = this.actions$.pipe(
    ofType(QuestActions.SUBMIT_SURVEY),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let ansString = '';
      for (let ans of (actionState as any).payload.answers) {
        ansString += '&' + ans.question_id + '=' + ans.value;
      }

      let body = `&userid=${storeState.room.user_id}&survey_id=${
        (actionState as any).payload.survey_id
      }${ansString}`;
      return this.http.post<any>(
        `${this.core.hostUrl}questionnaire/questions/submitanswers`,
        body
      );
    }),
    tap((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('---------- Answers Submited ----------');
        //TODO create a toast to info user that anwsers submited!
      }
    })
  );

  /**
   * * Get Preferences Categories
   *
   * @param channel_id
   * @returns []
   */
   @Effect()
   getpreferencescategories = this.actions$.pipe(
     ofType(QuestActions.GET_PREFERENCES_CATEGORIES),
     withLatestFrom(this.store$.select('appStore')),
     switchMap(([actionState, storeState]) => {
       let body = `&channel_id=${storeState.tvbox_info.channel_id}`;
       return this.http.post<any>(
         `${this.core.hostUrl}questionnaire/surveys/get-plus-preference-survey`,
         body
       );
     }),
     map((res) => {
       if (res.error) {
         console.info('Server returned Error. get languages');
         throw 'Server returned Error. ' + res.alert ? res.alert : '';
       } else {
         console.info('----------got Survey Categories----------');
         return new QuestActions.SetServeyCategories({id: res[0].id,surveyCategories: res[0].surveyCategories});
       }
     })
   );

   /**
   * * Submit Alarm Time
   *
   * @param user_id
   * @param wakeup
   * @param answers array
   */
  @Effect()
  sumbitalarmtime = this.actions$.pipe(
    ofType(QuestActions.SUBMIT_WAKE_UP),
    withLatestFrom(this.store$.select('appStore')),
    concatMap(([actionState, storeState]) => {
      let body = `&checkedInUserId=${storeState.room.check_in_id}&timestamp=${(actionState as any).payload}`;
      return this.http.post<any>(
        `${this.core.hostUrl}in-house/wake-up-call/new`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('---------- Alarm Time Submited ----------');
        //TODO create a toast to info user that anwsers submited!
        return new QuestActions.GetWakeUpList()
      }
    })
  );

  /**
   * * Get Alarms
   *
   * @param corporateId
   * @param roomId
   * @param userId array
   */
  @Effect()
  getwakeuptime = this.actions$.pipe(
    ofType(QuestActions.GET_WAKE_UP_LIST),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      let body = `&corporateId=${storeState.tvbox_info.corporate}&roomId=${storeState.tvbox_info.room_id}&userId=${storeState.room.user_id}`;
      return this.http.post<any>(
        `${this.core.hostUrl}in-house/wake-up-call/list`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('---------- Set Wake Up List ----------');
        console.log(orderBy(res.wakeUpCalls,['timestamp'],'asc'));
        return new QuestActions.SetWakeUpList(orderBy(res.wakeUpCalls,['timestamp'],'asc'))
      }
    })
  );

  /**
   * * Edit Alarm
   *
   * @param wakeUpCallId
   * @param timestamp
   */
    @Effect()
    editwakeupalarm = this.actions$.pipe(
      ofType(QuestActions.EDIT_WAKE_UP_ALARM),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        let body = `&wakeUpCallId=${(actionState as any).payload.wakeUpCallId}&timestamp=${(actionState as any).payload.timestamp}`;
        return this.http.post<any>(
          `${this.core.hostUrl}in-house/wake-up-call/edit`,
          body
        );
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. get languages');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('---------- Set Wake Up List ----------');
          return new QuestActions.GetWakeUpList()
        }
      })
    );

  /**
   * * Delete Alarm
   *
   * @param wakeUpCallId
   */
  @Effect()
  deletewakeupalarm = this.actions$.pipe(
    ofType(QuestActions.DELETE_WAKE_UP_ALARM),
    withLatestFrom(this.store$.select('appStore')),
    switchMap(([actionState, storeState]) => {
      console.log((actionState as any).payload)
      let body = `&wakeUpCallId=${(actionState as any).payload}`;
      return this.http.post<any>(
        `${this.core.hostUrl}in-house/wake-up-call/remove`,
        body
      );
    }),
    map((res) => {
      if (res.error) {
        console.info('Server returned Error. get languages');
        throw 'Server returned Error. ' + res.alert ? res.alert : '';
      } else {
        console.info('---------- Set Wake Up List ----------');
        return new QuestActions.GetWakeUpList()
      }
    })
  );
  

  /**
   * * Get Concierge Categories
   * @returns []
   */
   @Effect()
   getconciergecategories = this.actions$.pipe(
     ofType(QuestActions.GET_CONCIERGE_CATEGORIES),
     withLatestFrom(this.store$.select('appStore')),
     switchMap(([actionState, storeState]) => {
       return this.http.post<any>(
         `${this.core.hostUrl}concierge/concierge-categories/get-concierge-categories`,
         ''
       );
     }),
     map((res) => {
       if (res.error) {
         console.info('Server returned Error. get concierge categories');
         throw 'Server returned Error. ' + res.alert ? res.alert : '';
       } else {
         console.info('----------got Concierge Categories----------');
         return new QuestActions.SetConciergeCategories(res);
       }
     })
   );

   /**
   * * Get Concierge Messages
   * @returns []
   */
    @Effect()
    getconciergemessages = this.actions$.pipe(
      ofType(QuestActions.GET_CONCIERGE_MESSAGES),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        let body = null
        if((actionState as any).payload == -1){
          body = `&user_id=${storeState.room.user_id}`;
        }else{
          body = `&category=${(actionState as any).payload}&user_id=${storeState.room.user_id}`;
        }
        return this.http.post<any>(
          `${this.core.hostUrl}concierge/concierge/concierge-messages`,
          body
        );
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. get concierge messages');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('----------got Concierge Messages----------');
          if (!isEqual(res, 'no data'))
            return new QuestActions.SetConciergeMessages(orderBy(res, ['timestamp'], 'desc'));
          else
            return new QuestActions.SetConciergeMessages([]);
        }
      })
    );

    /**
   * * Get Predifined Messages
   * @returns []
   */
     @Effect()
     getpredifinedmessages = this.actions$.pipe(
       ofType(QuestActions.GET_PREDIFINED_MESSAGES),
       switchMap(() => {
        console.log("pred")
         return this.http.post<any>(
           `${this.core.hostUrl}concierge/predefined-message/list`,
           ''
         );
       }),
       map((res) => {
         if (res.error) {
           console.info('Server returned Error. get predifined messages');
           throw 'Server returned Error. ' + res.alert ? res.alert : '';
         } else {
           console.info('----------got Predifined Messages----------');
           return new QuestActions.SetPredifinedMessages(res['models']);
         }
       })
     );

       /**
   * * Send Predifined Message
   * @param userId
   * @param message
   * @param category
   * @returns []
   */
    @Effect()
    sendpredifinedmessage = this.actions$.pipe(
      ofType(QuestActions.SEND_CONCIERGE_MESSAGE),
      withLatestFrom(this.store$.select('appStore')),
      switchMap(([actionState, storeState]) => {
        let body = `&userid=${storeState.room.user_id}&message=${(actionState as any).payload.name}&category=${(actionState as any).payload.category_id}`;
        return this.http.post<any>(
          `${this.core.hostUrl}concierge/concierge/newmessage`, body);
      }),
      map((res) => {
        if (res.error) {
          console.info('Server returned Error. send predifined message');
          throw 'Server returned Error. ' + res.alert ? res.alert : '';
        } else {
          console.info('---------send Predifined Message----------');
          return new QuestActions.GetConciergeMessages(-1);
        }
      })
    );

     /**
     * * Get User Invoices (Current Check in)
     * @returns []
     */
      @Effect()
      getuserinvoices = this.actions$.pipe(
        ofType(QuestActions.GET_USER_INVOICES),
        withLatestFrom(this.store$.select('appStore')),
        switchMap(([actionState, storeState]) => {
          let body = `&user_id=${storeState.profile.user_id}`;
          return this.http.post<any>(
            `${this.core.hostUrl}invoices/invoices/get-user-current-invoices${body}`,
            ''
          );
        }),
        map((res) => {
          if (res.error) {
            console.info('Server returned Error. get user invoices');
            throw 'Server returned Error. ' + res.alert ? res.alert : '';
          } else {
            console.info('----------got User Invoices----------');
            return new QuestActions.SetUserInvoices(res);
          }
        })
      );

      /**
     * * Get User Invoices History (All)
     * @returns []
     */
       @Effect()
       getuserinvoiceshistory = this.actions$.pipe(
         ofType(QuestActions.GET_USER_INVOICES_HISTORY),
         withLatestFrom(this.store$.select('appStore')),
         switchMap(([actionState, storeState]) => {
           let body = `&user_id=${storeState.profile.user_id}`;
           return this.http.post<any>(
             `${this.core.hostUrl}invoices/invoices/get-user-invoice-history${body}`,
             ''
           );
         }),
         map((res) => {
           if (res.error) {
             console.info('Server returned Error. get user invoices history');
             throw 'Server returned Error. ' + res.alert ? res.alert : '';
           } else {
             console.info('----------got User Invoices History----------');
             return new QuestActions.SetUserInvoicesHistory(res);
           }
         })
       );

        /**
       * * Read Messages
       * @returns []
       */
         @Effect()
         readmessage = this.actions$.pipe(
           ofType(QuestActions.READ_MESSAGE),
           withLatestFrom(this.store$.select('appStore')),
           switchMap(([actionState, storeState]) => {
             let body = `&message_id=${(actionState as any).payload}&user_id=${storeState.profile.user_id}`;
             return this.http.post<any>(
               `${this.core.hostUrl}room_push/room-push/message-read`,
               body
             );
           }),
           map((res) => {
             if (res.error) {
               console.info('Server returned Error. get user invoices history');
               throw 'Server returned Error. ' + res.alert ? res.alert : '';
             } else {
               console.info('----------got User Invoices History----------');
               return new AppInitialActions.GetRoomPush();
             }
           })
         );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private core: CoreService,
    private store$: Store<fromApp.AppState>
  ) {}
}

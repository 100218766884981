import * as GuestActions from './guest.actions';

export interface State {
  id: number;
  surveyCategories: Array<any>;
  conciergeCategories: Array<any>;
  conciergeMessages: Array<any>;
  predifinedMessages: Array<any>;
  invoices: Array<any>;
  wakeuplist: Array<any>;
}

const initialState: State = {
  id: 0,
  surveyCategories: [],
  conciergeCategories: [],
  conciergeMessages: [],
  predifinedMessages: [],
  invoices: [],
  wakeuplist: []
}


export function guestReducer(
  state: State = initialState,
  action: GuestActions.GuestActions
): State {
  switch (action.type) {
    case GuestActions.SET_SURVEY_CATEGORIES:
      return {
        ...state,
        id: action.payload.id,
        surveyCategories: action.payload.surveyCategories
      };
    case GuestActions.SET_CONCIERGE_CATEGORIES:
      return {
        ...state,
        conciergeCategories: action.payload
      };
    case GuestActions.SET_CONCIERGE_MESSAGES:
      return {
        ...state,
        conciergeMessages: action.payload
      };
    case GuestActions.SET_PREDIFINED_MESSAGES:
      return {
        ...state,
        predifinedMessages: action.payload
      };
    case GuestActions.SET_USER_INVOICES:
      return {
        ...state,
        invoices: action.payload
      };
    case GuestActions.SET_USER_INVOICES_HISTORY:
      return {
        ...state,
        invoices: action.payload
      };
    case GuestActions.SET_WAKE_UP_LIST:
      return {
        ...state,
        wakeuplist: action.payload as any[]
      };
    
    default:
      return state;
  }
}

<div class="modal-body">
  <div class="row" *ngIf="!!question">
     <div class="col-12">
       <p class="text-parental">{{ question | translate }}</p>
     </div>
  </div>
  <form class="form-parental" [formGroup]="parentalForm">
    <div class="row">
      <div class="col-12">
        <input type="number" min='4' max='9' (input)="update($event)" tabindex='-1' class="form-control" id="parentalNum" formControlName="parentalNum">
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger btn-lg" tabindex="-1" (keydown.enter)="cancel()">{{ 'CANCEL' | translate }}</button>
  <button type="button" class="btn btn-success btn-lg" tabindex="-1" (keydown.enter)="confirm()">{{ 'CONFIRM' | translate }}</button>
</div>

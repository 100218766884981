<div class="container-fluid">
  <div class="row" *ngFor="let i of [].constructor(5)">
    <div class="col" *ngFor="let i of [].constructor(10)">
      <ngx-skeleton-loader appearance="circle" [theme]="{
        'border-radius': '5px',
        height: '100px',
        width: '100px',
        border: '1px solid white'
      }" class="d-flex justify-content-center"></ngx-skeleton-loader>
    </div>
  </div>
</div>
